(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PurchasingApplicationController', PurchaseController);

    PurchaseController.$inject = ['$scope', 'Principal', '$state', 'MessageService', 'PurchaseOrder', 'ParseLinks', '$uibModal', '$timeout', '$stateParams'];

    function PurchaseController ($scope, Principal, $state, MessageService, PurchaseOrder, ParseLinks, $uibModal, $timeout, $stateParams) {
        var vm = this;

        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_PRODUCT').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问的权限！");
                $state.go("home");
            }
        });

        vm.isTableState = false;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.selection = [
            {name:'全部', value:'all'},
            {name:'待审核', value:'ToBeAudited'},
            {name:'待修订', value:'ToBeRevised'},
            {name:'已下达', value:'AlreadyIssued'},
            {name:'采购预警', value:'Warning'}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: ['createdDate,DESC'],
            keyword: '',
            type:'all',
            pageType: 'demand'
        };

        //获取选中的数组
        $scope.checked =[];
        $scope.selectOne = function(data){
            var index = $scope.checked.indexOf(data);
            if(index == -1 && data.checked){
                $scope.checked.push(data);
            }else if (!data.checked && index !== -1){
                $scope.checked.splice(index, 1);
            }
            if (vm.tableList.length === $scope.checked.length) {
                $scope.select_all = true;
            } else {
                $scope.select_all = false;
            }
        };

        $scope.selectAll = function(){
            if($scope.select_all) {
                angular.forEach(vm.tableList, function (item) {
                    item.checked = true;
                });
                $scope.checked = vm.tableList.map(function(value){
                    return value;
                })
            }else {
                angular.forEach(vm.tableList, function (item) {
                    item.checked = false;
                });
                $scope.checked = [];
            }
        };

        $scope.cancelModal = function() {
            vm.messageStr = '';
            $('#submitModal').modal('hide');
        };

        $scope.confirmSubmit = function() {
            var ids = '';
            for (var i = $scope.checked.length - 1; i >= 0; i--) {
                ids += $scope.checked[i].id + ",";
            }
            ids = ids.substr(0, ids.length - 1);
            if (vm.messageType == 1) {
                // 审核
                PurchaseOrder.changeStatue({ids: ids}, function(){
                    MessageService.success('审核成功');

                    cancelModal();
                    init();
                }, function(){
                    MessageService.error('审核失败');
                });
            }
        };

        // 转采购单
        $scope.submission = function() {
            if ($scope.checked.length == 0) {
                MessageService.error('请选择列表项');
                return;
            }

            var ids = '';
            for (var i = $scope.checked.length - 1; i >= 0; i--) {
                if ($scope.checked[i].type != 'Examined') {
                    MessageService.error('存在未审核订单');
                    return;
                }
                ids += $scope.checked[i].id + ",";
            }
            ids = ids.substr(0, ids.length - 1);
            console.log(ids);
            PurchaseOrder.createPurchaseOrder({ids: ids}, function(date){
                $state.go("purchaseProductLineEdit", {id: date.id});
            }, function(){
                MessageService.error('采购单生成失败');
            });
        };

        // 审核需求订单
        $scope.toExamine = function() {

            if($scope.checked.length == 0){
                MessageService.error('请选择列表项');
                return;
            }

            vm.messageStr = '审核操作确认。';
            vm.messageType = 1;     // 审核
            $('#submitModal').modal('show');
        }

        // 取消采购需求
        $scope.cancelledBtn = function() {
            if($scope.checked.length == 0){
                MessageService.error('请选择列表项');
                return;
            }

            var ids = '';
            for (var i = $scope.checked.length - 1; i >= 0; i--) {
                ids += $scope.checked[i].id + ",";
            }
            ids = ids.substr(0, ids.length - 1);

            PurchaseOrder.cancelled({ids: ids}, function(){
                MessageService.success('取消成功');
                init();
            }, function(){
                MessageService.error('取消失败');
            });
        }

        var _h = angular.element(".table-top").find(".table-state").height();
        if(_h > 40){
            vm.isTableState = true;
        }else {
            vm.isTableState = false;
        }

        init();
        function init() {
            vm.tableList = [];
            $scope.checked = [];
            $scope.select_all = false;
            PurchaseOrder.query(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        }

        var nowTime = new Date().getTime();

        function getDate(strDate) {
            var st = strDate;
            var a = st.split(" ");
            var b = a[0].split("-");
            var c = a[1].split(":");
            var date = new Date(b[0], b[1] - 1, b[2], c[0], c[1], c[2]);
            return date;
        }

        $scope.conditionSelect = function(data){
            vm.tableData.type = data.value;
            vm.tableData.page = 0;
            init();
        };

        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };

        $scope.tableStateBtn = function () {
            var _h = angular.element(".table-top").height();
            if(_h > 40){
                angular.element(".table-top").height("40");
                angular.element(".table-state-btn").removeClass("table-state-btnOn");
            }else {
                angular.element(".table-top").height("auto");
                angular.element(".table-state-btn").addClass("table-state-btnOn");
            }
        };

        window.onresize = function(){
            var _h = angular.element(".table-top").find(".table-state").height();
            $timeout(function () {
                if(_h > 40){
                    vm.isTableState = true;
                }else {
                    vm.isTableState = false;
                }
            })
        }
    }

})();
