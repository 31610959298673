(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ProjectEditController', ProjectEditController);

    ProjectEditController.$inject = ['$scope', '$state', 'MessageService', '$uibModal', 'entity', '$location', '$localStorage', 'Project', 'Merchant',
    '$stateParams', '$filter', '$q', 'LocationProvice', 'LocationCity', 'LocationDistrict', '$http', 'Product'];

    function ProjectEditController ($scope, $state, MessageService, $uibModal, entity, $location, $localStorage, Project, Merchant,
        $stateParams, $filter, $q, LocationProvice, LocationCity, LocationDistrict, $http, Product) {
        var vm = this;
        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;
        vm.getOrder = getOrder;

        vm.data = entity;
        //商户
        $q.all([vm.data.$promise]).then(function(){
            init();
        });
        function init() {
            // 获取地址
            LocationProvice.query({},function (data) {
                vm.provinces =  data;
                for (var i = vm.provinces.length - 1; i >= 0; i--) {
                    var p = vm.provinces[i];
                    if (p.name == vm.data.address.split("-")[0]) {
                        vm.province = p;
                        break;
                    }
                }
                vm.districts = [];
                LocationCity.findByProvince({provinceId: vm.province.id}, function (data) {
                    vm.citys = data;
                    for (var i = vm.citys.length - 1; i >= 0; i--) {
                        var c = vm.citys[i];
                        if (c.name == vm.data.address.split("-")[1]) {
                            vm.city = c;
                            break;
                        }
                    }
                    
                    LocationDistrict.findByCities({cityId: vm.city.id}, function (data) {
                        vm.districts = data;
                        for (var i = vm.districts.length - 1; i >= 0; i--) {
                            var di = vm.districts[i];
                            if (di.name == vm.data.address.split("-")[2]) {
                                vm.district = di;
                                vm.address = vm.data.address.split(vm.district.name+"-")[1];
                            }
                        }
                    });
                });
            });

            Merchant.getAll(function (res) {
                vm.merchants = res.filter(function (item) {
                    return item.enable
                });

                $q.all([vm.merchants.$promise]).then(function(){
                    vm.expectedDate =  $filter('date')(vm.data.expectedDate, "yyyy-MM-dd");
                    angular.element("#startDate").val(vm.expectedDate);
                    angular.forEach(vm.merchants,function(item){
                        if(vm.data.merchant.id == item.id){
                            vm.merchant = item;
                        }
                    })
                })

            });
        }


        //项目类型
        vm.projectTypes =[
            {name:'标准',id:'Standard'},
            {name:'打样',id:'Proofing'}
        ];
        vm.token = $localStorage.authenticationToken;
        //查看直接点编号进去   编辑只能单选  其他是可以多选的

        var productLine ={
            productName:'选择产品',//订购商品
            productNumber:'',//编号
            unit:'',//单位
            quantity:'',//数量
            price:'',//单价
            taxRate:'',//税率
            amount:'',//合计金额
            taxPrice:'',//含税单价
            taxAmount:'',//加税合计
            filePath:''//上传的文件
        };
        //新建生产
        var flag = false;
        $scope.productLineNew = function(){
            var flag = false;
            if(vm.data.productLines.length >0) {
                angular.forEach(vm.data.productLines, function (item) {
                    if (!item.productName || !item.productNumber || !item.unit || !item.quantity ||!item.filePath) {
                        flag = true;
                    }
                });
            }
            if(flag){
                MessageService.error('请将空白项填写完整再添加！！！！');
            }else{
                productLine ={
                    productName:'选择产品',//订购商品
                    productNumber:'',//编号
                    unit:'',//单位
                    quantity:'',//数量
                    price:'',//单价
                    taxRate:'',//税率
                    amount:'',//合计金额
                    taxPrice:'',//含税单价
                    taxAmount:'',//加税合计
                    filePath:''//上传的文件
                };
                vm.data.productLines.push(angular.copy(productLine));
            }
        };

        $scope.productLineDelete = function(index){
            vm.data.productLines.splice(index,1);
        };

        //选择产品
        $scope.chooseProduct = function(index){
            $uibModal.open({
                templateUrl: 'app/project/chooseProduct.html',
                controller: 'ChooseProductController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result){
                console.log(result);
                if(result!=undefined){
                    vm.data.productLines[index].productName=result.sku;
                    vm.data.productLines[index].productNumber=result.number;
                    vm.data.productLines[index].unit=result.unit;
                }
            });
        };

        //文件上传  二次上传覆盖之前上传的文件  压缩包里面不能文件夹
        window.uploadFile = function($event) {
            var index=$event.currentTarget.dataset.target;
            if(!vm.data.productLines[index].productNumber){
                MessageService.error('请选择商品！');
                return;
            }
            var ext,idx;
            var fileObj = $event.target.files[0]; // js 获取文件对象
            idx = fileObj.name.lastIndexOf(".");
            ext = fileObj.name.substr(idx+1).toUpperCase();
            ext = ext.toLowerCase();
            if (ext != 'pdf' && ext != 'zip'){
                angular.element("#file").val("");
                MessageService.error("只能上传.pdf/.zip类型的文件!");
                return;
            }

            if (fileObj.size > 1024 * 1024 * 1024 * 2) {
                MessageService.error("文件不能大于2GB");
                return;
            }

            checkIfUploaded(fileObj, ext, index);
        };

        function checkIfUploaded (file, ext, index_file) {
            var reader = new FileReader();
            // 用来计算fileMd5值的file内容
            var fileToCountMD5 = file;

            if (file.size > 1024*1024*50) {
                var fileStart = file.slice(0, 1024 * 1024);
                var fileEnd = file.slice(file.size - 1024 * 1024, file.size);
                fileToCountMD5 = new Blob([fileStart,fileEnd], { type: "text/plain" });
            }
            reader.readAsBinaryString(fileToCountMD5);

            //绑定读取失败事件
            reader.onerror = function() {
                MessageService.error("文件读取失败");
            };

            //绑定读取成功事件
            reader.onload = function() {
                //需要引入计算MD5的jar包
                var fileMd5 = hex_md5(reader.result);

                var form = new FormData();
                form.append("fileMd5", fileMd5);
                form.append("fileSize", file.size);

                //校验是否上传过该文件，返回上传进度
                console.log("isFileExist");
                $.ajax({
                    url: $location.protocol() + '://' + $location.host() + ':' + $location.port() +  "/api/scm-file/isFileExist",
                    type: "POST",
                    data: form,
                    async: true,        //异步
                    processData: false,  //很重要，告诉jquery不要对form进行处理
                    contentType: false,  //很重要，指定为false才能形成正确的Content-Type
                    success: function(data) {
                        if (data.success) {
                            //文件已上传
                            MessageService.success("上传成功！");
                        } else {
                            //文件未上传或者部分上传
                            uploadBySplit(file, fileMd5, 0, ext, index_file);
                        }
                    },
                    error: function() {
                        MessageService.error("服务器出错!");
                    }
                });
            }
        }

        //分片上传
        function uploadBySplit(file, fileMd5, i, ext, index_file) {
            var splitSize = 1024 * 1024 * 20;   //分片大小20M  -- 20M为一段
            var size = file.size;//总大小
            var splitCount = Math.ceil(size / splitSize);  //总片数

            if (i == splitCount) {
                MessageService.success("文件上传成功！");
                return;
            }

            //计算每一片的起始与结束位置
            var start = i * splitSize;
            var end = Math.min(size, start + splitSize);
            var fileData = file.slice(start,end);
            var reader = new FileReader();
            reader.onload = function(){
                var md5 = hex_md5(reader.result);

                var form = new FormData();
                form.append("fileMd5", fileMd5);
                form.append("size", size);  //总大小
                form.append("totalStr", splitCount);   //总片数
                form.append("indexStr", i);    //当前是第几片
                form.append("md5", md5);    //分片md5

                //判断分片是否上传
                $.ajax({
                    url: $location.protocol() + '://' + $location.host() + ':' + $location.port() +  "/api/scm-file/isFileSplitExist",
                    type: "POST",
                    data: form,
                    async: true,        //异步
                    processData: false,  //很重要，告诉jquery不要对form进行处理
                    contentType: false,  //很重要，指定为false才能形成正确的Content-Type
                    success: function(data){
                        if (data.code == 1) {//已上传
                            //上传进度
                            var process = Math.round(end/size*100)+"%";
                            i++;
                            uploadBySplit(file, fileMd5, i, ext, index_file);    // 循环上传
                        } else { //未上传
                            form.append("fileData", fileData);
                            form.append("ext", ext);    // 文件后缀
                            //上传分片
                            $.ajax({
                                url: $location.protocol() + '://' + $location.host() + ':' + $location.port() +  "/api/scm-file/upload",
                                type: "POST",
                                data: form,
                                async: true,        //异步
                                processData: false,  //很重要，告诉jquery不要对form进行处理
                                contentType: false,  //很重要，指定为false才能形成正确的Content-Type
                                success: function(data) {
                                    if (data.state == 200) {
                                        //上传进度
                                        $('#progress-bar').css('width', (i / splitCount * 100)  +'%');
                                        i++;
                                        uploadBySplit(file, fileMd5, i, ext, index_file);
                                    } else if (data.state == 300) {
                                        $('#progress-bar').css('width', '0%');
                                        console.log(index_file);
                                        vm.data.productLines[index_file].filePath = data.message;
                                        $scope.$apply();
                                    } else {
                                        $('#progress-bar').css('width', '0%');
                                        MessageService.error("分片上传失败");
                                    }
                                },
                                error: function(XMLHttpRequest, textStatus, errorThrown) {
                                    MessageService.error("服务器出错!");
                                }
                            });
                        }
                    },

                    error: function(XMLHttpRequest, textStatus, errorThrown) {
                        MessageService.error("服务器出错!");
                    }
                });
            }
            reader.readAsBinaryString(fileData);
        }

        //文件下载
        $scope.downLoadFile = function (data) {
            $http({
                url: 'api/projects/download',
                method: "GET",
                params: {
                    filePath: data.filePath
                },
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "APPLICATION/pdf"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = Date.now() + ".pdf";
                a.setAttribute('download', '');
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        };

        function getDate(strDate) {
            var st = strDate;
            var a = st.split(" ");
            var b = a[0].split("-");
            var c = a[1].split(":");
            var date = new Date(b[0], b[1] - 1, b[2], c[0], c[1], c[2]);
            return date;
        }


        //保存
        $scope.save = function () {
            // if(!vm.data.name){
            //     MessageService.error("项目名称不能为空");
            //     return false;
            // }
            if(!vm.data.type){
                MessageService.error("项目类型不能为空");
                return false;
            }
            vm.data.merchant =vm.merchant;
            if(!vm.data.merchant){
                MessageService.error("商户不能为空");
                return false;
            }
            vm.expectedDate= angular.copy(angular.element("#startDate").val());
            vm.data.expectedDate = getDate($('#startDate').val() + " 23:59:59");
            // if(!vm.expectedDate){
            //     MessageService.error("预期交货时间不能为空");
            //     return false;
            // }
            if(!vm.data.contact){
                MessageService.error("联系人不能为空");
                return false;
            }
            if(!vm.data.phone){
                MessageService.error("联系方式不能为空");
                return
            }
            // var myreg=/^[1][3,4,5,6,7,8][0-9]{9}$/;
            // if (!myreg.test(vm.data.phone)) {
            //     MessageService.error("请输入11位符合要求的电话号码");
            //     return false;
            // };
            // if(!vm.data.address){
            //     MessageService.error("联系地址不能为空");
            //     return false;
            // }
            if (vm.province == null || vm.province == "") {
                MessageService.error("请选择省份");
                return false;
            };

            if (vm.city == null || vm.city == '') {
                MessageService.error('请选择城市');
                return false;
            }

            if (vm.district == null || vm.district == "") {
                MessageService.error("请选择区县");
                return false;
            };

            if (vm.address == null || vm.address == "") {
                MessageService.error("请输入详细地址");
                return false;
            };
            vm.data.address = vm.province.name + "-" + vm.city.name + "-" + vm.district.name + "-" + vm.address;

            if(!vm.data.freight){
                // MessageService.error("运费不能为空");
                // return false;
                vm.data.freight = 0;
            }

            var ccFlag=true;
            if (vm.data.productLines != null && vm.data.productLines.length > 0) {
                angular.forEach(vm.data.productLines,function(item){
                    if(!item.productNumber && item.productName =='选择产品'){
                        MessageService.error("订购商品不能为空！");
                        ccFlag=false;
                        return false;
                    }
                    if(!item.quantity){
                        MessageService.error("数量不能为空");
                        ccFlag=false;
                        return false;
                    }
                    // if(!item.price){
                    //     MessageService.error("价格不能为空");
                    //     ccFlag=false;
                    //     return false;
                    // }
                    // if(!item.taxRate){
                    //     MessageService.error("税率不能为空");
                    //     ccFlag=false;
                    //     return false;
                    // }
                    if(!item.filePath){
                        MessageService.error("上传文件不能为空");
                        ccFlag=false;
                        return false;
                    }

                    item.amount = item.quantity * item.price;
                    item.taxPrice = (item.price * item.taxRate / 100) + item.price;
                    item.taxAmount = ((item.price * item.taxRate / 100) + item.price) * item.quantity;
                });
            }else{
                MessageService.error("商品信息不能为空");
                return false;
            }

            if(!ccFlag){
                return false;
            }

            vm.isSaving = true;
            Project.update(vm.data, function (res) {
                MessageService.success("保存成功");
                $state.go("project");
            }, function (error) {
                vm.isSaving = false;
                MessageService.error(error.data.message);
            });
        };

        //返回
        $scope.goBack = function () {
            $state.go("project",{
                        id: $stateParams.id,
                        page:$stateParams.page,
                        keyword:$stateParams.keyword
                }
            )
        }

        function changeProvince() {
            LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                vm.citys = data;
                vm.districts = [];
            });
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
            });
        }

        function getOrder() {
            if(!vm.data.merchant){
                MessageService.error("请选择商户");
                return;
            }
            if(!vm.data.number) {
                MessageService.error("请填写项目编号");
                return;
            }
            Project.getThirdPartyProductByNumber({number: vm.data.number, merchantId: vm.data.merchant.id}, function(res) {
                if (res.orderNumber == null) {
                    MessageService.error("获取订单数据错误");
                    return;
                }

                // 地址数据充填
                vm.data.contact = res.contact;
                vm.data.phone = res.phone;
                vm.address = res.address;
                if (res.province.length == 2) {
                    res.province = res.province + "市";
                    res.city = "市辖区";
                }
                for (var i = vm.provinces.length - 1; i >= 0; i--) {
                    var p = vm.provinces[i];
                    if (p.name == res.province) {
                        vm.province = p;
                        break;
                    }
                }
                vm.districts = [];
                LocationCity.findByProvince({provinceId: vm.province.id}, function (data) {
                    vm.citys = data;
                    for (var i = vm.citys.length - 1; i >= 0; i--) {
                        var c = vm.citys[i];
                        if (c.name == res.city) {
                            vm.city = c;
                            break;
                        }
                    }
                    
                    LocationDistrict.findByCities({cityId: vm.city.id}, function (data) {
                        vm.districts = data;
                        for (var i = vm.districts.length - 1; i >= 0; i--) {
                            var di = vm.districts[i];
                            if (di.name == res.district) {
                                vm.district = di;
                            }
                        }
                    });
                });

                // 商品充填
                if (res.supNumber != null) {
                    Product.getBySkuNumber({number: res.supNumber}, function(pro) {
                        if (pro.id != null) {
                            
                            productLine.productName = pro.propertyConfigs[0].sku; //订购商品
                            productLine.productNumber = pro.propertyConfigs[0].number;      //编号
                            productLine.unit = pro.measurementName;               //单位
                            productLine.quantity = parseInt(res.quantity);           //数量

                            var date = new Date();
                            date.setHours(date.getHours() + pro.warningTime);
                            vm.expectedDate = angular.copy(angular.element("#startDate").val());
                            if (vm.expectedDate == '') {
                                vm.expectedDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                            }

                            var flag = true;
                            for (var i = vm.data.productLines.length - 1; i >= 0; i--) {
                                if (vm.data.productLines[i].productNumber == productLine.productNumber) {
                                    flag = false;
                                }
                            }
                            if (flag) {
                                vm.data.productLines.push(productLine);
                            }
                        }
                    })
                }
            });
        }

    }
})();
