(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('InvoicingOperateController', InvoicingOperateController);

    InvoicingOperateController.$inject = ['$scope', 'Principal', 'LoginService','$state','User','$uibModal','$uibModalInstance','MessageService','$timeout','msgdata','Invoices'];

    function InvoicingOperateController ($scope, Principal, LoginService,$state,User,$uibModal,$uibModalInstance,MessageService,$timeout,msgdata,Invoices) {
        var vm = this;
        vm.operate = angular.copy(msgdata.data);
        vm.title = msgdata.title

        $scope.save = function() {
            if(!vm.operate.invoiceNumber){
                MessageService.error('发票号');
                return
            }

            if(!vm.operate.deliveryCompany){
                MessageService.error('请输入快递公司');
                return
            }
            if(!vm.operate.deliveryNumber){
                MessageService.error('请输入快递单号');
                return
            }
            vm.isSaving = true;
            Invoices.delivery({
                id:vm.operate.id,
                invoiceNumber:vm.operate.invoiceNumber,
                deliveryCompany:vm.operate.deliveryCompany,
                deliveryNumber:vm.operate.deliveryNumber
            },function (res) {
                MessageService.success('配送成功');
                $uibModalInstance.close(vm.operate);
            }, function (error) {
                vm.isSaving = false;
                MessageService.error("配送失败");
            })
        };

        $scope.save2 = function(){
            Invoices.reject({id:vm.operate.id,remark:vm.operate.remark},function (res) {
                MessageService.success('拒绝成功');
                $uibModalInstance.close(vm.operate);
            })
        }

        $scope.modalHide = function(){
            $uibModalInstance.close(false);
        };
    }
})();
