(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('DeliveryRequestController', DeliveryRequestController);

    DeliveryRequestController.$inject = ['$scope', '$uibModal', '$state', 'DeliveryRequest', 'Express', 'MessageService', 'ParseLinks', '$stateParams', 'Supplier', 'Depository'];

    function DeliveryRequestController ($scope, $uibModal, $state, DeliveryRequest, Express, MessageService, ParseLinks, $stateParams, Supplier, Depository) {
        var vm = this;
        vm.clear =clear;
        vm.condition = "InitialOrder";
        vm.conditionList = [
            {name:'发货请求', value:'InitialOrder'},
            {name:'合并发货', value:'MergeOrder'},
        ];
        vm.deliveryStatusList = [
            {name:'已发货', value:'Delivered'},
            {name:'已揽件', value:'Receiving'},
            {name:'已签收', value:'Received'},
            {name:'物流异常', value:'Abnormal'}
        ]
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: ['createdDate,DESC'],
            expressSettingId:'',
            keyword: '',
            expectStart: '',
            expectEnd: '',
            supId: '',
            warehouseId: '',
            type: vm.condition,
            deliveryStatus:''
        };
        angular.element("#startDate").val(GetDateStr(-31));
        angular.element("#endDate").val(GetDateStr(0));
        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }
        vm.supList = Supplier.getAll();
        vm.depositories = Depository.getAll();

        $scope.conditionSelect = function(data){
            vm.condition = data.value;
            vm.tableData.page = 0;
            vm.tableData.type = vm.condition;
            init()
        };
        Express.getAll(function(res){
            vm.expressCompanies = res.filter(function(item){return item.enable});
        });

        init();
        function init() {
            vm.tableList = [];
            vm.objList = [];
            vm.tableData.expectStart = angular.copy(angular.element("#startDate").val());
            vm.tableData.expectEnd = angular.copy(angular.element("#endDate").val());
            DeliveryRequest.query(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };

        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };

        function clear(){
            vm.tableData.keyword = '';
            vm.tableData.page = 0;
            init();
        }
        $scope.reset = function(){
            vm.tableData = {
                sort: ['createdDate,DESC'],
                page: 0,
                size: 20,
                expressSettingId:'',
                keyword: '',
                expectStart:'',
                expectEnd:''
            };
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            init();
        };

        $scope.checked =[];
        $scope.tableTr = function(data){
            var index = $scope.checked.indexOf(data);
            if(index == -1 && data.checked){
                $scope.checked.push(data);
            }else if (!data.checked && index !== -1){
                $scope.checked.splice(index, 1);
            }
            if (vm.tableList.length === $scope.checked.length) {
                $scope.select_all = true;
            } else {
                $scope.select_all = false;
            }

            vm.tablePitch = $scope.checked.length >0 ?true:false;
        };

        vm.objList = [];
        $scope.selectOne = function(obj){
            var flag = false;
            for (var i = vm.objList.length - 1; i >= 0; i--) {
                if (vm.objList[i].id == obj.id) {
                    vm.objList.splice(i, 1);
                    flag = true;
                }
            }

            if (!flag) {
                vm.objList.push(obj);
            }
            console.log(vm.objList)
        };

        $scope.magerRequest = function() {
            $uibModal.open({
                templateUrl: 'app/delivery/delivery-request-details.html',
                controller: 'DeliveryRequestDetailsController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return null;
                    }
                }
            }).result.then(function(result){
                if(result){
                    init();
                }
            });
        };

        $scope.shipmentsClick = function() {
            if(!vm.objList || vm.objList.length != 1){
                MessageService.error("只能选择一个未发货的发货请求");
                return
            }
            if(vm.objList[0].deliveryStatus != 'UnDeliver'){
                MessageService.error("只能选择一个未发货的发货请求");
                return
            }
            $uibModal.open({
                templateUrl: 'app/delivery/delivery-request-shipments.html',
                controller: 'DeliveryRequestShipmentsController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return vm.objList[0];
                    }
                }
            }).result.then(function(result){
                if(result){
                    init();
                }
            });
        };

        $scope.shippedAgain = function() {
            if(vm.objList.length === 0){
                MessageService.error("请选择重新发货的订单");
                return
            }
            if(vm.objList.length > 1){
                MessageService.error("只能选择一个发货订单");
                return
            }
            $uibModal.open({
                templateUrl: 'app/delivery/shippedAgain.html',
                controller: 'ShippedAgainController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return vm.objList;
                    }
                }
            }).result.then(function(result){
                if(result){
                    init();
                }
            });
        };

        $scope.splitDeliveryRequest = function() {
            // if (vm.objList.length != 1) {
            //     MessageService.error("选择一项拆分");
            //     return
            // }
            var _objList = [];
            for(var i=0; i<vm.objList.length; i++){
                _objList.push(vm.objList[i].id)
            }

            DeliveryRequest.splitDeliveryRequest({ids: _objList.join(",")}, function() {
                MessageService.success("拆分成功");
                init();
            })
        }

        $scope.showPage = function() {
            if (vm.objList.length != 1) {
                MessageService.error("选择一项查看");
                return
            }
            $uibModal.open({
                templateUrl: 'app/delivery/delivery-request-page.html',
                controller: 'DeliveryRequestPageController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return {id: vm.objList[0].id};
                    }
                }
            }).result.then(function(result){
                init();
            });
        }
        $scope.details = function() {
            if (vm.objList.length != 1) {
                MessageService.error("选择一项查看");
                return
            }
            $uibModal.open({
                templateUrl: 'app/delivery/delivery-details.html',
                controller: 'DeliveryDetailsController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return {id: vm.objList[0].id};
                    }
                }
            }).result.then(function(result){
                init();
            });
        }
        $scope.statusInfo = function (item) {
            switch (item) {
                case 'UnDeliver':
                    return '未发货';
                case 'Delivered':
                    return '已发货';
                case 'Receiving':
                    return '已揽件';
                case 'Received':
                    return '已签收';
                case 'Abnormal':
                    return '物流异常';
                default:
                    return '暂无状态';
            }
        }
    }
})();
