(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('InterfaceEditController', InterfaceEditController);

    InterfaceEditController.$inject = ['$scope','$state','MessageService', 'ApiSetting', 'entity', 'Channels'];

    function InterfaceEditController ($scope, $state,MessageService,ApiSetting, entity, Channels) {
        var vm = this;
        vm.interface = entity;

        Channels.getAll({}, function(res) {
            vm.channelList = res;
            // for (var i = 0; i < res.length; i ++) {
            //     if (vm.interface.channel != null && res[i].id == vm.interface.channel.id){
            //         vm.channel = res[i];
            //     }
            // }
        });

        $scope.validate = function () {
            if(vm.interface.name =='' || vm.interface.name == null){
                MessageService.error("名称不能为空");
                return;
            }
            if(vm.interface.name.length >20){
                MessageService.error("名称不能大于20位");
                return;
            }
            if(vm.interface.description =='' || vm.interface.description == null){
                MessageService.error("描述不能为空");
                return;
            }
            if(vm.interface.description.length >50){
                MessageService.error("描述不能大于50位");
                return;
            }
            if(vm.interface.deliveryChangeApi =='' || vm.interface.deliveryChangeApi == null){
                MessageService.error("发货变更接口不能为空");
                return;
            }
            if(vm.interface.logisticsInformApi =='' || vm.interface.logisticsInformApi == null){
                MessageService.error("物流通知接口不能为空");
                return;
            }
            if(vm.interface.orderTracesApi =='' || vm.interface.orderTracesApi == null){
                MessageService.error("物流轨迹更新接口不能为空");
                return;
            }
            if (vm.interface.oneOrderApi == '' || vm.interface.oneOrderApi == null) {
                MessageService.error("项目获取订单接口不能为空");
                return;
            }
            return true;
        };

        $scope.save = function () {
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            ApiSetting.update(vm.interface, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("修改成功");
                $state.go("interface");
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("修改失败，请稍后重试！");
            }
        };
    }
})();
