(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('UserController', UserController);

    UserController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'ParseLinks', '$uibModal', '$stateParams'];

    function UserController ($scope, Principal, LoginService, $state,User,MessageService, ParseLinks, $uibModal, $stateParams) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_USER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有用户访问的权限！");
                $state.go("home");
            }
        });
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: ['createdDate,DESC'],
            key:''
        };

        init()
        function init() {
            vm.tableList = [];
            User.query(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };

        //删除
        $scope.userDelete = function (data) {
            MessageService.confirm("确认要删除该用户？", function () {
                User.delete({id: data.id}, function () {
                    init();
                    MessageService.success("用户删除成功");
                }, function (error) {
                    MessageService.error("删除失败，请重试！");
                });
            });
        };
        //重置密码
        $scope.modifyContent = function(data){

            vm.user=data;
            $uibModal.open({
                templateUrl: 'app/user/user-password.html',
                controller: 'UserPasswordController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return vm.user;
                    }
                }
            }).result.then(function(result){
                if(result){
                    init()
                }
            });

        };
    }
})();
