(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ProjectOrderController', ProjectOrderController);

    ProjectOrderController.$inject = ['$scope', '$state','MessageService','$uibModalInstance','msgdata', 'Project'];

    function ProjectOrderController ($scope, $state,MessageService,$uibModalInstance,msgdata, Project) {
        var vm = this;

        Project.detail({id: msgdata[0]},function(res){
            console.log(res)
            vm.data = res;
        });

        //保存
        $scope.save = function () {
            if(!vm.data.contact){
                MessageService.error("联系人不能为空");
                return
            }
            if(!vm.data.phone){
                MessageService.error("联系方式不能为空");
                return
            }
            // var myreg=/^[1][3,4,5,6,7,8][0-9]{9}$/;
            // if (!myreg.test(vm.data.phone)) {
            //     MessageService.error("请输入11位符合要求的电话号码");
            //     return;
            // };
            if(!vm.data.address){
                MessageService.error("联系地址不能为空");
                return;
            }
            // if(!vm.data.freight){
            //     MessageService.error("运费不能为空");
            //     return;
            // }
            // if(!vm.data.remarks){
            //     MessageService.error("备注不能为空");
            //     return;
            // }

            vm.isSaving = true;
            var obj={
                id:vm.data.id,
                address: vm.data.address,
                contact: vm.data.contact,
                createdDate:vm.data.createdDate,
                expectedDate:vm.data.expectedDate,
                freight: vm.data.freight,
                lastModifiedDate:vm.data.lastModifiedDate,
                merchant:vm.data.merchant,
                name: vm.data.name,
                number:vm.data.number,
                phone: vm.data.phone,
                productLines:vm.data.productLines,
                remarks:vm.data.remarks,
                status:vm.data.status,
                type:vm.data.type
            };

            Project.order(obj,function(res){
                MessageService.success('订购成功！');
                $uibModalInstance.close();
            },function(error){
                MessageService.success('订购失败！');
            });



        };
        //返回
        $scope.canccel = function () {
            $uibModalInstance.close();
        }

    }
})();
