(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('Sorting', Sorting);

    Sorting.$inject = ['$resource'];

    function Sorting ($resource) {
        var resourceUrl =  'api/sorting-rules/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true,
                params: {
                    id: 'page'
                },
            },
            'getAll': { method: 'GET', isArray: true,
                params: {
                    id: 'getAll'
                },
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'delete':{
                method:'DELETE',
                params: {
                    id: 'id'
                }
            },
            'add': { method:'POST' } ,
            'update': { method:'PUT' },
            'detail': {method:'GET',
                params:{
                    id:'exists'
                }
            }
        });
    }
})();
