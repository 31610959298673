(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MakeUpOrderListController', MakeUpOrderListController);

    MakeUpOrderListController.$inject = ['$scope', 'Principal', '$state','$uibModalInstance', 'ParseLinks', 'msgdata', 'MessageService', '$localStorage','$http', '$location', 'entity'];

    function MakeUpOrderListController ($scope, Principal, $state, $uibModalInstance, ParseLinks, msgdata, MessageService,  $localStorage, $http, $location, entity) {
        var vm = this;
        vm.orderList = entity;
        vm.source = msgdata;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_ENTRUST').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });
        $scope.getQuantity = function(data){
            if (data == null || data.orderToMakeupFiles == null) {
                return 0;
            }
            for (var i = data.orderToMakeupFiles.length - 1; i >= 0; i--) {
                var d = data.orderToMakeupFiles[i];
                if (d.cancelled|| d.orderMakeupFile == null) {
                    continue;
                }
                if (d.orderMakeupFile.id == vm.source.sourceId) {
                    return d.quantity;
                }
            }
        }
        vm.token = $localStorage.authenticationToken;

        $scope.back = function(){
            $uibModalInstance.close();
        }
    }
})();
