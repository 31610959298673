(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('Supplier', Supplier)
        .factory('Makeups', Makeups)
        .factory('Provinces', Provinces);

    Supplier.$inject = ['$resource'];
    Makeups.$inject = ['$resource'];
    Provinces.$inject = ['$resource'];

    function Supplier ($resource) {
        var resourceUrl =  'api/suppliers/:path/:id';

        return $resource(resourceUrl, {}, {
            'create': {
                method: 'POST',
                isArray: false,
                params: {
                    path: 'create'
                }
            },
            'update': {
                method:'POST',
                isArray: false,
                params: {
                    path: 'update'
                }
            } ,
            'changeBalance': {
                method:'GET',
                params: {
                    path: 'changeBalance'
                }
            } ,
            'getTransactionRecord': {
                method:'GET',
                isArray: true,
                params: {
                    path: 'getTransactionRecord'
                }
            } ,
            'deleteSupplier': {
                method:'DELETE'
            } ,
            'page': {
                method:'GET',
                isArray: true,
                params: {
                    path: 'page'
                }
            } ,
            'getAll': {
                method:'GET',
                isArray: true,
                params: {
                    path: 'getAll'
                }
            } ,
            'getHomePageData': {
                method:'GET',
                params: {
                    path: 'getHomePageData'
                }
            } ,
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getFactoryCategories': {
                method: 'GET',
                params: {
                    path: 'getFactoryCategories'
                }
            },
            'getFactoryProducts': {
                method: 'GET',
                params: {
                    path: 'getFactoryProducts'
                }
            },
            'getFactorySkuSpecs': {
                method: 'GET',
                params: {
                    path: 'getFactorySkuSpecs'
                }
            }

        });
    }


    function Makeups ($resource) {
        var resourceUrl =  'api/makeups/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAllMakeups': {
                method:'GET',
                isArray: true
            }
        });
    }


    function Provinces ($resource) {
        var resourceUrl = 'api/provinces/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAll":{
                method:"GET",
                isArray: true,
                params: {
                    path: 'area'
                }
            }
        });
    }

})();
