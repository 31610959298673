(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('Machine', Machine)
        .factory('SupplierAbutSetting', SupplierAbutSetting);

    Machine.$inject = ['$resource'];
    SupplierAbutSetting.$inject = ['$resource'];

    function Machine ($resource) {
        var resourceUrl =  'api/devices/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'page'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                isArray: true
            } ,
            'delete':{method:'DELETE'},
            'create': { method:'POST' } ,
            'update': { method:'PUT' },
            'detail': {method:'GET'}
        });
    }
    function SupplierAbutSetting ($resource) {
        var resourceUrl =  'api/supplier-abut-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'page'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                isArray: true,
                params: {
                    path: 'getAll'
                }
            } ,
            'delete':{method:'DELETE'},
            'create': { method:'POST' } ,
            'update': { method:'PUT' },
            'detail': {method:'GET',
                isArray: true}
        });
    }
})();
