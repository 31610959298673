(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('IngredientsController', IngredientsController);

    IngredientsController.$inject = ['$q', '$scope', 'Principal', 'LoginService', '$state','User','MessageService','ProductCategory', 'ParseLinks',
    '$uibModal','$uibModalInstance', 'entity', 'Material', 'Sorting', 'UnitMeasurement', 'Merchant'];

    function IngredientsController ($q, $scope, Principal, LoginService, $state,User,MessageService, ProductCategory, ParseLinks,
        $uibModal, $uibModalInstance, entity, Material, Sorting, UnitMeasurement, Merchant) {
        var vm = this;

        //商户列表
        vm.merchantList =  Merchant.getAll();
        //材料名称
        vm.materialList = Material.getAll();
        //分拣列表
        // vm.sortingList = Sorting.getAll();
        //计量单位
        vm.unitList = UnitMeasurement.getAll();
        $q.all([vm.merchantList.$promise, vm.materialList.$promise, vm.unitList.$promise]).then(function(){
            if(entity){
                vm.data = [];
                for(var i=0; i<entity.length; i++){
                    var _obj = {
                        materialId: entity[i].materialId,
                        materialName: entity[i].materialName,
                        measurementId: entity[i].measurementId,
                        measurementName: entity[i].measurementName,
                        usingRule: entity[i].usingRule,
                        materialSettingSku: entity[i].materialSettingSku,
                        materialSettingId: entity[i].materialSettingId,
                        merchants: entity[i].merchants
                    };
                    vm.data.push(_obj);
                }
            }else {
                vm.data = [{
                    materialId: '',
                    materialName: '',
                    measurementId: '',
                    measurementName: '',
                    usingRule: {},
                    materialSettingSku: '',
                    materialSettingId: '',
                    merchants: []
                }]
            }
        });


        //使用规则
        $scope.regulationsSet = function(index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/regulations.html',
                controller: 'RegulationsController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {}]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    vm.data[index].usingRule = backData;
                }
            });
        }
        //编辑使用规则
        $scope.regulationsEdit = function(data, index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/regulations.html',
                controller: 'RegulationsController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return data;
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    vm.data[index].usingRule = backData;
                }
                console.log(backData)
                console.log(vm.data)
                console.log(index)
            });
        }
        //辅料配置新增
        $scope.ingredientAdd = function () {
            var _obj = {
                materialId: '',
                materialName: '',
                measurementId: '',
                measurementName: '',
                usingRule: {},
                materialSettingId: '',
                materialSettingSku: '',
                merchants: []
            };
            vm.data.push(_obj)
        }
        //材料名称选择
        $scope.materialSelect = function(data){
            for(var i=0; i<vm.materialList.length; i++){
                if(data.materialId == vm.materialList[i].id){
                    data.materialName = vm.materialList[i].name;
                }
            }
        }
        //辅料配置计量单位
        $scope.measurementSelect1 = function(data){
            for(var i=0; i<vm.unitList.length; i++){
                if(data.measurementId == vm.unitList[i].id){
                    data.measurementName = vm.unitList[i].name;
                }
            }
        }
        //辅料配置删除
        $scope.ingredientDelete = function (index) {
            vm.data.splice(index, 1)
        }

        //选择材料
        $scope.materiaSet = function(data, index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/supplies.html',
                controller: 'SuppliesController',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function() {
                        return data;
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    vm.data[index].materialName = !backData ? '' : (backData.sku.split('_').length > 0 ? backData.sku.split('_')[0] : "");
                    vm.data[index].materialSettingId = !backData ? "" : backData.ID;
                    vm.data[index].materialSettingSku = !backData ? "" : backData.sku;
                }
            });
        }

        //分拣规则设置
        $scope.merchantsSet = function(index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/merchants.html',
                controller: 'MerchantsController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {}]
                }
            }).result.then(function (backData) {
                vm.data[index].merchants = backData;
                console.log(vm.data)
            });
        }

        //分拣规则编辑
        $scope.merchantsEdit = function(data,index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/merchants.html',
                controller: 'MerchantsController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return data;
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    vm.data[index].merchants = backData;
                }
            });
        }

        //确认
        $scope.save = function () {
            console.log(vm.data);
            $uibModalInstance.close(vm.data);
        }
        //取消
        $scope.modalHide = function () {
            $uibModalInstance.close();
        }
    }
})();
