(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('purchasingApplication', {
                parent: 'module',
                url: '/purchasingApplication',
                data: {
                    authorities: ['ROLE_PURCHASE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/purchase/purchasingApplication.html',
                        controller: 'PurchasingApplicationController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('purchasingApplicationAdd', {
                parent: 'module',
                url: '/purchasingApplicationPage/{id}',
                data: {
                    authorities: ['ROLE_PURCHASE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/purchase/purchasingApplicationPage.html',
                        controller: 'PurchasingApplicationPageController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PurchaseOrder', function($stateParams, PurchaseOrder) {
                        return PurchaseOrder.get({id: $stateParams.id});
                    }]
                }
            })
            .state('purchaseProductLine', {
                parent: 'module',
                url: '/purchaseProductLine',
                data: {
                    authorities: ['ROLE_PURCHASE_LIST']
                },
                views: {
                    'content': {
                        templateUrl: 'app/purchase/purchaseOrder.html',
                        controller: 'PurchaseController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('purchaseProductLineAdd', {
                parent: 'module',
                url: '/purchaseProductLineAdd',
                data: {
                    authorities: ['ROLE_PURCHASE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/purchase/purchaseOrder-add.html',
                        controller: 'PurchaseOrderAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('role');
                        return $translate.refresh();
                    }]
                }
            })
            .state('purchaseProductLineEdit', {
                parent: 'module',
                url: '/purchasingApplicationEdit/{id}',
                data: {
                    authorities: ['ROLE_PURCHASE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/purchase/purchaseOrder-edit.html',
                        controller: 'PurchaseOrderEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PurchaseOrder', function($stateParams, PurchaseOrder) {
                        return PurchaseOrder.get({id: $stateParams.id}).$promise;
                    }]
                }
            });
    }
})();
