(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('interface', {
            parent: 'module',
            url: '/interface',
            data: {
                authorities: ['ROLE_SET_INTER']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/interface/interface.html',
                    controller: 'InterfaceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })
        .state('interfaceAdd', {
            parent: 'module',
            url: '/interfaceAdd',
            data: {
                authorities: ['ROLE_SET_INTER']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/interface/interface-add.html',
                    controller: 'InterfaceAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })
        .state('interfaceEdit', {
            parent: 'module',
            url: '/interfaceEdit/{id}',
            data: {
                authorities: ['ROLE_SET_INTER']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/interface/interface-edit.html',
                    controller: 'InterfaceEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ApiSetting', function($stateParams, ApiSetting) {
                    return ApiSetting.detail({id : $stateParams.id});
                }]
            }
        })
        ;
    }
})();
