(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderDetailsController', OrderDetailsController);

    OrderDetailsController.$inject = ['$scope', 'Principal', 'entity', '$state', 'MessageService', '$localStorage', '$stateParams', '$rootScope', '$uibModal','OrderPack','DeliveryRequest'];

    function OrderDetailsController ($scope, Principal, entity, $state, MessageService, $localStorage, $stateParams, $rootScope, $uibModal,OrderPack,DeliveryRequest) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_SELF_SUPPORT_ORDER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });
        vm.token = $localStorage.authenticationToken;
        vm.salesOrder = entity;
        vm.fileUrl = vm.salesOrder.justifyFile;
        if(vm.salesOrder.justifyFile){
            if (vm.salesOrder.justifyFile.indexOf('http') >= 0) {
                vm.fileUrl = vm.salesOrder.justifyFile;
            } else if (vm.salesOrder.justifyFile.indexOf('.pdf') > 0 || vm.salesOrder.justifyFile.indexOf('.zip') > 0) {
                vm.fileUrl = vm.salesOrder.channelUrl + '/' + vm.salesOrder.justifyFile;
                if (vm.salesOrder.justifyFile.indexOf('.zip') > 0) {
                    vm.salesOrder.orderType = 'General';
                }
            } else {
                vm.fileUrl = vm.salesOrder.channelUrl + vm.salesOrder.justifyFile +'/'+ vm.salesOrder.sourceOrderNumber + '.pdf';
            }
        }
        // OrderPack.get({id: vm.salesOrder.orderPackId}, function (res) {
        //     vm.orderPack = res;
        // })
        vm.orderPack = [];
        DeliveryRequest.bySaleOrder({id: vm.salesOrder.id}, function (res) {
            vm.orderPack = res;
        })

        //    返回   保留筛选信息
        $scope.goBack = function(){
            if($rootScope.previousState){
                $state.go($rootScope.previousState.name,{
                    page: $stateParams.page,
                    state: $stateParams.state,
                    source: $stateParams.source,
                    startDate: $stateParams.startDate,
                    endDate: $stateParams.endDate,
                    back: $stateParams.back,
                    timeWarning: $stateParams.timeWarning,
                    replenishment: $stateParams.replenishment,
                    refund: $stateParams.refund,
                    supplierId: $stateParams.supplierId,
                    productId: $stateParams.productId,
                    keyword: $stateParams.keyword,
                    promoteProduct: $stateParams.promoteProduct
                });
            }else {
                history.back();
            }
        };

        $scope.editAddress = function(){
            $uibModal.open({
                templateUrl: 'app/order/order-address.html',
                controller: 'OrderAddressController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return vm.salesOrder
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    $state.reload();
                }
            });
        };

        $scope.viewPics = function (){
            $uibModal.open({
                templateUrl: 'app/order/order-replenishmentPic.html',
                controller: 'OrderReplenishmentPicController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return vm.salesOrder
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    $state.reload();
                }
            });
        }
    }
})();
