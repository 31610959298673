(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CustomerHubDetailController', CustomerHubDetailController);

    CustomerHubDetailController.$inject = ['$scope', 'Principal','entity', '$stateParams', '$state','$uibModal'];

    function CustomerHubDetailController ($scope, Principal, entity, $stateParams, $state,$uibModal) {
        var vm = this;

        vm.supply = entity;
        //返回
        $scope.goBack = function () {
            $state.go("customerHub",{page: $stateParams.page});
        };

        $scope.productionDetail = function(data){
            $uibModal.open({
                templateUrl: 'app/supply/zone-detail.html',
                controller: 'ZoneDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return data.areaList;
                    }
                }
            }).result.then(function(result){
                console.log(result)
                //data.areaList = result;
            });
        };

        $scope.propertyPrice = function(data){
            $uibModal.open({
                templateUrl: 'app/supply/sku-price-page.html',
                controller: 'SkuPricePageController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return data;
                    }
                }
            }).result.then(function(result){
                console.log(result)
                //data.areaList = result;
            });
        };

        $scope.skuFileDetail = function(data){
            $uibModal.open({
                templateUrl: 'app/supply/sku-file-detail.html',
                controller: 'SkuFileDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return data;
                    }
                }
            }).result.then(function(result){
                console.log(result)
                //data.areaList = result;
            });
        };

        $scope.skuMakeupDetail = function(data){
            $uibModal.open({
                templateUrl: 'app/supply/sku-makeup-detail.html',
                controller: 'SkuMakeupDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return data;
                    }
                }
            }).result.then(function(result){
                console.log(result)
                //data.areaList = result;
            });
        };

        //充值
        $scope.recharge = function(){
            $uibModal.open({
                templateUrl: 'app/supply/recharge-amount.html',
                controller: 'RechargeAmountController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return vm.supply;
                    }
                }
            }).result.then(function(result){
                vm.supply.balance = result.balance;
            });
        };

        //交易明细
        $scope.transactionDetail = function(){
            $uibModal.open({
                templateUrl: 'app/customerHub/transaction-detail.html',
                controller: 'CustomerHubTransactionDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return vm.supply;
                    }
                }
            }).result.then(function(result){
                // vm.supply.balance = result.balance;
            });
        };

    }
})();
