(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SuppliesController', SuppliesController);

    SuppliesController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService','ProductCategory', 'ParseLinks', '$uibModal','$uibModalInstance', 'entity', 'Material'];

    function SuppliesController ($scope, Principal, LoginService, $state,User,MessageService, ProductCategory, ParseLinks, $uibModal, $uibModalInstance, entity, Material) {
        var vm = this;

        if(entity){
            vm.data = {
                id: entity.materialId,
                name: entity.materialName
            }
        }else {
            vm.data = {}
        }
        vm.tableData = {
            page: 0,
            size: 10,
            sort: 'lastModifiedDate,DESC',
            keyword: '',
            categoryId: ''
        };
        //获取品类
        vm.categoryList = ProductCategory.getAllCategory();
        console.log(vm.categoryList);

        $scope.categoryTitle = function(data, $event){
            data.open = !data.open ? true : false;
            $event.stopPropagation();
        }
        $scope.categoryOne = function(data){
            vm.tableData.categoryId = data ? data.id : "";
            init()
        }
        $scope.categoryChild = function(data){
            vm.tableData.categoryId = data.id;
            init()
        }

        init();
        function init() {
            vm.tableList = [];
            Material.query(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                // vm.tableList =res;
                angular.forEach(res,function(item){
                    angular.forEach(item.propertyConfigs,function(value){
                        value.createdDate = item.createdDate;
                        value.measurementName = item.measurementName;
                        value.measurementId = item.measurementId;
                        value.ID = item.id;
                        vm.tableList.push(value);
                    })
                });
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };

        //选择表格
        $scope.tableCb = function(data){
            vm.data = data;
        }

        //确认
        $scope.save = function () {
            $uibModalInstance.close(vm.data);
        }
        //取消
        $scope.modalHide = function () {
            $uibModalInstance.close(false);
        }
    }
})();
