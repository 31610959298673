(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('DeliveryRequestPageController', DeliveryRequestPageController);

    DeliveryRequestPageController.$inject = ['$q', '$scope', 'Principal', '$state', 'MessageService', 'DeliveryRequest', 'entity', '$uibModalInstance'];

    function DeliveryRequestPageController ($q, $scope, Principal, $state, MessageService, DeliveryRequest, entity, $uibModalInstance) {
        var vm = this;

        vm.objList = [];

        // console.log(entity.id);

        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_DELIVER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });

        vm.data = DeliveryRequest.mergeRequestPage({ids: entity.id});
        $q.all([vm.data.$promise]).then(function(){
            // console.log(vm.data);
        });


        $scope.goBack = function(){
            $uibModalInstance.close();
        };
    }
})();
