(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ProvinceController', ProvinceController);

    ProvinceController.$inject = ['$scope','$state','MessageService', 'Express', 'msgdata', '$uibModalInstance','LocationProvice'];

    function ProvinceController ($scope, $state,MessageService,Express, msgdata,$uibModalInstance,LocationProvice) {
        var vm = this;
        vm.msgdata = msgdata || [];
        LocationProvice.query({},function (data) {
            for(var i=0; i<data.length; i++){
                for(var j=0; j<vm.msgdata.length; j++){
                    if(data[i].id == vm.msgdata[j].id){
                        data[i].selected = true
                    }
                }
            }
            vm.provinces =  data;
        });


        $scope.save = function(){
            var _provinces = [];
            for(var i=0; i<vm.provinces.length; i++){
                if(vm.provinces[i].selected){
                    _provinces.push(vm.provinces[i])
                }
            }
            $uibModalInstance.close(_provinces);
        }
        $scope.modalHide = function(){
            $uibModalInstance.close(false);
        };
    }
})();
