(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ChannelEditController', ChannelEditController);

    ChannelEditController.$inject = ['$scope', 'Principal', '$uibModal', '$state','MessageService', 'Channels','entity', '$stateParams'];

    function ChannelEditController ($scope, Principal, $uibModal, $state,MessageService,Channels,entity, $stateParams) {
        var vm = this;
        vm.data = entity;

        $scope.save = function () {
            if(!vm.data.name){
                MessageService.error("渠道名称不能为空");
                return;
            }
            if(vm.data.name.length >20){
                MessageService.error("渠道名称不能大于20位");
                return;
            }
            if(!vm.data.url){
                MessageService.error("渠道url不能为空");
                return;
            }
            if(!vm.data.makeupUrl){
                MessageService.error("拼版url不能为空");
                return;
            }
            vm.isSaving = true;
            Channels.update(vm.data, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("编辑成功");
                $state.go("channel",{page: $stateParams.page});
            }

            function onSaveError (error) {
                vm.isSaving = false;
                MessageService.error(error.data.message);
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("channel",{page: $stateParams.page});
        }

        //选择产品
        $scope.chooseProduct = function(){
            $uibModal.open({
                templateUrl: 'app/setting/channel/chooseProduct.html',
                controller: 'CannelChooseProductController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return vm.data.propertyConfigs || [];
                    }]
                }
            }).result.then(function(result){
                console.log(result);
                if(result!=undefined){
                    vm.data.propertyConfigs = result;
                }else{
                    vm.data.propertyConfigs = [];
                }
            });
        };

        //充值
        $scope.recharge = function(){
            $uibModal.open({
                templateUrl: 'app/setting/channel/recharge-amount.html',
                controller: 'CRechargeAmountController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return vm.data;
                    }
                }
            }).result.then(function(result){
                vm.data.balance = result.balance;
            });
        };


        //交易明细
        $scope.transactionDetail = function(){
            $uibModal.open({
                templateUrl: 'app/setting/channel/transaction-detail.html',
                controller: 'CTransactionDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return vm.data;
                    }
                }
            }).result.then(function(result){
            });
        };

        //偏远地区
        $scope.productionSet = function(){
            $uibModal.open({
                templateUrl: 'app/setting/channel/province/province.html',
                controller: 'CProvinceController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return vm.data;
                    }
                }
            }).result.then(function(result){
                if(result){
                    vm.data.remoteProvinces = result.remoteProvinces;
                    vm.data.freight = result.freight;
                }
            });
        };
    }
})();
