(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('user', {
            parent: 'module',
            url: '/user',
            params:{page: null},
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content': {
                    templateUrl: 'app/user/user.html',
                    controller: 'UserController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('user');
                    $translatePartialLoader.addPart('role');
                    $translatePartialLoader.addPart('user-management');
                    return $translate.refresh();
                }]
            }
        })
            .state('userAdd', {
                parent: 'module',
                url: '/userAdd',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content': {
                        templateUrl: 'app/user/user-add.html',
                        controller: 'UserAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('user');
                        return $translate.refresh();
                    }]
                }
            })
            .state('userEdit', {
                parent: 'module',
                url: '/userEdit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content': {
                        templateUrl: 'app/user/user-edit.html',
                        controller: 'UserEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('user');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'User', function ($stateParams, User) {
                        return User.id({id: $stateParams.id});
                    }]
                }
            })
            .state('role', {
                parent: 'module',
                url: '/role',
                params:{page: null},
                data: {
                    authorities: ['ROLE_ROLE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/user/role.html',
                        controller: 'RoleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('user');
                        $translatePartialLoader.addPart('role');
                        $translatePartialLoader.addPart('user-management');
                        return $translate.refresh();
                    }]
                }
            })
            .state('roleAdd', {
                parent: 'module',
                url: '/roleAdd',
                data: {
                    authorities: ['ROLE_ROLE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/user/role-add.html',
                        controller: 'RoleAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('role');
                        return $translate.refresh();
                    }]
                }
            })
            .state('roleEdit', {
                parent: 'module',
                url: '/roleEdit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_ROLE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/user/role-edit.html',
                        controller: 'RoleEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('role');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Role', function($stateParams, Role) {
                        return Role.get({id : $stateParams.id});
                    }],
                }
            });
    }
})();
