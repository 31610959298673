(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MaterialAddController', MaterialAddController);

    MaterialAddController.$inject = ['$scope','$state','MessageService','ProductCategory','$uibModal','MaterialTemplates', 'UnitMeasurement', 'Material'];

    function MaterialAddController ($scope, $state,MessageService,ProductCategory, $uibModal, MaterialTemplates, UnitMeasurement, Material) {
        var vm = this;
        //计量单位
        UnitMeasurement.getAll(function(res){
            vm.unitList =res.filter(function(item){return item.defaulted;});
        });

        //辅材模板  accessories 辅料 / product 成品
        MaterialTemplates.getAll({
            type: 'accessories'
        }, function(res){
            vm.materialTemplatesList =  res.filter(function(item){return item.enable;})
        });
        vm.materialTypeList =[
            {name: '原材料', id: '原材料'},
            {name: '纸张', id: '纸张'},
            {name: '包材', id: '包材'}
        ];
        vm.data = {
            name: '',
            materialTemplate: {},
            type: '',
            measurementId: '',
            measurementName: '',
            enable: false,
            propertyConfigs: []
        };
        //获取订单编号
        Material.getNumber({}, function (res) {
            vm.data.number = res.number;
        });

        //辅材品类一级
        vm.productCategoryList = ProductCategory.getAll({type:'accessories'});
        //计量单位选中
        $scope.unitSelect = function(){
            for(var i=0; i<vm.unitList.length; i++){
                if(vm.unitList[i].id == vm.data.measurementId){
                    vm.data.measurementName = vm.unitList[i].name;
                }
            }
        };
        //辅材分类一级
        vm.superiorName = null;
        //辅材模板选择
        $scope.materialTemplateSelect = function(){
            vm.commodityProperty =[];
            vm.superiorName = null;
            //商品属性
            if(vm.data.materialTemplate ==null){
                vm.commodityPropertyList =[];
                vm.data.type ='';
            }else{
                vm.commodityPropertyList = vm.data.materialTemplate.items;
                vm.data.type = vm.data.materialTemplate.productCategoryName;

                for(var i=0; i<vm.productCategoryList.length; i++){
                    if(vm.productCategoryList[i].id == vm.data.materialTemplate.productCategoryId){
                        vm.data.materialTemplate.productCategoryName = vm.productCategoryList[i].name;
                        if(vm.productCategoryList[i].superiorId){
                            vm.superiorName = vm.productCategoryList[i].superiorName;
                        }
                    }
                }
            }
        };

        vm.commodityProperty = [];
        $scope.commodityPropertyFn = function($event, data){
            //var checkbox = $event.target.checked;
            //if(checkbox){
            //    vm.commodityProperty.push(data)
            //}else {
            //    for(var i=0; i<vm.commodityProperty.length; i++){
            //        if(vm.commodityProperty[i].id == data.id){
            //            vm.commodityProperty.splice(i, 1)
            //        }
            //    }
            //}
            vm.commodityProperty =vm.commodityPropertyList.filter(function(item){
                return item.checked;
            });
        };

        //商品属性快速生成
        $scope.rapidGenerating = function () {
            if(!vm.data.name){
                MessageService.error("请填写辅材名称！");
                return;
            }else if(vm.data.materialTemplate.id == undefined){
                MessageService.error("请选择辅材模板！");
                return;
            }else if(vm.commodityProperty.length ==0){
                MessageService.error("请选择属性！");
                return;
            }else{
                $uibModal.open({
                    templateUrl: 'app/product/finished/modal/property.html',
                    controller: 'PropertyController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function () {
                            return vm.commodityProperty;
                        }]
                    }
                }).result.then(function (backData) {
                    if (typeof backData == "object") {
                        var _backData = [];
                        for (var x = 0; x < backData.length; x++) {
                            if (backData[x].length > 0) {
                                _backData.push(backData[x]);
                            }
                        }
                        var _arr = getCombination(_backData);
                        if (typeof _arr == "object") {
                            for (var i = 0; i < _arr.length; i++) {
                                var _sku = vm.data.name + _arr[i];
                                var _isSku = false;
                                for(var j=0; j<vm.data.propertyConfigs.length; j++){
                                    var _sku1 = vm.data.name + vm.data.propertyConfigs[j].sku;
                                    if(_sku == _sku1){
                                        _isSku = true;
                                    }
                                }
                                if(!_isSku) {
                                    var _obj = {
                                        sku: _arr[i],
                                        number: '',
                                        minStorage: '',
                                        warehouseConfigs: null
                                    };
                                    vm.data.propertyConfigs.push(_obj);
                                }
                            }
                        }
                    }
                });
            }
        };
        function getCombination (array) {
            if(array.length > 0){
                var _arr = [];
                if (array.length < 2) {
                    for(var j =0; j<array[0].length; j++){
                        _arr.push([array[0][j]])
                    }
                }else {
                    _arr = [].reduce.call(array, function (col, set) {
                        var res = [];
                        col.forEach(function (c) {
                            set.forEach(function (s) {
                                var t = [].concat(Array.isArray(c) ? c : [c]);
                                t.push(s);
                                res.push(t);
                            })
                        });
                        return res;
                    });
                }
                for(var i=0; i<_arr.length; i++){
                    var _h = '';
                    var _arrI = _arr[i].sort();
                    for(var j=0; j<_arrI.length; j++){
                        _h += '_' + _arrI[j];
                    }
                    _arr[i] = _h
                }
                return _arr
            }
        }

        //设置最小库存预警
        $scope.warningSet = function(index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/minWarning.html',
                controller: 'MinWarningController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {type: true}
                    }]
                }
            }).result.then(function (backData) {
                console.log(backData)
                if(typeof backData == "object"){
                    vm.data.propertyConfigs[index].minStorage = backData.minStorage;
                    vm.data.propertyConfigs[index].depotsWarning = backData.depotsWarning;
                    vm.data.propertyConfigs[index].warehouseConfigs = backData.warehouseConfigs;
                }
            });
        }
        $scope.warningEdit = function(data,index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/minWarning.html',
                controller: 'MinWarningController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {type: true, data: data};
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    vm.data.propertyConfigs[index].minStorage = backData.minStorage;
                    vm.data.propertyConfigs[index].depotsWarning = backData.depotsWarning;
                    vm.data.propertyConfigs[index].warehouseConfigs = backData;
                }
            });
        }
        $scope.save = function() {
            if(!vm.data.name){
                MessageService.error("辅材名称不能为空");
                return;
            }
            if(vm.data.name.length >20){
                MessageService.error("辅材名称不能超过20位");
                return;
            }
            if(!vm.data.materialTemplate.id){
                MessageService.error("辅材模板不能为空");
                return;
            }
            if(!vm.data.type){
                MessageService.error("辅材品类不能为空");
                return;
            }
            if(vm.data.propertyConfigs.length == 0){
                MessageService.error("属性不能为空");
                return;
            }
            var _data = angular.copy(vm.data);
            for(var i=0; i<_data.propertyConfigs.length; i++){
                _data.propertyConfigs[i].sku = _data.name + _data.propertyConfigs[i].sku;
                _data.propertyConfigs[i].number = _data.number + '-' + (Number(i)+Number(1));
                _data.propertyConfigs[i].sort = Number(i) + Number(1);
            }

            vm.isSaving = true;
            Material.save(_data, function (res) {
                MessageService.success("保存成功");
                $state.go("material");
            }, function (error) {
                vm.isSaving = false;
                MessageService.error(error.data.message);
            });
        };

        $scope.propertyDelete = function(index){
            vm.data.propertyConfigs.splice(index,1);
        };

    }
})();
