(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('item', {
            parent: 'module',
            url: '/item',
            params:{page: null},
            data: {
                authorities: ['ROLE_ITEM']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/item/item.html',
                    controller: 'ItemController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })
        .state('itemAdd', {
            parent: 'module',
            url: '/itemAdd',
            data: {
                authorities: ['ROLE_ITEM']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/item/item-add.html',
                    controller: 'ItemAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })
        .state('itemEdit', {
            parent: 'module',
            url: '/itemEdit/{id}',
            params:{page: null},
            data: {
                authorities: ['ROLE_ITEM']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/item/item-edit.html',
                    controller: 'ItemEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Items', function ($stateParams, Items) {
                    return Items.detail({id: $stateParams.id});
                }]
            }
        });
    }
})();
