(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ReplenishmentReasonAddController', ReplenishmentReasonAddController);

    ReplenishmentReasonAddController.$inject = ['$scope','$state','MessageService', 'ReplenishmentReason'];

    function ReplenishmentReasonAddController ($scope, $state,MessageService,ReplenishmentReason) {
        var vm = this;
        init();
        function init(){
            vm.replenishmentReason = {
                reason: '',
                enable: true,
                remarks: '',
                type: ''
            };
        }

        vm.belongs =[
            {name:'平台',value:'platform'},
            {name:'供应商',value:'supplier'}
        ];

        $scope.save = function () {
            if(!vm.replenishmentReason.reason){
                MessageService.error("原因不能为空");
                return;
            }
            if(vm.replenishmentReason.reason.length >20){
                MessageService.error("原因不能大于20位");
                return;
            }
            if(!vm.replenishmentReason.remarks){
                MessageService.error("描述不能为空");
                return;
            }
            if(vm.replenishmentReason.remarks >50){
                MessageService.error("描述不能大于50位");
                return;
            }
            if(!vm.replenishmentReason.type){
                MessageService.error("所属不能为空");
                return;
            }
            vm.isSaving = true;
            ReplenishmentReason.add(vm.replenishmentReason, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("添加成功");
                $state.go("replenishmentReason");
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("添加失败，请稍后重试！");
            }
        };
    }
})();
