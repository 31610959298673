(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('FileCheckController', FileCheckController);

    FileCheckController.$inject = ['$scope', '$state','Product','MessageService','entity', '$uibModalInstance'];

    function FileCheckController ($scope, $state,Product,MessageService, entity, $uibModalInstance) {
        var vm = this;
        vm.flowData = {
            fileWidth:'',
            filePageCount:'',
            fileHeight:''
        };
        init();
        function init(){
            vm.type = entity.type;
            if (!vm.type) {
                vm.flowData = {
                    fileWidth: entity.data.fileWidth,
                    filePageCount: entity.data.filePageCount,
                    fileHeight: entity.data.fileHeight
                };
            }
        };

        $scope.flowSave = function () {
            if(!vm.flowData.fileWidth){
                MessageService.error("PDF文件宽度不能为0");
                return;
            }
            if(!vm.flowData.filePageCount){
                MessageService.error("PDF文件总页数不能为0");
                return;
            }
            if(!vm.flowData.fileHeight){
                MessageService.error("PDF文件高度不能为0");
                return;
            }
            $uibModalInstance.close(vm.flowData);
        };
        $scope.modalHide = function(){
            $uibModalInstance.close();
        };
    }
})();
