(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ChooseProductController', ChooseProductController);

    ChooseProductController.$inject = ['$q', '$scope','$uibModalInstance','MessageService','Product','$timeout'];

    function ChooseProductController ($q, $scope, $uibModalInstance,MessageService,Product,$timeout) {
        var vm = this;
        vm.keyword ='';
        vm.selectIndex ='';
        vm.first =[];
        vm.tableList =[];
        vm.data=[];
        var tempArr =[];

        var productArr = {key: null, value: null};

        vm.productList = Product.sortByCategory({keyword:vm.keyword});
        $q.all([vm.productList.$promise]).then(function() {
            for (var k in vm.productList) {
                if(k == '$promise' || k == '$resolved'|| k == 'toJSON'|| k == '$get'|| k == '$save'|| k == '$query' 
                    || k == '$remove'|| k == '$delete'|| k == '$getAll'|| k == '$getNumber'|| k == '$sortByCategory'
                    || k == '$getAllByCategory'|| k == '$update'|| k == '$validate'){
                } else {
                    console.log('一级 --- '+k);

                    var level_2 = {name: null, product: null};
                    var levels_2 = [];
                    for (var i = vm.productList[k].length - 1; i >= 0; i--) {
                        for (var j in vm.productList[k][i]) {
                            console.log('   二级  ---' + j);

                            level_2 = {name: j, product: []};
                            var product_sku = {pname: null, sku: null};

                            for (var p in vm.productList[k][i][j]) {
                                for (var q in vm.productList[k][i][j][p]) {
                                    // sku
                                    product_sku = {pname: q, sku: vm.productList[k][i][j][p][q]};
                                    level_2.product.push(product_sku);
                                }
                            }
                            level_2.product.push(product_sku);
                            levels_2.push(level_2);
                        }
                    }

                    productArr = {key: k, value: levels_2};
                    vm.data.push(productArr);
                }
            }
            console.log(vm.data);
        });

        $timeout(function(){
            angular.element('.firstItem').click(function(){
                if(!$(this).hasClass('on')){
                    $(this).next('.subItems').slideDown(200).parent().siblings('.mainItems').find('.subItems').slideUp(200);
                    $(this).addClass('on').parent().siblings('.mainItems').find('.firstItem').removeClass('on');
                    $(this).find('.plus_first').addClass('rotateIcon').end().parent().siblings('.mainItems').find('.plus_first').removeClass('rotateIcon');
                }else{
                    $(this).removeClass('on');
                    $(this).next('.subItems').slideUp(200).parent().siblings('.mainItems').find('.subItems').slideUp(200);
                    $(this).find('.plus_first').removeClass('rotateIcon');
                }
            });
        },500);

        $scope.getProduct = function(key,data){
            vm.selectIndex =-1;
            vm.tableList=data;
            vm.navKey =key;
        };

        //这里搜索有问题
        $scope.search = function(){
            vm.tableList = vm.data.filter(function(item){
                return item.sku ==vm.keyword ||item.number ==vm.keyword;
            });
            //展开列表收起
            $('.firstItem').removeClass('on');
            $('.firstItem').next('.subItems').slideUp(200).parent().siblings('.mainItems').find('.subItems').slideUp(200);
            $('.firstItem').find('.plus_first').removeClass('rotateIcon');
        };

        vm.product=null;
        $scope.productChoice = function(data,index){
            vm.selectIndex = index;
            vm.product=data;
        };

        $scope.confirm = function(){
          if(vm.product==null){
              MessageService.error('请选择商品！');
          }else{
              $uibModalInstance.close(vm.product);
          }
        };

        $scope.cancel = function(){
            $uibModalInstance.close();
        };

    }
})();
