(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CRechargeAmountController', CRechargeAmountController);

    CRechargeAmountController.$inject = ['$scope', 'Principal', 'LoginService','$state','Channels','msgdata','$uibModal','$uibModalInstance','MessageService'];

    function CRechargeAmountController ($scope, Principal, LoginService,$state,Channels,msgdata,$uibModal,$uibModalInstance,MessageService) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        console.log(msgdata);

        function clear(){
            $uibModalInstance.dismiss();
        }

        $scope.validate = function () {
            if (vm.balance == null || vm.balance == "") {
                MessageService.error("充值金额不能为空");
                return false;
            }
            return true;
        };

        function save () {
            if (!$scope.validate()) {
                return;
            }

            //plus充值/minus消费
            Channels.changeBalance({
                channelId: msgdata.id,
                balance: vm.balance,
                type: 'plus'
            }, function (res) {
                vm.isSaving = false;
                    MessageService.success("充值金额成功");
                    $uibModalInstance.close(res);
            }, function (error) {
                vm.isSaving = false;
                MessageService.error("充值金额失败");
            });
        }

    }
})();
