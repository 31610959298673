(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MachineEditController', MachineEditController);

    MachineEditController.$inject = ['$scope', '$state','MessageService', 'Machine', 'entity', '$stateParams','Supplier','$uibModal'];

    function MachineEditController ($scope,$state,MessageService,Machine, entity, $stateParams,Supplier,$uibModal) {
        var vm = this;
        vm.machine = entity;

        Supplier.getAll({},function(res){
            vm.suppliers =res.filter(function(item){
                return item.enable;
            });
        });

        $scope.setImposition = function(){
            $uibModal.open({
                templateUrl: 'app/setting/machineTypes/set-pin.html',
                controller: 'SetPinController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return vm.machine.makeups;
                    }
                }
            }).result.then(function(result){
                if(!result){
                    vm.machine.makeups = [];
                }else{
                    vm.machine.makeups = result;
                }
            });
        };

        $scope.save = function () {
            if(!vm.machine.type){
                MessageService.error("机型名称不能为空");
                return;
            }
            if(vm.machine.type.length >20){
                MessageService.error("机型名称不能大于20位");
                return;
            }
            if(!vm.machine.supplierId){
                MessageService.error("供应商不能为空");
                return;
            }
            if(vm.machine.makeups.length == 0){
                MessageService.error("拼版方式不能为空");
                return;
            }
            if(!vm.machine.capacity){
                MessageService.error("产能不能为空");
                return;
            }
            vm.isSaving = true;
            Machine.update(vm.machine, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("修改成功");
                $state.go("machine",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("修改失败，请稍后重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("machine",{page: $stateParams.page});
        }
    }
})();
