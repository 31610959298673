(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$http', '$q', '$scope', 'Principal', '$state', '$localStorage', 'SalesOrder', 'Supplier', 'Channels', 'Reserves', 'Notices'];

    function HomeController ($http, $q, $scope, Principal, $state, $localStorage, SalesOrder, Supplier, Channels, Reserves, Notices) {
        var vm = this;
        vm.orderDatas = SalesOrder.getHotLinkData();
        vm.supplierDatas = Supplier.getHomePageData();
        vm.channelDatas = Channels.getHomePageData();
        vm.reserveDatas = Reserves.getHomePageData();
        vm.newsDatas = Notices.getNewest();
        var myChart = echarts.init(document.getElementById('main'));
        myChart.showLoading();
        $q.all([vm.orderDatas.$promise]).then(function(){
           
            myChart.hideLoading();
            vm.startDate = vm.orderDatas.dateJson[0].date;
            vm.endDate = vm.orderDatas.dateJson[6].date;
            chart(vm.orderDatas.dateJson, myChart);
            function chart(data, myChart) {
                var _time = [];
                var _data = [];
                var _legend = "订单数";
                if (data && data) {
                    for(var i = 0; i< data.length; i++){
                        _time.push(data[i].date); 
                        _data.push(data[i].quantity);
                    }
                }
                var option = {
                    tooltip: {trigger: 'axis'},
                    legend: {
                        data:[_legend]
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        data: _time
                    },
                    yAxis: {},
                    series: [{
                        name: _legend,
                        type: 'line',
                        itemStyle : {
                            normal : {
                                color:'#ff9601',
                                lineStyle:{
                                    color:'#ff9601'
                                }
                            }
                        },
                        data: _data
                    }]
                };
                myChart.setOption(option);
            }
        });
    }
})();
