(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PrePressFlowEditController', PrePressFlowEditController);

    PrePressFlowEditController.$inject = ['$scope','$state','MessageService', 'PrePressFlow', 'entity', '$stateParams'];

    function PrePressFlowEditController ($scope, $state,MessageService,PrePressFlow, entity, $stateParams) {
        var vm = this;
 
        vm.prePressFlow = entity;

        $scope.save = function () {
            if(!vm.prePressFlow.name){
                MessageService.error("名称不能为空");
                return;
            }
            if(vm.prePressFlow.name.length >20){
                MessageService.error("名称不能大于20位");
                return;
            }
            if(!vm.prePressFlow.codeName){
                MessageService.error("印前流程名称不能为空");
                return;
            }
  
            vm.isSaving = true;
            if (vm.prePressFlow.id) {
                PrePressFlow.update(vm.prePressFlow, onSaveSuccess, onSaveError);
            }else{
                PrePressFlow.save(vm.prePressFlow, onSaveSuccess, onSaveError);
            }

            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("修改成功");
                $state.go("prePressFlow",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("修改失败，请稍后重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("prePressFlow",{page: $stateParams.page});
        }
    }
})();
