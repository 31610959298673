(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('project', {
                parent: 'module',
                url: '/project',
                params:{page: null,status: null,keyword: null},
                data: {
                    authorities: ['ROLE_PROJECT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/project/project.html',
                        controller: 'ProjectController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('projectAdd', {
                parent: 'module',
                url: '/projectAdd',
                data: {
                    authorities: ['ROLE_PROJECT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/project/project-add.html',
                        controller: 'ProjectAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProductCategory', function($stateParams, ProductCategory) {
                        return ProductCategory.get();
                    }]
                }
            })
            .state('projectEdit', {
                parent: 'module',
                url: '/projectEdit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_PROJECT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/project/project-edit.html',
                        controller: 'ProjectEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Project', function($stateParams, Project) {
                        return Project.detail({id: $stateParams.id,page:$stateParams.page,keyword:$stateParams.keyword});
                    }]
                }
            })
            .state('projectDetail', {
                parent: 'module',
                url: '/projectDetail/{id}',
                params:{page: null,status: null,keyword: null},
                data: {
                    authorities: ['ROLE_PROJECT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/project/project-detail.html',
                        controller: 'ProjectDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Project', function($stateParams, Project) {
                        return Project.detail({id: $stateParams.id,page:$stateParams.page,keyword:$stateParams.keyword});
                    }]
                }
            })
        ;
    }
})();
