(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ShelfManagementAddsController', ShelfManagementAddsController);

    ShelfManagementAddsController.$inject = ['$scope','$state','MessageService', 'GoodsShelves','ProductCategory','Supplier','$q'];

    function ShelfManagementAddsController ($scope, $state,MessageService,GoodsShelves,ProductCategory,Supplier,$q) {
        var vm = this;
        vm.data = {
            prefix: '',
            beginNumber: '',
            quantity: '',
            productCategoryId: '',
            supplierId:''
        };
        vm.productTypes = ProductCategory.getAll();
        vm.supplier = Supplier.getAll();
        $q.all([vm.productTypes.$promise,vm.supplier.$promise]).then(function() {
            vm.typeList =vm.productTypes.filter(function(item){return item.enable == true;});
            vm.suppliers =vm.supplier.filter(function(item){return item.enable == true;});
        });

        $scope.save = function () {
            if(!vm.data.quantity){
                MessageService.error("货架数量不能为空");
                return;
            }
            if(vm.data.quantity >999){
                MessageService.error("货架数量不能超过999");
                return;
            }
            if(!vm.data.supplierId){
                MessageService.error("供应商不能为空");
                return;
            }
            vm.isSaving = true;
            GoodsShelves.createBatch(vm.data, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("添加成功");
                $state.go("shelfManagement");
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("添加失败，请稍后重试！");
            }
        };
    }
})();
