(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('ProofRule', ProofRule);

    ProofRule.$inject = ['$resource'];

    function ProofRule ($resource) {
        var resourceUrl =  'api/proof-rules/:path/:id';

        return $resource(resourceUrl, {}, {
            'page': { 
                method: 'GET', 
                isArray: true,
                params: {
                    path: 'page'
                },
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                isArray: true,
                params: {
                    path: 'getAll'
                }
            } ,
            'delete':{
                method:'DELETE',
            },
            'enable': { 
                method:'GET',
                params:{
                    path:"enable"
                },
             } ,
            'update': { method:'PUT' },
        });
    }

})();
