(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SupplyNewController', SupplyNewController);

    SupplyNewController.$inject = ['$scope','entity','$state','User','$uibModal','MessageService', 'Supplier','$q', '$stateParams','Product','Machine','Makeups', 'SupplierAbutSetting'];

    function SupplyNewController ($scope,entity,$state,User,$uibModal,MessageService,Supplier,$q, $stateParams,Product,Machine,Makeups,SupplierAbutSetting) {
        var vm = this;
        vm.save= save;
        vm.supply = entity;
        console.log(entity);
        vm.multimediaGroups =[
            {
                name:'outsource',
                value:'委外'
            },{
                name:'SourceMaterials',
                value:'原辅材料'
            }];
        vm.cashiers =[
            {name:'CashSettlement',value:'现结'},
            {name:'MonthlyKnots',value:'月结'}
        ];
        vm.payTypes =[
            {name:'InvoicePayment',value:'开票付款'},
            {name:'CashDelivery',value:'货到付款'}
        ];
        vm.settlementTypes =[
            {name:'DownLoad',value:'下载结算'},
            {name:'Delivered',value:'发货结算'}
        ];


        Product.getAll({},function(res){
            vm.products = res.filter(function(item){return item.enable});
        });

        Machine.getAll({},function(res){
            vm.machines = res.filter(function(item){return item.enable});
        });

        vm.supplierAbutSettings = SupplierAbutSetting.getAll({});

        $scope.chooseMachine = function(data){
            if(data.deviceId==null){
            }else{
                Machine.detail({id:data.deviceId},function(res){
                    data.makeups = res.makeups;
                    data.capacity = res.capacity;
                })
            }
        };


        $scope.productionDelete = function(index){
            vm.supply.productionManages.splice(index,1);
        };

        //设置区域
        $scope.productionEdit = function(data){
            console.log(data);
            $uibModal.open({
                templateUrl: 'app/supply/zone.html',
                controller: 'ZoneController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return data.areaList;
                    }
                }
            }).result.then(function(result){
                data.areaList = result;
            });
        };

        // 设置价格
        $scope.priceEdit = function(data){
            $uibModal.open({
                templateUrl: 'app/supply/sku-price.html',
                controller: 'SkuPriceController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return data;
                    }
                }
            }).result.then(function(result){
                data.propertyPriceList = result;
            });
        };

        $scope.skuFileDetail = function(data){
            $uibModal.open({
                templateUrl: 'app/supply/sku-file.html',
                controller: 'SkuFileController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return data;
                    }
                }
            }).result.then(function(result){
                console.log(result);
                // data.skuManageList = result;
                //data.areaList = result;
            });
        };

        $scope.skuMakeupDetail = function(data){
            data.abutType == null;
            data.supplierAbutSettingId = null;
            if (vm.supply.supplierAbutSettingId && vm.supplierAbutSettings) {
                for (var i = vm.supplierAbutSettings.length - 1; i >= 0; i--) {
                    var abut = vm.supplierAbutSettings[i];
                    if (abut.id == vm.supply.supplierAbutSettingId ) {
                        data.abutType = abut.outwardMerchant;
                        data.supplierAbutSettingId = vm.supply.supplierAbutSettingId
                        break;
                    }
                }       
            }
            $uibModal.open({
                templateUrl: 'app/supply/sku-makeup.html',
                controller: 'SkuMakeupController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return data;
                    }
                }
            }).result.then(function(result){
                console.log(result);
                data.skuManageList = result;
                //data.areaList = result;
            });
        };

        $q.all([vm.supply.$promise,vm.supplierAbutSettings.$promise]).then(function(){
            if(vm.supply.id!=''){
                $scope.titleContent = '编辑供应商';
                angular.forEach(vm.multimediaGroups,function(item){
                    if(vm.supply.category == item.name){
                        vm.category = item;
                        if(vm.category.name == 'SourceMaterials'){
                            $('.special').attr('disabled',true);
                            if(vm.supply.username =='' || vm.supply.username == null){
                                $('.special2').hide();
                            }
                        }
                    }
                });
                vm.user={
                    username:vm.supply.username,
                    password:null,
                    confirmPassword:null,
                    userId:vm.supply.userId
                };

                angular.forEach(vm.supply.productionManages,function(data){
                    if (data.deviceId) {
                        Machine.detail({id:data.deviceId},function(res){
                            data.makeups = res.makeups;
                        })
                    }else{
                        data.makeups =[];
                    }
                    
                })
            }else{
                $scope.titleContent = '新建供应商';
                vm.supply =[];
                vm.supply.locations =[];
                vm.supply.makeups =[];
                vm.supply.productionManages =[];
                vm.user =null;
                $('.special').attr('disabled',false);
                $('.special2').show();
            }
        });

        //添加商品管理
        $scope.addproduction = function(){
            var productionManage ={
                productId:'',
                productName:'',
                deviceId:'',
                deviceType:'',
                makeupId:'',
                makeupName:'',
                capacity:'',
                imgDpi:300,
                areaList:[],
                propertyPriceList: []
            };

            vm.flag = false;
            angular.forEach(vm.supply.productionManages,function(item){
                if(item.areaList ==null || item.areaList.length ==0 || !item.productId){
                    vm.flag = true;
                }
            });
            if(vm.flag){
                MessageService.error("请完善空白项，再追加");
            }else{
                vm.supply.productionManages.push(angular.copy(productionManage));
            }
        };


        //新建 or 编辑 用户名
        $scope.modifyContent = function(userInfo){
            if(!vm.supply.id){
                $uibModal.open({
                    templateUrl: 'app/supply/create-user.html',
                    controller: 'CreateUserController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('supplier');
                            return $translate.refresh();
                        }],
                        msgdata: function () {
                            return userInfo;
                        },
                        entity: function () {
                            return null;
                        }
                    }
                }).result.then(function(result){
                    if(result){
                        vm.user = result;
                        vm.supply.username = result.name;
                        console.log(vm.user);
                    }
                });
            }else{
                $uibModal.open({
                    templateUrl: 'app/user/user-password.html',
                    controller: 'UserPasswordController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('supplier');
                            return $translate.refresh();
                        }],
                        entity: function () {
                            return userInfo;
                        }
                    }
                }).result.then(function(result){
                    if(result){
                        vm.user = result;
                        vm.supply.username = result.username;
                        console.log(vm.user);
                    }
                });
            }

        };
        //设置拼版
        $scope.setImposition = function(pins){
            $uibModal.open({
                templateUrl: 'app/supply/set-imposition.html',
                controller: 'SetImpositionController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return pins;
                    },
                    entity: function () {
                        return null;
                    }
                }
            }).result.then(function(result){
                vm.supply.makeups = result;
            });
        };

        //添加地址
        $scope.addAddress = function(data){
            $uibModal.open({
                templateUrl: 'app/supply/address-new.html',
                controller: 'AddressNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return data;
                    }
                }
            }).result.then(function(result){
                vm.supply.locations.push(result);
            });
        };

        //编辑地址
        $scope.addressEdit = function(data,index){
            $uibModal.open({
                templateUrl: 'app/supply/address-new.html',
                controller: 'AddressNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return data;
                    }
                }
            }).result.then(function(result){
                console.log(result);
                vm.supply.locations[index] = result;
            });
        };

        //删除地址
        $scope.addressDelete = function(index){
            vm.supply.locations.splice(index,1);
        };

        $scope.validate = function () {
            if(vm.supply.name =='' || vm.supply.name == null){
                MessageService.error("供应商简称不能为空");
                return;
            }
            if(vm.supply.name.length >20){
                MessageService.error("供应商简称不能超过20位");
                return;
            }
            if(vm.supply.fullname =='' || vm.supply.fullname == null){
                MessageService.error("供应商全称不能为空");
                return;
            }
            if(vm.supply.fullname.length >30){
                MessageService.error("供应商全称不能超过30位");
                return;
            }
            if(vm.category =='' || vm.category == null){
                MessageService.error("分类不能为空");
                return;
            }
            if(vm.category.name =='outsource'){
                if(vm.supply.username =='' || vm.supply.username == null){
                    MessageService.error("用户名不能为空");
                    return;
                }
            }

            if(vm.supply.taxRate < 0 && vm.supply.taxRate > 100 || vm.supply.taxRate == undefined){
                MessageService.error("税率只能为0-100的整数");
                return;
            }

            // if(!vm.supply.settlementMethod){
            //     MessageService.error("结算方式不能为空");
            //     return;
            // }
            // if(!vm.supply.paymentTerm){
            //     MessageService.error("付款条件不能为空");
            //     return;
            // }
            // if(!vm.supply.taxCode){
            //     MessageService.error("纳税登记编码不能为空");
            //     return;
            // }
            // if(!vm.supply.registeredAddress){
            //     MessageService.error("注册地址不能为空");
            //     return;
            // }
            // if(!vm.supply.remarks){
            //     MessageService.error("备注不能为空");
            //     return;
            // }

            vm.flag = false;
            angular.forEach(vm.supply.productionManages,function(item){
                if(item.areaList ==null || item.areaList.length ==0 || !item.productId){
                    vm.flag = true;
                }
            });
            if(vm.flag){
                MessageService.error("生产管理存在空值，请检查修改");
                return;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;

            var obj={
                name:vm.supply.name,
                fullname:vm.supply.fullname,
                category:vm.category.name,
                username:vm.supply.username,
                userId:vm.supply.userId,
                enable:vm.supply.enable,
                //makeups:vm.supply.makeups,
                locations:vm.supply.locations,
                taxRate:vm.supply.taxRate,
                settlementMethod:vm.supply.settlementMethod,
                settlementType:vm.supply.settlementType,
                paymentTerm:vm.supply.paymentTerm,
                taxCode:vm.supply.taxCode,
                warnBalance:vm.supply.warnBalance,
                registeredAddress:vm.supply.registeredAddress,
                remarks:vm.supply.remarks,
                productionManages:vm.supply.productionManages,
                balance: vm.supply.balance,
                outward: vm.supply.outward,
                outwardType: vm.supply.outwardType,
                supplierAbutSettingId: vm.supply.supplierAbutSettingId,
            };
            console.log(obj);
            if(vm.supply.id){
                obj.id=vm.supply.id;
                Supplier.update(obj,function (res) {
                    MessageService.success("更新成功！");
                    $state.go('supplyManagement',{page: $stateParams.page});
                },function (error) {
                    vm.isSaving = false;
                    MessageService.error("更新失败！");
                });
            }else{
                if(obj.userId ==  undefined && obj.username == undefined && obj.category == 'SourceMaterials'){
                    Supplier.create(obj,function (res) {
                        MessageService.success("供应商新建成功！");
                        $state.go('supplyManagement',{page: $stateParams.page});
                    },function (error) {
                        vm.isSaving = false;
                        MessageService.error("供应商新建失败！");
                    });
                }else{
                    User.saveSupply({username:vm.user.name,password:vm.user.password, admin: vm.user.admin}, onSaveSuccess, onSaveError);
                }
            }

            function onSaveSuccess (result) {
                obj.userId =result.id;
                Supplier.create(obj,function (res) {
                    MessageService.success("供应商新建成功！");
                    $state.go('supplyManagement',{page: $stateParams.page});
                },function (error) {
                    vm.isSaving = false;
                    MessageService.error("供应商新建失败！");
                });
            }
            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("用户名添加失败");
            }

        }
        //返回
        $scope.goBack = function () {
            $state.go("supplyManagement",{page: $stateParams.page});
        }
    }
})();
