(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ShippedAgainController', ShippedAgainController);

    ShippedAgainController.$inject = ['$q', '$scope', 'Principal', '$state', 'MessageService', 'LocationProvice', '$localStorage', '$uibModalInstance', 'entity','LocationCity','LocationDistrict', 'DeliveryRequest'];

    function ShippedAgainController ($q, $scope, Principal, $state, MessageService, LocationProvice, $localStorage, $uibModalInstance, entity,LocationCity,LocationDistrict, DeliveryRequest) {
        var vm = this;
        vm.data={
            id: entity[0].id,
            contact:entity[0].contact,
            phone:entity[0].phone,
            address:''
        }
        vm.datalist = entity[0];
        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;
        init();
        function init(){
            vm._address = vm.datalist.address.split("-");
            vm.address = vm._address[3] ? vm._address[3] : "";
            LocationProvice.query({},function (data) {
                vm.provinces =  data;
                if(vm._address[0]){
                    for(var i=0; i<data.length; i++){
                        if(vm._address[0] == data[i].name){
                            vm.province = data[i];
                            changeProvince()
                        }
                    }
                }
            });
        }
        function changeProvince() {
            LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                vm.citys = data;
                vm.districts = [];
                if(vm._address[1]){
                    for(var i=0; i<data.length; i++){
                        if(vm._address[1] == data[i].name){
                            vm.city = data[i];
                            changeCity()
                        }
                    }
                }
            });
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
                if(vm._address[2]){
                    for(var i=0; i<data.length; i++){
                        if(vm._address[2] == data[i].name){
                            vm.district = data[i];
                        }
                    }
                }
            });
        }

        $scope.save = function(){
            if(!vm.data.contact){
                MessageService.error("姓名不能为空");
                return
            }

            if (vm.data.phone == null || vm.data.phone == "") {
                MessageService.error("联系方式不能为空");
                return false;
            }

            if(!vm.province){
                MessageService.error("省市区不能为空");
                return
            }
            if(!vm.city){
                MessageService.error("市区不能为空");
                return
            }
            if(!vm.district){
                MessageService.error("区县不能为空");
                return
            }
            if(!vm.address){
                MessageService.error("详细地址不能为空");
                return
            }
            vm.data.address = vm.province.name + '-' + vm.city.name + '-'  + vm.district.name + '-'  + vm.address;
            console.log(vm.data)
            DeliveryRequest.rebuildDeliveryRequest(vm.data, function (res) {
                $uibModalInstance.close(true);
            })
        }
        //取消
        $scope.modalHide = function () {
            $uibModalInstance.close();
        }
    }
})();
