(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ItemAddController', ItemAddController);

    ItemAddController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'Items'];

    function ItemAddController ($scope, Principal, LoginService, $state,User,MessageService,Items) {
        var vm = this;
        vm.data = {
            name: '',
            //type: '',
            enable: true,
            description: '',
            itemValues: [
                {name: ''}
            ]
        };
        //vm.typeList = [
        //    {name: '原材料', value: '原材料'},
        //    {name: '纸张', value: '纸张'},
        //    {name: '包材', value: '包材'}
        //];

        //删除属性值
        $scope.itemDelete = function(index){
            vm.data.itemValues.splice(index,1);
        };
        //新增属性值
        $scope.itemAdd = function(){
            var arr =vm.data.itemValues.filter(function(item){
                return item.name=='';
            });
            if(arr.length>0){
                MessageService.error("已存在空属性，请添加！");
            }else{
                vm.data.itemValues.push({name: ''});
            }
        };

        $scope.save = function () {
            if(!vm.data.name){
                MessageService.error("名称不能为空");
                return;
            }
            if(vm.data.name.length >20){
                MessageService.error("名称不能大于20位");
                return;
            }
            //if(!vm.data.type){
            //    MessageService.error("类型不能为空");
            //    return;
            //}
            //if(!vm.data.description){
            //    MessageService.error("描述不能为空");
            //    return;
            //}
            if(vm.data.description >50){
                MessageService.error("描述不能大于50位");
                return;
            }

            var arr =vm.data.itemValues.filter(function(item){
                return item.name=='';
            });
            if(arr.length>0){
                MessageService.error("存在空属性，请修改！");
                return;
            }
            vm.isSaving = true;
            Items.add(vm.data, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("添加成功");
                $state.go("item");
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("添加失败，请稍后重试！");
            }
        };
    }
})();
