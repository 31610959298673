(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('UnitMeasurementEditController', UnitMeasurementEditController);

    UnitMeasurementEditController.$inject = ['$scope', '$state','MessageService', 'UnitMeasurement', 'entity', '$stateParams'];

    function UnitMeasurementEditController ($scope,$state,MessageService,UnitMeasurement, entity, $stateParams) {
        var vm = this;
        vm.unitMeasurement = entity;
        vm.typeList =['数量','重量','长度','面积','体积','密度'];

        $scope.save = function () {
            if(!vm.unitMeasurement.name){
                MessageService.error("名称不能为空");
                return;
            }
            if(vm.unitMeasurement.name.length >20){
                MessageService.error("名称不能大于20位");
                return;
            }
            if(!vm.unitMeasurement.type){
                MessageService.error("类型不能为空");
                return;
            }
            if(vm.unitMeasurement.accuracy =='' || vm.unitMeasurement.accuracy ==null){
                vm.unitMeasurement.accuracy = 0;
            }

            if(!vm.unitMeasurement.number){
                MessageService.error("编码不能为空");
                return;
            }
            if (vm.unitMeasurement.number.match(/[\u4E00-\u9FA5]/g,'')) {
                MessageService.error("编码不能输入中文");
                return false;
            }
            vm.isSaving = true;
            UnitMeasurement.update(vm.unitMeasurement, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("修改成功");
                $state.go("unitMeasurement",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("修改失败，请稍后重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("unitMeasurement",{page: $stateParams.page});
        }
    }
})();
