(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('Notices', Notices);

    Notices.$inject = ['$resource'];

    function Notices ($resource) {
        var resourceUrl =  'api/notices/:item/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                params: {
                    id: 'pages'
                }
            },
            'getNewest': {
                method: 'GET',
                isArray: true,
                params: {
                    item: 'getNewest'
                }
            },
            'unRead': {
                method: 'GET',
                isArray: true,
                params: {
                    id: 'pages/unRead'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                isArray: true
            },
            'read': {
                method:'GET',
                params: {
                    item: 'read'
                }
            },
            'readBatch': {
                method:'POST',
                params: {
                    item: 'readBatch'
                }
            },
            'getUnReadCount': {
                method:'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                params: {
                    id: 'getUnReadCount'
                }
            },
        });
    }
})();
