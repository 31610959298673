(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('prePressFlow', {
                parent: 'module',
                url: '/prePressFlow',
                params:{page: null},
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/prepressflow/prepressflow.html',
                        controller: 'PrePressFlowController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('prePressFlowAdd', {
                parent: 'module',
                url: '/prePressFlowAdd',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/prepressflow/prepressflow-edit.html',
                        controller: 'PrePressFlowEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity:  function(){
                        return {};
                    }
                }
            })
            .state('prePressFlowEdit', {
                parent: 'module',
                url: '/prePressFlowEdit/{id}',
                params:{page: null},
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/prepressflow/prepressflow-edit.html',
                        controller: 'PrePressFlowEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PrePressFlow', function($stateParams, PrePressFlow) {
                        return PrePressFlow.get({id : $stateParams.id});
                    }],
                }
            })

        ;
    }
})();
