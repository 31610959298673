(function() {
    'use strict';

    var jhiItemCount = {
        template: 
                '<div class="info">' +
                    '{{($ctrl.page * $ctrl.itemsPerPage) < $ctrl.queryCount ? ($ctrl.page * $ctrl.itemsPerPage) : $ctrl.queryCount}} ' +'条数据,'+
                    '每页'+'{{$ctrl.itemsPerPage}}'+'条,'+'第'+'{{$ctrl.page}}'+'页/'+'共'+'{{$ctrl.s}}'+ '页'+ '</div>',
        bindings: {
            page: '<',
            queryCount: '<total',
            itemsPerPage: '<'
        },
        controller: 'jhiItemCountCtrl'
    };

    angular
        .module('gwApp')
        .component('jhiItemCount', jhiItemCount)
        .controller('jhiItemCountCtrl', jhiItemCountCtrl);
        jhiItemCountCtrl.$inject = [];
        function jhiItemCountCtrl(){
            var vm = this;
            vm.s = Math.ceil(vm.queryCount/vm.itemsPerPage);
        }
})();
