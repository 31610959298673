(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('InformController', InformController);

    InformController.$inject = ['$scope','Prices','MessageService', 'ParseLinks', '$stateParams','Notices'];

    function InformController ($scope, Prices,MessageService, ParseLinks, $stateParams,Notices) {
        var vm = this;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: 'id,DESC',
            keyword: ''
        };
        vm.clear =clear;
        init();
        function init() {
            vm.tableList = [];
            Notices.query(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };


        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };

        function clear(){
            vm.tableData.keyword = '';
            vm.tableData.page = 0;
            init();
        }
        //标记已读
        $scope.read = function (data) {
            Notices.read({id:data.id}, function (res) {
                data.read = true;
                MessageService.success('已读');
            })
        };
    }

})();
