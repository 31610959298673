(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('depository', {
                parent: 'module',
                url: '/depository',
                params:{page: null},
                data: {
                    authorities: ['ROLE_WAREHOUSE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/depository/depository.html',
                        controller: 'DepositoryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('depositoryAdd', {
                parent: 'module',
                url: '/depositoryAdd',
                data: {
                    authorities: ['ROLE_WAREHOUSE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/depository/depository-add.html',
                        controller: 'DepositoryAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('depositoryEdit', {
                parent: 'module',
                url: '/depositoryEdit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_WAREHOUSE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/depository/depository-edit.html',
                        controller: 'DepositoryEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Depository', function($stateParams, Depository) {
                        return Depository.detail({id : $stateParams.id});
                    }]
                }
            });
    }
})();
