(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CustomerHubCopyController', CustomerHubCopyController);

    CustomerHubCopyController.$inject = ['$scope', 'msgdata', '$uibModalInstance', 'MessageService'];

    function CustomerHubCopyController ($scope, msgdata, $uibModalInstance, MessageService) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.data = angular.copy(msgdata);
        vm.selectedAll = false;
        console.log(vm.data)

        $scope.chooseAll = function () {
            for (var i = 0; i < vm.data.skuManages.length; i++) {
                var item = vm.data.skuManages[i];
                if(item.delete||item.cancelled){
                    continue
                }
                item.selected = vm.selectedAll;
            }
        }

        function clear(){
            $uibModalInstance.dismiss();
        }

        function save() {
            angular.forEach(vm.data.skuManages,function(item){
                if(item.selected){
                    if(vm.data.tag=='priceListSku'){
                        item.type = angular.copy(vm.data.data.type);
                        item.baseOn = angular.copy(vm.data.data.baseOn);
                        item.ladderSetups = angular.copy(vm.data.data.ladderSetups);
                        item.priceNum = angular.copy(vm.data.data.priceNum);
                        item.piecePrice = angular.copy(vm.data.data.piecePrice);
                        item.vipPrice = angular.copy(vm.data.data.vipPrice);
                        item.vipPiecePrice = angular.copy(vm.data.data.vipPiecePrice);
                    }else{
                        item.prePress = angular.copy(vm.data.data.prePress);
                        item.prePressFlowId = angular.copy(vm.data.data.prePressFlowId);
                        item.needCheck = angular.copy(vm.data.data.needCheck);
                        item.proofRuleId = angular.copy(vm.data.data.proofRuleId);
                    }

                }
            });
            vm.isSaving = true;
            $uibModalInstance.close(vm.data);
        }
    }
})();
