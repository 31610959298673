(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MerchantAddController', MerchantAddController);

    MerchantAddController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'Merchant', 'Channels', 'CustomerHub',
     'LocationProvice', 'LocationCity', 'LocationDistrict'];

    function MerchantAddController ($scope, Principal, LoginService, $state,User,MessageService,Merchant, Channels, CustomerHub,
     LocationProvice, LocationCity, LocationDistrict) {
        var vm = this;
        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;

        vm.channelList = Channels.getAll();

        vm.customerHubList = CustomerHub.getAll();

        init();
        function init(){
            vm.merchant = {
                name: '',
                number: '',
                sortingTips: '',
                enable: true,
                channel: {
                    id: '',
                    name: ''
                },
                customerHub: {
                    id: '',
                    name: ''
                },
            };
        }

       loadData();
        
        function loadData() {
            LocationProvice.query({},function (data) {
                vm.provinces =  data;
            });

        }

        function changeProvince() {
            LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                vm.citys = data;
                vm.districts = [];
            });
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
            });
        }

        $scope.save = function () {

             if (vm.merchant.shipment) {
                if (vm.province == null || vm.province == "") {
                    MessageService.error("请选择省份");
                    return false;
                };
                vm.merchant.province = vm.province.name;

                if (vm.city == null || vm.city == '') {
                    MessageService.error('请选择城市');
                    return false;
                }
                vm.merchant.city = vm.city.name;

                if (vm.district == null || vm.district == "") {
                    MessageService.error("请选择区县");
                    return false;
                };
                vm.merchant.district = vm.district.name;

                if (vm.merchant.address == null || vm.merchant.address == "") {
                    MessageService.error("请输入详细地址");
                    return false;
                };

                if (vm.merchant.address.length >50) {
                    MessageService.error("详细地址不能大于50位");
                    return false;
                };

                if (vm.merchant.contact == null || vm.merchant.contact == "") {
                    MessageService.error("请输入联系人姓名");
                    return false;
                };
                if (vm.merchant.contact.length >20) {
                    MessageService.error("联系人姓名不能超过20位");
                    return false;
                };
                if (vm.merchant.phone == null || vm.merchant.phone == "") {
                    MessageService.error("请输入联系方式");
                    return false;
                };

            }

            if(!vm.merchant.name){
                MessageService.error("商户名不能为空");
                return;
            }
            if(vm.merchant.name.length >20){
                MessageService.error("商户名不能超过20位");
                return;
            }
            if(!vm.merchant.number){
                MessageService.error("商户号不能为空");
                return;
            }
            if(vm.merchant.number.length >20){
                MessageService.error("商户号不能超过20位");
                return;
            }
            if(!vm.merchant.sortingTips){
                MessageService.error("分拣提示不能为空");
                return;
            }
            if(vm.merchant.sortingTips.length >20){
                MessageService.error("分拣提示不能超过20位");
                return;
            }
            if (!vm.merchant.channelId && !vm.merchant.customerHubId) {
                MessageService.error("请选择所属对象，至少选一个");
                return;
            }
            vm.isSaving = true;
            Merchant.add(vm.merchant, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("添加成功");
                $state.go("merchant");
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("添加失败，请稍后重试！");
            }
        };
    }
})();
