(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('express', {
                parent: 'module',
                url: '/express',
                params:{page: null},
                data: {
                    authorities: ['ROLE_EXPRESS']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/express/express.html',
                        controller: 'ExpressController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('expressAdd', {
                parent: 'module',
                url: '/expressAdd',
                data: {
                    authorities: ['ROLE_EXPRESS']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/express/express-add.html',
                        controller: 'ExpressAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('expressEdit', {
                parent: 'module',
                url: '/expressEdit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_EXPRESS']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/express/express-edit.html',
                        controller: 'ExpressEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Express', function($stateParams, Express) {
                        return Express.detail({id : $stateParams.id});
                    }]
                }
            });
    }
})();
