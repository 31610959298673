(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MerchantsController', MerchantsController);

    MerchantsController.$inject = ['$scope', 'Merchant', '$q', '$uibModalInstance', 'entity', 'MessageService', 'ListService'];

    function MerchantsController ($scope, Merchant, $q, $uibModalInstance, entity, MessageService, ListService) {
        var vm = this;
        vm.selectAll = false;
        //商户列表
        vm.merchantList =  Merchant.getAll();

        $q.all([vm.merchantList.$promise]).then(function () {
            if(!vm.merchantList||!vm.merchantList.length){
                MessageService.error("merchants为null");
            }
            if(typeof entity == "object"){
                vm.data = entity.merchants||[];
                for (var i = 0; i < vm.data.length; i++) {
                    var da =  vm.data[i];
                    var result = ListService.inList(da, vm.merchantList, ["number"]);
                    if (result) {
                        result.select = true;
                    }
                }
                if(vm.data.length==vm.merchantList.length){
                    vm.selectAll = true;
                }
                console.log(vm.data)
            }else {
                vm.data = [];
            }
        })

        $scope.chooseAll = function () {
            // vm.selectAll = !vm.selectAll;
            if(vm.selectAll){
                vm.data = angular.copy(vm.merchantList);
            }else{
                vm.data = [];
            }
            angular.forEach(vm.merchantList,function (data,i) {
                if(vm.selectAll){
                    data.select = true;
                }else{
                    data.select = false;
                }
            })
        }

        $scope.chooseOne = function (data) {
            // data.select = !data.select;
            // 选中
            if(data.select){
                vm.data.push(data);
            }else{
                // 未选中
                vm.data.some(function (da, i) {
                    if (da.id==data.id) {
                        vm.data.splice(i, 1);
                    }
                })
            }

            // 判断全选按钮
            if(vm.data.length==vm.merchantList.length){
                vm.selectAll = true;
            }else{
                vm.selectAll = false;
            }
            console.log("chooseone");
            console.log(vm.data)
        }

        //确认
        $scope.save = function () {
            console.log(vm.data)
            $uibModalInstance.close(vm.data);
        }
        //取消
        $scope.modalHide = function () {
            $uibModalInstance.close();
        }
    }
})();
