(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('DistributeController', DistributeController);

    DistributeController.$inject = ['$scope', 'LoginService', '$state', 'msgdata', '$uibModal', '$uibModalInstance', 'MessageService',
    'Supplier','SalesOrder','Product', 'Makeup'];

    function DistributeController ($scope, LoginService, $state, msgdata, $uibModal, $uibModalInstance, MessageService, 
        Supplier, SalesOrder, Product, Makeup) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.isSaving = false;
        Supplier.getAll(function(res){
            vm.suppliers = res.filter(function(item){return item.enable;})
        });

        $scope.selectSupplier =function(data){
            console.log(data.productionManages);
            vm.productionManages = data.productionManages;
            vm.productionManages2 = [];
            for (var i = vm.productionManages.length - 1; i >= 0; i--) {
                if (vm.productionManages[i].productId == msgdata.productId) {
                    for (var j = vm.productionManages[i].skuManageList.length - 1; j >= 0; j--) {
                        if (vm.productionManages[i].skuManageList[j].propertyId == msgdata.propertyId) {
                            vm.makeup = vm.productionManages[i].skuManageList[j].makeup;
                            vm.productionManages2.push(vm.productionManages[i].skuManageList[j].makeup);
                        }
                    }
                }
            }

            // 说明没有配置拼版方式--获取全部拼版方式
            if (vm.makeup == null) {
                // vm.productionManages2 = Makeup.getAll();
            }
        };
        vm.outsources = msgdata.outsources;
        vm.productId = msgdata.productId;

        Product.get({id: vm.productId}, function (res) {
            //    是否拼版    OrderingProduct:定制商品(有拼版)   StandardProduct:标准印品(可能有拼版)   GeneralProduct:标准通品（无拼版）
            if(res.category == 'GeneralProduct'){
                vm.makeupOrNot =false;
            }else{
                vm.makeupOrNot =true;
            }
        });

        function clear(){
            $uibModalInstance.dismiss();
        }

        $scope.validate = function () {
             if (vm.supplier == null || vm.supplier == "") {
                 MessageService.error("生产商不能为空");
                 return false;
             }
            return true;
        };

        function save () {
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            var obj = {
                ids: vm.outsources.join(','),
                supplierId: vm.supplier.id,
                makeupId: vm.makeup == undefined ? '0' : vm.makeup.id
            };

            SalesOrder.allot(obj, function(res) {
                vm.isSaving = false;
                if (res.state == '4000') {
                    MessageService.error("供应商余额不足，分配失败");
                } else if (res.state == '200') {
                    MessageService.success('分配成功');
                    $uibModalInstance.close(res);
                    $state.go('outsourcingTask');
                }
            },function(error){
                vm.isSaving = false;
                MessageService.error("分配失败");
            });
        }

    }
})();
