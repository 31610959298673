(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SystemController', SystemController);

    SystemController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'System', 'Qinius', '$q', '$timeout'];

    function SystemController ($scope, Principal, LoginService, $state,User,MessageService,System, Qinius, $q, $timeout) {
        var vm = this;
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.ffc_url = {};
        vm.procurementWarning = {};
        vm.orderDays = {};
        vm.kdnUserid = {};
        vm.kdnAppkey = {};
        vm.prePressFlowUrl ={};
        vm.defaultProductImg ={};
        vm.downloadWarning ={};
        vm.projectExamineSwitch = {};
        vm.autoBoxing = {};
        vm.projectFileUrlPrefix = {};


        $q.all([vm.tokens.$promise, vm.domains.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        })

         $scope.removeFile = function () {
            vm.defaultProductImg.value = null;
        };

        //产品 coverImg更改
        window.updateImgFile1 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.defaultProductImg.value = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };



        System.getAll({}, function (res) {
            for(var i=0; i<res.length; i++){
                // if(res[i].key == 'warning_time'){
                //     vm.warningTime = res[i];
                // }
                if (res[i].key == 'ffc_url') {
                    vm.ffc_url = res[i];
                }
                if (res[i].key == 'warning_procurement') {
                    vm.procurementWarning = res[i];
                }
                if (res[i].key == 'order_days') {
                    vm.orderDays = res[i];
                }
                if (res[i].key == 'kdn_userid') {
                    vm.kdnUserid = res[i];
                }
                if (res[i].key == 'kdn_appkey') {
                    vm.kdnAppkey = res[i];
                }
                if (res[i].key == 'pre_press_flow_url') {
                    vm.prePressFlowUrl = res[i];
                }
                if (res[i].key == 'default_product_img') {
                    vm.defaultProductImg = res[i];
                }
                if (res[i].key == 'download_warning') {
                    vm.downloadWarning = res[i];
                }
                if (res[i].key == 'project_examine_switch') {
                    vm.projectExamineSwitch = res[i];
                    if (vm.projectExamineSwitch.value == 'true') {
                        vm.projectExamineSwitch.value = true;
                    } else {
                        vm.projectExamineSwitch.value = false;
                    }
                }
                if (res[i].key == 'auto_boxing') {
                    vm.autoBoxing = res[i];
                    if (vm.autoBoxing.value == 'true') {
                        vm.autoBoxing.value = true;
                    } else {
                        vm.autoBoxing.value = false;
                    }
                }
                if (res[i].key == 'project_file_url_prefix') {
                    vm.projectFileUrlPrefix = res[i];
                }
            }
        });

        $scope.save = function () {
            // if(!vm.warningTime.value){
            //     MessageService.error("预警时间不能为空");
            //     return;
            // }
            // if(isNaN(vm.warningTime.value) || vm.warningTime.value < 0){
            //     MessageService.error("预警时间只能输入大于0的数字");
            //     return;
            // }
            if (vm.kdnUserid == undefined || !vm.kdnUserid.value) {
                MessageService.error("快递鸟客户号不能为空");
                return;
            }
            if (vm.kdnAppkey == undefined || !vm.kdnAppkey.value) {
                MessageService.error("快递鸟密码不能为空");
                return;
            }
            if(vm.orderDays == undefined || !vm.orderDays.value) {
                MessageService.error("订单显示范围不能为空");
                return;
            }
            if(vm.procurementWarning == undefined || !vm.procurementWarning.value){
                MessageService.error("采购预警时间不能为空");
                return;
            }
            if (vm.downloadWarning == undefined || !vm.downloadWarning.value) {
                MessageService.error("下载预警时间不能为空");
                return;
            }
            if(vm.ffc_url == undefined || !vm.ffc_url.value){
                MessageService.error("接口url不能为空");
                return;
            }
            if(!vm.projectFileUrlPrefix || !vm.projectFileUrlPrefix.value){
                MessageService.error("接口url不能为空");
                return;
            }

            vm.isSaving = true;
            // vm.warningTime.key = 'warning_time';
            // System.update(vm.warningTime, function onSaveSuccess () {
            vm.procurementWarning.key = 'warning_procurement';
            vm.ffc_url.key = 'ffc_url';
            vm.orderDays.key = 'order_days';
            vm.kdnUserid.key = 'kdn_userid';
            vm.kdnAppkey.key = 'kdn_appkey';
            vm.downloadWarning.key = "download_warning";
            vm.projectFileUrlPrefix.key = "project_file_url_prefix";
            vm.projectExamineSwitch.key = "project_examine_switch";
            vm.autoBoxing.key = "auto_boxing";
            vm.prePressFlowUrl.key = "pre_press_flow_url";
            vm.defaultProductImg.key = "default_product_img";
            if (vm.projectExamineSwitch.value == null) {
                vm.projectExamineSwitch.value = "false";
            }
            if (vm.autoBoxing.value == null) {
                vm.autoBoxing.value = "false";
            }

            var list = [];
            list.push(vm.procurementWarning);
            list.push(vm.ffc_url);
            list.push(vm.orderDays);
            list.push(vm.kdnUserid);
            list.push(vm.kdnAppkey);
            list.push(vm.downloadWarning);
            list.push(vm.projectExamineSwitch);
            list.push(vm.autoBoxing);
            list.push(vm.projectFileUrlPrefix);
            list.push(vm.prePressFlowUrl);
            list.push(vm.defaultProductImg);

            System.update(list, function onSaveSuccess () {
                vm.isSaving = false;
                MessageService.success("保存成功");
            }, function onSaveError () {
                vm.isSaving = false;
                MessageService.error("保存失败，请稍后重试！");
            });
        };
    }
})();
