(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('Allocations', Allocations);

    Allocations.$inject = ['$resource'];

    function Allocations ($resource) {
        var resourceUrl =  'api/allocations/:path/:id';

        return $resource(resourceUrl, {}, {
            'create': {
                method: 'POST',
                isArray: false
            },
            'update': {
                method:'PUT',
                isArray: false
            },
            'delete': {
                method:'DELETE'
            } ,
            'page': {
                method:'GET',
                isArray: true
            },
            'detail': {
                method:'GET'
            },
            'getAll': {
                method:'GET',
                isArray: true,
                params: {
                    path: 'getAll'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }

        });
    }

})();
