(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('TransactionDetailController', TransactionDetailController);

    TransactionDetailController.$inject = ['$scope', 'Principal', 'LoginService','$state','Supplier','$stateParams','msgdata','$uibModal','$uibModalInstance','ParseLinks','MessageService', '$localStorage', '$http'];

    function TransactionDetailController ($scope, Principal, LoginService,$state,Supplier,$stateParams,msgdata,$uibModal,$uibModalInstance,ParseLinks,MessageService, $localStorage, $http) {
        var vm = this;
        vm.clear = clear;
        vm.supply= msgdata;
        vm.token = $localStorage.authenticationToken;

        vm.loadAll = loadAll;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            supId:vm.supply.id,
            sort: ['createdDate,DESC'],
            startDate:GetDateStr(-31),
            endDate:GetDateStr(0),
            type:''
        };
        // angular.element("#startDate").val(GetDateStr(-31));
        // angular.element("#endDate").val(GetDateStr(0));

        //日期
        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }
        vm.types =[
            {name:'plus', value:'充值'},
            {name:'minus', value:'消费'},
            {name:'refund', value:'退款'}];


        loadAll();
        function loadAll() {
            vm.records = [];
            if(angular.element("#startDate").val()){
                vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            }
            if(angular.element("#endDate").val()){
                vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            }
            Supplier.getTransactionRecord(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                vm.records = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            loadAll();
                        }
                    }
                });
            }, function (error) {

            });
        }

        $scope.search =function(){
            vm.tableData.page = 0;
            loadAll();
        };

        function clear(){
            vm.tableData.type = '';
            vm.tableData.page = 0;
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            $uibModalInstance.close();
        }
        //下载
        $scope.export = function () {
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            // if(vm.tableData.startDate == ""){
            //     MessageService.error("开始时间不能为空");
            //     return
            // }
            // if(vm.tableData.endDate == ""){
            //     MessageService.error("结束时间不能为空");
            //     return
            // }
            $http({
                url: 'api/suppliers/downloadTransactionRecord',
                method: "GET",
                params: vm.tableData,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = '交易明细' + Date.now() + ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        };


    }
})();
