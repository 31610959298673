(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ClassifyController', ClassifyController);

    ClassifyController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService','ProductCategory', 'ParseLinks', '$uibModal'];

    function ClassifyController ($scope, Principal, LoginService, $state,User,MessageService, ProductCategory, ParseLinks, $uibModal) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_CLASSIFY').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有分类管理访问的权限！");
                $state.go("home");
            }
        });
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tableData = {
            page: 0,
            sort: 'lastModifiedDate,DESC',
            size: 20,
            keyword: ''
        };
        vm.typeList = [
            {name:'内部', value:'内部'},
            {name:'供应商', value: '供应商'}
        ];
        init()
        function init() {
            vm.tableList = [];
            ProductCategory.query(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        //删除
        $scope.roleDelete = function (data) {
            MessageService.confirm("确认要删除该分类？", function () {
                ProductCategory.delete({id: data.id}, function () {
                    init();
                    MessageService.success("角色删除成功");
                }, function (error) {
                    MessageService.error("删除失败，请重试！");
                });
            });
        };
        //新增分类
        $scope.classifyAdd = function (){
            $uibModal.open({
                templateUrl: 'app/product/classify-add.html',
                controller: 'ClassifyAddController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        var obj = {
                            state: true
                        }
                        return obj;
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    init()
                }
            });
        };
        //编辑分类
        $scope.classifyEdit = function (data) {
            $uibModal.open({
                templateUrl: 'app/product/classify-add.html',
                controller: 'ClassifyAddController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        var obj = {
                            data: data,
                            state: false
                        }
                        return obj;
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    init()
                }
            });
        }
    }
})();
