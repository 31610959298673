(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PriceListSkuController', PriceListSkuController);

    PriceListSkuController.$inject = ['$scope','$uibModalInstance','MessageService', 'msgdata','$uibModal','Product','$q'];

    function PriceListSkuController ($scope,$uibModalInstance,MessageService,msgdata,$uibModal,Product,$q) {
        var vm = this;
        vm.save = save;
        vm.skuManages = [];
        vm.delSku = [];
        vm.addSku = [];
        console.log(msgdata)
        vm.product = Product.get({id: msgdata.productId});

        vm.priceTypes=[
            {name:'单价',value:'UNITPRICE'},
            {name:'一口价',value:'PRICE'}
        ];

        vm.pricingStrategy =[
            {name:'标准定价',value:'STANDARD'},
            {name:'阶梯定价',value:'LADDER'},
            // {name:'梯度定价',value:'GRADIENT'}
        ];

        vm.choices =[
            {name:'订单数',value:'NUMBER'},
            {name:'张数',value:'PAPER'}
        ];

        $scope.search = function(){
            angular.forEach(  vm.skuManages , function(sku){
                sku.hide = false
                if (vm.name != null && vm.name != "") {
                    if (sku.propertyConfig.sku.indexOf(vm.name)<0) {
                        sku.hide = true;
                    }
                }
            })
        }

        $scope.cancelSearch = function(){
            vm.name = null;
            angular.forEach(  vm.skuManages , function(sku){
                sku.hide = false
            })
        }

        $q.all([vm.product.$promise]).then(function(){
            vm.propertyList = vm.product.propertyConfigs;
            vm.addSku = angular.copy(vm.propertyList);
            if(msgdata.priceRules&&msgdata.priceRules.length){
                vm.skuManages = angular.copy(msgdata.priceRules);

                for (var x = vm.skuManages.length - 1; x >= 0; x--) {
                    for (var y = vm.propertyList.length - 1; y >= 0; y--) {
                        if (vm.propertyList[y].id == vm.skuManages[x].propertyConfigId) {
                            vm.skuManages[x].propertyConfig = vm.propertyList[y];
                            vm.propertyList[y].isAdd = true;
                        }
                    }
                    if(vm.skuManages[x].delete||vm.skuManages[x].cancelled){
                        vm.delSku.push(vm.skuManages[x])
                    }
                }

                for (var y = vm.propertyList.length - 1; y >= 0; y--) {
                    if(vm.propertyList[y].isAdd){
                        vm.addSku.splice(y, 1);
                    }
                }
                if (vm.addSku.length) {
                    angular.forEach(vm.addSku, function (sku) {
                        var sku2 = {
                            propertyConfigId: sku.id,
                            propertyConfig:sku,
                            delete:true,
                            cancelled:true,
                            type:'STANDARD',
                            baseOn:'NUMBER',
                            ladderSetups:[],
                            priceNum:null,
                            vipPrice:null,
                            piecePrice:null,
                            vipPiecePrice:null,
                            propertyConfigSku:null,
                        }
                        vm.skuManages.push(sku2);
                        vm.delSku.push(sku2);
                    })
                }
                console.log(vm.addSku)
                console.log(vm.delSku)
            }else{
                // angular.forEach(vm.propertyList,function (p) {
                //     var skuManage = {
                //         propertyConfigId: p.id,
                //         propertyConfig:p,
                //         type:'STANDARD',
                //         baseOn:'NUMBER',
                //         ladderSetups:[],
                //         priceNum:null,
                //         vipPrice:null,
                //         propertyConfigSku:null,
                //         piecePrice:null,
                //         vipPiecePrice:null,
                //     }
                //     vm.skuManages.push(skuManage);
                // })
            }
            angular.forEach(  vm.skuManages , function(sku){
                sku.hide = false
            })
            console.log(vm.skuManages)

        })

        $scope.changePropertyConfig = function(data){
            // data.propertyConfigId = data.propertyConfig.id;
            // data.propertyConfigSku = data.propertyConfig.sku;
        };



        //梯度/阶梯弹窗
        $scope.setLadderSetups = function (data) {
            $uibModal.open({
                templateUrl: 'app/setting/priceList/set-price.html',
                controller: 'SetPriceController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return {
                            addPage:msgdata.addPage,
                            data:data
                        }
                    }
                }
            }).result.then(function(result){
                data.ladderSetups = result;
            });
        }

        //标准定价弹窗
        $scope.setStandardPrice = function(data){
            $uibModal.open({
                templateUrl: 'app/setting/priceList/set-standard-price.html',
                controller: 'SetStandardPriceController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'mg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return {
                            addPage:msgdata.addPage,
                            data:data
                        }
                    }
                }
            }).result.then(function(result){
                data.priceNum = result.priceNum;
                data.piecePrice = result.piecePrice;
                data.vipPrice = result.vipPrice;
                data.vipPiecePrice = result.vipPiecePrice;
            });
        }

        //定价策略修改后
        $scope.changeType = function(data){
            data.ladderSetups = [];
            data.priceNum = null;
            data.piecePrice = null;
            data.vipPrice = null;
            data.vipPiecePrice = null;
        }


        //复制
        $scope.skuCopy = function(data){
            $uibModal.open({
                templateUrl: 'app/customerHub/customerHub-copy.html',
                controller: 'CustomerHubCopyController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return {
                            skuManages:vm.skuManages,
                            data:data,
                            tag:'priceListSku'
                        }
                    }
                }
            }).result.then(function(result){
                angular.forEach(result.skuManages,function(item,i){
                    if(item.selected){
                        // vm.skuManages[i].prePressFlow = angular.copy(item.prePressFlow);
                        vm.skuManages[i].type = angular.copy(item.type);
                        vm.skuManages[i].baseOn = angular.copy(item.baseOn);
                        vm.skuManages[i].ladderSetups = angular.copy(item.ladderSetups);
                        vm.skuManages[i].priceNum = angular.copy(item.priceNum);
                        vm.skuManages[i].piecePrice = angular.copy(item.piecePrice);
                        vm.skuManages[i].vipPrice = angular.copy(item.vipPrice);
                        vm.skuManages[i].vipPiecePrice = angular.copy(item.vipPiecePrice);
                    }
                });
                console.log(vm.skuManages);
            });
        };

        //删除sku
        $scope.skuDelete = function(index){
            vm.delSku.push(vm.skuManages[index]);
            // vm.skuManages.splice(index,1);
            vm.skuManages[index].delete = true;
            vm.skuManages[index].cancelled = true;
        };

        // 添加sku
        $scope.addSkuManage = function () {
            for(var i = 0;i<vm.skuManages.length;i++){
                var sku = vm.skuManages[i];
                if(sku.propertyConfigId==vm.delSku[vm.delSku.length-1].propertyConfigId){
                    sku.delete = false;
                    sku.cancelled = false;
                    sku.type = 'STANDARD';
                    sku.baseOn = 'NUMBER';
                    sku.ladderSetups = [];
                    sku.priceNum = null;
                    sku.vipPrice = null;
                    sku.piecePrice = null;
                    sku.vipPiecePrice = null;
                    vm.delSku.splice(vm.delSku.length-1,1);
                    break
                }
            }
        }

        $scope.cancel =function(){
            $uibModalInstance.dismiss();
        };

        function save () {
            console.log(vm.skuManages);
            // return;
            var ccFlag = true;
            if (vm.skuManages != null && vm.skuManages.length > 0) {
                angular.forEach(vm.skuManages, function (item) {
                    if (!item.propertyConfigId) {
                        MessageService.error("销售商品不能为空！");
                        ccFlag = false;
                        return;
                    }
                    if (!item.type) {
                        MessageService.error("定价策略不能为空");
                        ccFlag = false;
                        return;
                    }
                    if (!item.baseOn) {
                        MessageService.error("基于不能为空");
                        ccFlag = false;
                        return;
                    }
                    // {name:'标准定价',value:'STANDARD'},
                    // {name:'阶梯定价',value:'LADDER'},
                    // {name:'梯度定价',value:'GRADIENT'}
                    if (item.type!='STANDARD'&&!item.ladderSetups.length) {
                        MessageService.error("阶梯设置不能为空");
                        ccFlag = false;
                        return;
                    }
                });
            }else{
                MessageService.error("价格梯度设置不能为空");
                return;
            }

            if(ccFlag){
                $uibModalInstance.close(vm.skuManages);
            }

        }
    }
})();
