(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderExtemalDetailsController', OrderExtemalDetailsController);

    OrderExtemalDetailsController.$inject = ['$scope', 'Principal', 'entity', 'LoginService', '$state','User','MessageService', 'ParseLinks', '$localStorage','$http', '$stateParams', '$rootScope'];

    function OrderExtemalDetailsController ($scope, Principal, entity, LoginService, $state,User,MessageService, ParseLinks, $localStorage, $http, $stateParams, $rootScope) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_SELF_SUPPORT_ORDER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });
        vm.token = $localStorage.authenticationToken;
        vm.salesOrder = entity;
        //打印
        // $scope.printpage = function () {
        //     var iframe = document.getElementById('print-frame');
        //     if (iframe === null || iframe === undefined) {
        //         iframe = document.createElement('iframe');
        //         iframe.lang = 'en';
        //         iframe.style.display = 'none';
        //         iframe.id = 'print-frame';
        //         document.body.insertBefore(iframe,document.body.children[0]);
        //         iframe.contentDocument.head.innerHTML = "<meta charset='UTF-8'><title>主板文件</title><link rel='stylesheet' href='bower_components/bootstrap/dist/css/bootstrap.css'><link rel='stylesheet' href='content/css/main.css'>";
        //     }
        //
        //     setTimeout(function () {
        //         iframe.contentDocument.body.innerHTML = document.getElementById("printPage").innerHTML;
        //         iframe.contentDocument.getElementById("printHide").style.display="none";
        //         iframe.contentDocument.getElementById("printHide1").style.display="none";
        //         iframe.contentWindow.focus();
        //         iframe.contentWindow.print();
        //     },300);
        // };


        $scope.printpageBtn = function(){

            $http({
                url: 'api/sales_order/downloadJustifyFiles',
                method: "GET",
                params: {id: vm.salesOrder.id},
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var fileName = vm.salesOrder.number + (!vm.salesOrder.sku ? "" :"_" + vm.salesOrder.sku) + (!vm.salesOrder.propertySku ? "" : "_" + vm.salesOrder.propertySku);
                var blob = new Blob([data], {type: "application/zip"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                a.setAttribute('download', fileName);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (error, status) {
                if(status == 400){
                    MessageService.error("组版文件不存在");
                }
            });
        };
    //    返回   保留筛选信息
        $scope.goBack = function(){
            if($rootScope.previousState){
                $state.go($rootScope.previousState.name,{
                    page: $stateParams.page,
                    state: $stateParams.state,
                    source: $stateParams.source,
                    startDate: $stateParams.startDate,
                    endDate: $stateParams.endDate,
                    back: $stateParams.back,
                    timeWarning: $stateParams.timeWarning,
                    replenishment: $stateParams.replenishment,
                    refund: $stateParams.refund,
                    supplierId: $stateParams.supplierId,
                    productId: $stateParams.productId,
                    keyword: $stateParams.keyword,
                    promoteProduct: $stateParams.promoteProduct
                });
            }else {
                history.back();
            }
        };
    }
})();
