(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderModificationController', OrderModificationController);

    OrderModificationController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'ParseLinks', 'SalesOrder','$uibModalInstance', 'entity', 'Express'];

    function OrderModificationController ($scope, Principal, LoginService, $state,User,MessageService, ParseLinks, SalesOrder, $uibModalInstance, entity, Express) {
        var vm = this;
        vm.order = entity;
        vm.status_info = vm.order[0].status;
        vm.data={};
        vm.expressCompanies = [];

        if(vm.status_info == 'Assigned'){
            vm.statusList=[
                {name:'生产中', id:'Producing'},
                {name:'生产完成', id:'Finished'},
                {name:'已发货', id:'Delivered'},
                {name:'已揽件', id:'Receiving'},
                {name:'已签收', id:'Received'}
            ]
        }
        if(vm.status_info == 'Producing'){
            vm.statusList=[
                {name:'生产完成', id:'Finished'},
                {name:'已发货', id:'Delivered'},
                {name:'已揽件', id:'Receiving'},
                {name:'已签收', id:'Received'}
            ]
        }
        if(vm.status_info == 'Finished'){
            vm.statusList=[
                {name:'已发货', id:'Delivered'},
                {name:'已揽件', id:'Receiving'},
                {name:'已签收', id:'Received'}
            ]
        }
        if(vm.status_info == 'Delivered'){
            vm.statusList=[
                {name:'已揽件', id:'Receiving'},
                {name:'已签收', id:'Received'}
            ]
        }



        $scope.modalHide = function(){
            $uibModalInstance.close(false);
        };

        $scope.statusChange = function(){
            vm.data.downLoadDate = "";
            Express.getAll(function(res){
                res.forEach(function (item) {
                    if(item.enable && vm.order[0].supplierId == item.supplierId){
                        vm.expressCompanies.push(item)
                    }
                })
            });
        }

        //保存
        $scope.save = function () {
            vm.data.downLoadDate = angular.copy(angular.element("#downLoadDate").val());
            vm.data.deliveryDate = angular.copy(angular.element("#deliveryDate").val());
            vm.data.finishDate = angular.copy(angular.element("#finishDate").val());
            if(!vm.data.status){
                MessageService.error("状态不能为空");
                return;
            }
            if(vm.data.status == 'Producing' && !vm.data.downLoadDate){
                MessageService.error("下载时间不能为空");
                return;
            }
            if(vm.data.status == 'Finished' && !vm.data.finishDate){
                MessageService.error("完工时间不能为空");
                return;
            }
            if(vm.data.status == 'Delivered' && !vm.data.deliveryComp){
                MessageService.error("快递公司不能为空");
                return;
            }
            if(vm.data.status == 'Delivered' && !vm.data.deliveryDate){
                MessageService.error("发货日期不能为空");
                return;
            }
            if(vm.data.status == 'Delivered' && !vm.data.logisticsNumber){
                MessageService.error("快递单号不能为空");
                return;
            }
            var _data=[];
            vm.order.forEach(function (item) {
                _data.push({
                    status: vm.data.status,
                    downLoadDate: vm.data.downLoadDate,
                    finishDate: vm.data.finishDate,
                    deliveryComp: vm.data.deliveryComp,
                    logisticsNumber: vm.data.logisticsNumber,
                    deliveryDate: vm.data.deliveryDate,
                    orderId: item.id,
                })
            })
            SalesOrder.updateOrderBatch(_data, function (res) {
                MessageService.success("修改成功");
                $uibModalInstance.close(true);
            }, function (error) {
                MessageService.error("修改失败");
            });
        }
    }
})();
