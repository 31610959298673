(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('shelfManagement', {
            parent: 'module',
            url: '/shelfManagement',
            params:{page: null},
            data: {
                authorities: ['ROLE_GOODS_SHELVES']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/shelfManagement/shelfManagement.html',
                    controller: 'ShelfManagementController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })
        .state('shelfManagementAdd', {
            parent: 'module',
            url: '/shelfManagementAdd',
            data: {
                authorities: ['ROLE_GOODS_SHELVES']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/shelfManagement/shelfManagement-add.html',
                    controller: 'ShelfManagementAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })
        .state('shelfManagementAdds', {
            parent: 'module',
            url: '/shelfManagementAdds',
            data: {
                authorities: ['ROLE_GOODS_SHELVES']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/shelfManagement/shelfManagement-adds.html',
                    controller: 'ShelfManagementAddsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })
        .state('shelfManagementEdit', {
            parent: 'module',
            url: '/shelfManagementEdit/{id}',
            params:{page: null},
            data: {
                authorities: ['ROLE_GOODS_SHELVES']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/shelfManagement/shelfManagement-edit.html',
                    controller: 'ShelfManagementEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'GoodsShelves', function ($stateParams, GoodsShelves) {
                    return GoodsShelves.detail({id: $stateParams.id});
                }]
            }
        });
    }
})();
