(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('WarehousingController', WarehousingController);

    WarehousingController.$inject = ['$scope','$state', 'msgdata', '$uibModal', '$uibModalInstance', 'MessageService', 'PurchaseProductLine'];

    function WarehousingController ($scope, $state, msgdata, $uibModal, $uibModalInstance, MessageService, PurchaseProductLine) {
        var vm = this;

        init();
        function init() {
            vm.purchaseOrderList = msgdata[0];
            console.log(vm.purchaseOrderList);
        }

        $scope.saveNum = function(){
            if(vm.warehousingNum > 0){
                if (vm.purchaseOrderList.num < (vm.purchaseOrderList.warehousingNum + vm.warehousingNum)) {
                    MessageService.error('入库数量大于订购数量');
                    return;
                }
                PurchaseProductLine.changeWarehousingNum({
                    id: vm.purchaseOrderList.id,
                    num: vm.warehousingNum
                },function(res){
                    MessageService.success('入库成功！');
                    $uibModalInstance.close();
                },function(error){
                    MessageService.error('入库失败');
                });
            }else{
                MessageService.error('请输入入库数量');
            }
        };

        $scope.cancelTask = function(){
            $uibModalInstance.close();
        };

    }
})();
