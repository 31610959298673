(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('ReplenishmentReason', ReplenishmentReason);

    ReplenishmentReason.$inject = ['$resource'];

    function ReplenishmentReason ($resource) {
        var resourceUrl =  'api/replenishment-reasons/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                isArray: true,
                params: {
                    id: 'getAll'
                }
            } ,
            'delete':{method:'DELETE'},
            'add': { method:'POST' } ,
            'update': { method:'PUT' },
            'detail': {method:'GET'}
        });
    }
})();
