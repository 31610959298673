(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('DeliverOrders', DeliverOrders);

    DeliverOrders.$inject = ['$resource'];

    function DeliverOrders ($resource) {
        var resourceUrl =  'api/deliver-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            'page': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'page'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getData': {
                method:'GET',
                isArray: true,
                params: {
                    path: 'getData'
                }
            },
            'getData2': {
                method:'GET',
                isArray: true,
                params: {
                    path: 'getData2'
                }
            }
        });
    }
})();
