(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ToExamineController', ToExamineController);

    ToExamineController.$inject = ['$scope','$state', 'msgdata', '$uibModal', '$uibModalInstance', 'MessageService', 'PurchaseProductLine'];

    function ToExamineController ($scope, $state, msgdata, $uibModal, $uibModalInstance, MessageService, PurchaseProductLine) {
        var vm = this;

        init();
        function init() {
            vm.purchaseOrderList = msgdata;
            console.log(vm.purchaseOrderList);
        }

        $scope.confirmBtn = function() {
            console.log(vm.purchaseOrderList)
            var ids = '';
            for (var i = 0; i < vm.purchaseOrderList.length; i++) {
                if (vm.purchaseOrderList[i].state == 'ToBeAudited'||vm.purchaseOrderList[i].state == 'ToBeRevised') {
                    ids += vm.purchaseOrderList[i].id + ",";
                }
            }
            ids = ids.substr(0, ids.length - 1);
            PurchaseProductLine.changeState({ids: ids, state: 'AlreadyIssued'}, function (data) {
                if (data.message == '300') {
                    MessageService.error('采购订单审核失败，因供料不足！');
                } else if(data.message == '404'){
                    MessageService.error('采购订单审核失败，因数据有误！');
                } else if (data.message == '200') {
                    MessageService.success('采购订单已下达！');
                }
                $state.go("purchaseProductLine");
                $uibModalInstance.close();
            }, function (error) {
                MessageService.error('采购订单审核失败！');
            });
        }

        $scope.refuse = function() {
            var ids = '';
            for (var i = 0; i < vm.purchaseOrderList.length; i++) {
                if (vm.purchaseOrderList[i].state == 'ToBeAudited'||vm.purchaseOrderList[i].state == 'ToBeRevised') {
                    ids += vm.purchaseOrderList[i].id + ",";
                }
            }
            ids = ids.substr(0, ids.length - 1);
            PurchaseProductLine.changeState({ids: ids, state: 'ToBeRevised'}, function (data) {
                MessageService.success('采购订单已下达！');
                $uibModalInstance.close();
            }, function (error) {
                MessageService.error('采购订单审核失败！');
            });

        }
        $scope.cancelTask = function(){
            $uibModalInstance.close();
        };

    }
})();
