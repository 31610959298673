(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SkuPricePageController', SkuPricePageController);

    SkuPricePageController.$inject = ['$scope', 'msgdata', '$uibModalInstance', 'MessageService', 'Product', '$q'];

    function SkuPricePageController ($scope, msgdata, $uibModalInstance, MessageService, Product, $q) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;

        vm.propertyPriceList = [];
        vm.product = Product.get({id: msgdata.productId});
        $q.all([vm.product.$promise]).then(function(){

            // console.log(vm.product);

            if (msgdata.propertyPriceList == null || msgdata.propertyPriceList.length == 0) {
                console.log(vm.product.propertyConfigs);
                for (var i = vm.product.propertyConfigs.length - 1; i >= 0; i--) {
                    var propertyPrice = {
                        propertyConfigId: vm.product.propertyConfigs[i].id, 
                        propertyConfigSku: vm.product.propertyConfigs[i].sku, 
                        price: 0.00,
                        productionManageId: msgdata.id};

                    vm.propertyPriceList.push(propertyPrice);
                }
            } else {
                vm.propertyPriceList = msgdata.propertyPriceList;
            }
        });

        function clear(){
            $uibModalInstance.dismiss();
        }

        function save() {
            vm.isSaving = true;

            $uibModalInstance.close(vm.propertyPriceList);
        }
    }
})();
