(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('machine', {
                parent: 'module',
                url: '/machine',
                params:{page: null},
                data: {
                    authorities: ['ROLE_MACHINE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/machineTypes/machine.html',
                        controller: 'MachineController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('machineAdd', {
                parent: 'module',
                url: '/machineAdd',
                data: {
                    authorities: ['ROLE_MACHINE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/machineTypes/machine-add.html',
                        controller: 'MachineAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('machineEdit', {
                parent: 'module',
                url: '/machineEdit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_MACHINE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/machineTypes/machine-edit.html',
                        controller: 'MachineEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Machine', function($stateParams, Machine) {
                        return Machine.detail({id : $stateParams.id});
                    }]
                }
            });
    }
})();
