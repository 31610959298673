(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('PrePressFlow', PrePressFlow);

    PrePressFlow.$inject = ['$resource'];

    function PrePressFlow ($resource) {
        var resourceUrl =  'api/pre-press-flows/:path/:id';

        return $resource(resourceUrl, {}, {
            'page': { 
                method: 'GET', 
                isArray: true,
                params: {
                    path: 'page'
                },
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                isArray: true,
                params: {
                    path: 'getAll'
                }
            } ,
            'delete':{
                method:'DELETE',
            },
            'add': { method:'POST' } ,
            'update': { method:'PUT' },
        });
    }

})();
