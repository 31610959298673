(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PriceListEditController', PriceListEditController);

    PriceListEditController.$inject = ['$scope','$state','MessageService', 'entity','$uibModal','Channels','Product','Prices','Supplier','ProductCategory','CustomerHub','$q'];

    function PriceListEditController ($scope, $state,MessageService,entity,$uibModal,Channels,Product,Prices,Supplier,ProductCategory,CustomerHub,$q) {
        var vm = this;

        vm.priceTypes =[
            {name:'供应商价目表',value:'supplier'},
            {name:'客户价目表',value:'customer'},
            {name:'渠道价目表',value:'channel'}
        ];

        vm.price = entity;
        // 获取商品
        vm.allProducts = Product.getAll();
        // 获取渠道
        vm.allChannels = Channels.getAll();
        // 供应商
        vm.allSuppliers = Supplier.getAll();
        // 客户
        vm.allCustomerHubs = CustomerHub.getAll();
        // 一级分类
        vm.productCategoryFirst = ProductCategory.byCondition({items: [{key: 'subordinate', op: '=', value: false}, {key: 'enable', op: '=', value: true}]})
        vm.productCategorySecond = [];
        vm.products = [];
        $scope.changeSearchCategoryFirst = function(){
            vm.productCategorySecond = [];
            vm.products = [];
            if (vm.productCategoryFirstId == null) {
                return;
            }
            for(var i = 0 ; i< vm.productCategoryFirst.length; i++){
                var c =  vm.productCategoryFirst[i];
                if (c.id == vm.productCategoryFirstId) {
                    vm.productCategorySecond = c.productCategorySecond;
                    return;
                }
            }
        }

        $scope.changeSearchCategorySecond = function(){
            vm.products = [];
            if (vm.productCategorySecondId == null) {
                return;
            }
            for(var i = 0 ; i< vm.productCategorySecond.length; i++){
                var c =  vm.productCategorySecond[i];
                if (c.id == vm.productCategorySecondId) {
                    vm.products = c.products;
                    return;
                }
            }
        }


        $scope.search = function(){
            angular.forEach(vm.price.priceLines , function(line){
                line.hide = false;
                if (vm.productCategoryFirstId != null && line.productCategoryFirstId != vm.productCategoryFirstId) {
                    line.hide = true;
                }
                if (vm.productCategorySecondId != null && line.productCategorySecondId != vm.productCategorySecondId) {
                    line.hide = true;
                }
                if (vm.productName != null && line.productName.indexOf(vm.productName) <0) {
                    line.hide = true;
                }
            });
        };

        $scope.cancelSearch = function(){
            vm.productCategoryFirstId = null;
            vm.productCategorySecondId = null;
            vm.productId = null;
            vm.productName = null;
            angular.forEach(vm.price.priceLines , function(line){
                line.hide = false;
            });
        }

        $q.all([vm.price.$promise,vm.allProducts.$promise,vm.allChannels.$promise,vm.allSuppliers.$promise,vm.allCustomerHubs.$promise,vm.productCategoryFirst.$promise]).then(function(){
            vm.products = vm.allProducts.filter(function(item){return item.enable;});
            vm.channels = vm.allChannels.filter(function(item){return item.enable;});
            vm.suppliers = vm.allSuppliers.filter(function(item){return item.enable;});
            vm.customerHubs = vm.allCustomerHubs.filter(function(item){return item.enable;});

            angular.forEach(vm.productCategoryFirst, function(c){
                //获取二级分类
                ProductCategory.byCondition({items: 
                    [{key: 'subordinate', op: '=', value: true},
                    {key: 'enable', op: '=', value: true},{key: 'productCategory.id', op: '=', value: c.id}]
                }, function (res) {
                    c.productCategorySecond = res;
                    if(res.length>0){
                        for (var i = vm.price.priceLines.length - 1; i >= 0; i--) {
                            var line = vm.price.priceLines[i];
                            if (line.productCategoryFirstId == c.id ) {
                                line.productCategorySecond = res;
                            }
                        }
                        angular.forEach(c.productCategorySecond, function(sc){
                            //获取商品
                            Product.byProductCategoryId({productCategoryId:sc.id},function (data) {
                                sc.products = data.filter(function (item) {
                                    return item.enable;
                                });
                                for (var i = vm.price.priceLines.length - 1; i >= 0; i--) {
                                    var line = vm.price.priceLines[i];
                                    if (line.productCategorySecondId == sc.id ) {
                                        line.products = data.filter(function (item) {
                                            return item.enable;
                                        });
                                    }
                                }
                            });
                        });
                    }
                });
            });
            
            // angular.forEach(vm.price.priceLines,function(priceLine){
            //     priceLine.products = []
            //     angular.forEach(vm.products,function(item){
            //         if(item.id ==priceLine.productId){
            //             priceLine.product = item;
            //         }

            //         if(priceLine.productCategorySecondId==null&&item.productCategoryId == priceLine.productCategoryFirstId){
            //             priceLine.products.push(item);
            //         }else if(priceLine.productCategorySecondId!=null&&item.productCategoryId == priceLine.productCategorySecondId){
            //             priceLine.products.push(item);
            //         }
            //     })

            //     //获取二级分类
            //     ProductCategory.byCondition({
            //         items: [{key: 'subordinate', op: '=', value: true},
            //             {key: 'enable', op: '=', value: true},
            //             {key: 'productCategory.id', op: '=', value: priceLine.productCategoryFirstId}]
            //     }, function (res) {
            //         priceLine.productCategorySecond = res;
            //     });
            // })
            console.log(vm.price.priceLines)
        })


        $scope.choosePriceType = function () {
            vm.price.supplierId = null;
            vm.price.customerHubId = null;
            vm.price.channelId = null;
        }

        //新增规则
        var priceLine ={
            addPage:false,
            productCategoryFirstId:null,//一级分类
            // productCategoryFirstName:null,
            productCategorySecondId:null,//二级分类
            // productCategorySecondName:null,
            productId:null,
            // productName:null,
            // priceId:null,
            priceRules:[]
        };

        $scope.priceLineNew = function(){
            var flag = false;
            if(vm.price.priceLines.length >0){
                angular.forEach(vm.price.priceLines,function(item){
                    if(!item.cancelled&&(!item.productCategoryFirstId || !item.productId)){
                        flag = true;
                    }
                });
            }
            if(flag){
                MessageService.error('请将空白项填写完整再添加！！！！');
            }else {
                vm.price.priceLines.push(angular.copy(priceLine));
            }
        }

        // 设置sku
        $scope.defaultSet = function(data){
            console.log(data)
            $uibModal.open({
                templateUrl: 'app/setting/priceList/priceList-sku.html',
                controller: 'PriceListSkuController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'mg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return data;
                    }
                }
            }).result.then(function(result){
                data.priceRules =result;
            });
        }

        $scope.changeCategoryFirst = function(priceLine){

            priceLine.productCategorySecondId = null;
            priceLine.products = [];
            priceLine.productCategorySecond = null;
            if (priceLine.productCategoryFirstId == null) {
                return;
            }
            angular.forEach(vm.productCategoryFirst, function(c){
                if (c.id == priceLine.productCategoryFirstId) {
                    priceLine.productCategorySecond = c.productCategorySecond;
                    return;
                }
            })
        }


        $scope.changeCategorySecond = function(priceLine,data){

            priceLine.productId = null;
            priceLine.products = [];
            if (priceLine.productCategorySecondId == null) {
                return;
            }
            angular.forEach(priceLine.productCategorySecond, function(c){
                if (c.id == priceLine.productCategorySecondId) {
                    priceLine.products = c.products;
                    return;
                }
            })
        }

        $scope.changeProduct = function (priceLine) {
            for(var i = priceLine.priceRules.length-1; i >= 0; i--){
                var rule = priceLine.priceRules[i];
                if(rule.id){
                    rule.cancelled = true;
                }else{
                    priceLine.priceRules.splice(i,1)
                }
            }

            angular.forEach(priceLine.products,function (pro) {
                if(priceLine.productId==pro.id){
                    if(pro.type=="General"){
                        priceLine.pageCount = null;
                        priceLine.addPage = false;

                    }else{
                        priceLine.pageCount = pro.pageCount;
                    }
                    priceLine.type = pro.type;
                    angular.forEach(pro.propertyConfigs,function (p) {
                        if(!p.cancelled){
                            var rule = {
                                propertyConfigId: p.id,
                                propertyConfig:p,
                                type:'STANDARD',
                                baseOn:'NUMBER',
                                ladderSetups:[],
                                priceNum:null,
                                vipPrice:null,
                                propertyConfigSku:null,
                                piecePrice:null,
                                vipPiecePrice:null,
                            }
                            priceLine.priceRules.push(rule);
                        }
                    })
                }
            })
        }


        $scope.defaultDelete =function(index){
            // vm.price.priceLines.splice(index,1);
            vm.price.priceLines[index].cancelled = true;
        };

        $scope.save = function () {
            if(!vm.price.name){
                MessageService.error("价目表名称不能为空");
                return;
            }
            if(vm.price.name.length >20){
                MessageService.error("价目表名称不能大于20位");
                return;
            }
            if(!vm.price.priceType){
                MessageService.error("请选择类型");
                return;
            }
            if(vm.price.priceType=='supplier'&&(vm.price.supplierId==null||vm.price.supplierId=='')){
                MessageService.error("请选择所属供应商");
                return;
            }
            if(vm.price.priceType=='customer'&&(vm.price.customerHubId==null||vm.price.customerHubId=='')){
                MessageService.error("请选择所属客户");
                return;
            }
            if(vm.price.priceType=='channel'&&(vm.price.channelId==null||vm.price.channelId=='')){
                MessageService.error("请选择所属渠道");
                return;
            }

            var ccFlag = true;
            if (vm.price.priceLines != null && vm.price.priceLines.length > 0) {
                angular.forEach(vm.price.priceLines, function (item,i) {
                    if(!item.cancelled){
                        if (!item.productCategoryFirstId) {
                            MessageService.error("一级分类不能为空！");
                            ccFlag = false;
                            return;
                        }
                        if (!item.productId) {
                            MessageService.error("请选择第"+(i-0+1)+"行商品");
                            ccFlag = false;
                            return;
                        }
                        if (vm.price.priceRules == null && vm.price.priceLines.length == 0) {
                            MessageService.error("商品SKU不能为空");
                            ccFlag = false;
                            return;
                        }
                    }
                });
            }else{
                MessageService.error("价目规则不能为空");
                return;
            }

            if(!ccFlag){
                return;
            }
            console.log(vm.price);
            // return
            vm.isSaving = true;
            Prices.update(vm.price, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("添加成功");
                $state.go("priceList");
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("添加失败，请稍后重试！");
            }
        };
    }
})();
