(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('replenishmentReason', {
            parent: 'module',
            url: '/replenishmentReason',
            params:{page: null},
            data: {
                authorities: ['ROLE_REPLENISH_REASON']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/replenishment/replenishmentReason.html',
                    controller: 'ReplenishmentReasonController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })
            .state('replenishmentReasonAdd', {
                parent: 'module',
                url: '/replenishmentReasonAdd',
                data: {
                    authorities: ['ROLE_REPLENISH_REASON']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/replenishment/replenishmentReason-add.html',
                        controller: 'ReplenishmentReasonAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('replenishmentReasonEdit', {
                parent: 'module',
                url: '/replenishmentReasonEdit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_REPLENISH_REASON']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/replenishment/replenishmentReason-edit.html',
                        controller: 'ReplenishmentReasonEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ReplenishmentReason', function($stateParams, ReplenishmentReason) {
                        return ReplenishmentReason.detail({id : $stateParams.id});
                    }],
                }
            });
    }
})();
