(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('Prices', Prices);

    Prices.$inject = ['$resource'];

    function Prices ($resource) {
        var resourceUrl =  'api/prices/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                isArray: true
            } ,
            'delete':{method:'DELETE'},
            'create': { method:'POST'} ,
            'update': { method:'PUT'},
            'detail': {method:'GET'}
        });
    }
})();
