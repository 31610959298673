(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CustomerHubNewController', CustomerHubNewController);

    CustomerHubNewController.$inject = ['$scope','entity','$state','User','$uibModal','MessageService', 'Supplier','$q', '$stateParams','Product','Machine','Makeups', 'SupplierAbutSetting', 'CustomerHub','ProofRule'];

    function CustomerHubNewController ($scope,entity,$state,User,$uibModal,MessageService,Supplier,$q, $stateParams,Product,Machine,Makeups,SupplierAbutSetting,CustomerHub,ProofRule) {
        var vm = this;
        vm.save= save;
        vm.customerHub = entity;
        vm.userGroups = [];
        vm.userDel = [];
        console.log(entity);
        vm.multimediaGroups =[
            {
                name:'outsource',
                value:'委外'
            },{
                name:'SourceMaterials',
                value:'原辅材料'
            }];
        vm.cashiers =[
            {name:'CashSettlement',value:'现结'},
            {name:'MonthlyKnots',value:'月结'}
        ];
        vm.payTypes =[
            {name:'InvoicePayment',value:'开票付款'},
            {name:'CashDelivery',value:'货到付款'}
        ];
        vm.settlementTypes =[
            {name:'DownLoad',value:'下载结算'},
            {name:'Delivered',value:'发货结算'}
        ];


        Product.getAll({},function(res){
            vm.products = res.filter(function(item){return item.enable});
        });

        $scope.productionDelete = function(index){
            vm.customerHub.customerProductManages.splice(index,1);
        };

        $scope.changeProduct = function(data){
            data.customerSkuManages = null
        };

        $scope.skuMakeupDetail = function(data){
            if(!data.productId){
                MessageService.error('请选择商品');
                return
            }
            $uibModal.open({
                templateUrl: 'app/customerHub/customerHub-sku.html',
                controller: 'CustomerHubSkuController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'mg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return data;
                    }
                }
            }).result.then(function(result){
                data.customerSkuManages = result;
            });
        };

        $q.all([vm.customerHub.$promise]).then(function(){
            if (vm.customerHub.balance) {
                vm.customerHub.balance = Number(vm.customerHub.balance.toFixed(2));
            }
            if(vm.customerHub.id!=''){
                $scope.titleContent = '编辑客户';
                vm.userGroups = User.getAll({scope:'client', customerHubId:vm.customerHub.id});

            }else{
                $scope.titleContent = '新建客户';
                vm.customerHub = {};
                vm.customerHub.locations =[];
                vm.customerHub.makeups =[];
                vm.customerHub.productionManages =[];
                vm.user =null;
                $('.special').attr('disabled',false);
                $('.special2').show();
            }
        });

        //添加商品管理
        $scope.addproduction = function(){
            var productionManage ={
                productId:'',
                productName:'',
                numberShelf:''
            };

            if(!vm.customerHub.customerProductManages){
                vm.customerHub.customerProductManages=[]
            }
            vm.customerHub.customerProductManages.push(angular.copy(productionManage));
        };

        $scope.editPhone = function(userInfo){
            $uibModal.open({
                templateUrl: 'app/customerHub/edit-phone.html',
                controller: 'EditPhoneController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return userInfo;
                    }
                }
            }).result.then(function(result){
                if(result){
                    userInfo.username = result.username;
                    userInfo.phone = result.phone;
                    console.log(userInfo);
                }
            });
        }

        //新建 or 编辑 用户名
        $scope.modifyContent = function(userInfo,index){
            if(userInfo){
                $uibModal.open({
                    templateUrl: 'app/customerHub/reset-password.html',
                    controller: 'ResetPasswordController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('supplier');
                            return $translate.refresh();
                        }],
                        entity: function () {
                            return userInfo;
                        }
                    }
                }).result.then(function(result){
                    if(result){
                        userInfo.username = result.username;
                        console.log(userInfo);
                    }
                });
            }else{
                $uibModal.open({
                    templateUrl: 'app/supply/create-user.html',
                    controller: 'CreateUserController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('supplier');
                            return $translate.refresh();
                        }],
                        msgdata: function () {
                            return userInfo;
                        },
                        entity: function () {
                            return 'customerHub';
                        }
                    }
                }).result.then(function(result){
                    if(result){
                        result.add = true;
                        result.edit = false;
                        result.username = result.name;
                        vm.userGroups.push(result);
                        console.log(vm.userGroups);
                    }
                });
            }


        };

        //充值
        $scope.recharge = function(){
            $uibModal.open({
                templateUrl: 'app/customerHub/recharge-amount.html',
                controller: 'CustomerHubAmountController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return vm.customerHub;
                    }
                }
            }).result.then(function(result){
                vm.customerHub.balance = vm.customerHub.balance - result.amount;
            });
        };

        //交易明细
        $scope.transactionDetail = function(){
            $uibModal.open({
                templateUrl: 'app/customerHub/transaction-detail.html',
                controller: 'CustomerHubTransactionDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return vm.customerHub;
                    }
                }
            }).result.then(function(result){
            });
        };



        //删除
        $scope.userDelete = function (index) {
            if(vm.userGroups[index].id){
                vm.userDel.push(vm.userGroups[index].id);
            }
            vm.userGroups.splice(index,1);
        };

        //添加地址
        $scope.addAddress = function(data){
            $uibModal.open({
                templateUrl: 'app/supply/address-new.html',
                controller: 'AddressNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return data;
                    }
                }
            }).result.then(function(result){
                console.log(result);
                vm.customerHub.locations.push(result);
            });
        };

        //编辑地址
        $scope.addressEdit = function(data,index){
            $uibModal.open({
                templateUrl: 'app/supply/address-new.html',
                controller: 'AddressNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return data;
                    }
                }
            }).result.then(function(result){
                console.log(result);
                vm.customerHub.locations[index] = result;
            });
        };

        //删除地址
        $scope.addressDelete = function(index){
            vm.supply.locations.splice(index,1);
        };

        $scope.validate = function () {
            if(vm.customerHub.name =='' || vm.customerHub.name == null){
                MessageService.error("客户简称不能为空");
                return;
            }
            if(vm.customerHub.name.length >20){
                MessageService.error("客户简称不能超过20位");
                return;
            }
            if(vm.customerHub.fillName =='' || vm.customerHub.fillName == null){
                MessageService.error("客户全称不能为空");
                return;
            }
            if(vm.customerHub.fillName.length >30){
                MessageService.error("客户全称不能超过30位");
                return;
            }

            if(vm.customerHub.priceAdaptRate =='' || vm.customerHub.priceAdaptRate == null){
                MessageService.error("价格调整比例不能为空");
                return;
            }

            if(vm.customerHub.priceAdaptRate < 0 && vm.customerHub.priceAdaptRate > 100 || vm.customerHub.priceAdaptRate == undefined){
                MessageService.error("价格调整比例只能为0-100的整数");
                return;
            }

            if(vm.customerHub.acceptLocation){
                if(vm.customerHub.storeNumber =='' || vm.customerHub.storeNumber == null){
                    MessageService.error("商户编号不能为空");
                    return;
                }
                if(vm.customerHub.apiUrl =='' || vm.customerHub.apiUrl == null){
                    MessageService.error("接口api不能为空");
                    return;
                }
            }

            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;

            var count = 0,count2 = 0, count3 = 0;

            if (vm.customerHub.customerProductManages!= null) {
                for(var i = 0;i<vm.customerHub.customerProductManages.length;i++){
                    var cpm = vm.customerHub.customerProductManages[i];

                    if(!cpm.cancelled&&!cpm.productId){
                        count3++;
                    }
                    if(cpm.customerSkuManages&&cpm.customerSkuManages.length){
                        for(var j = 0;j<cpm.customerSkuManages.length;j++){
                            var csm = cpm.customerSkuManages[j];
                            if(csm.delete){
                                csm.cancelled = true;
                            }
                        }
                    }
                }
            }
            console.log("count: "+count+" count2: "+count2)
            if(count3>0){
                vm.isSaving = false;
                MessageService.error("请选择商品");
                return
            }

            //筛选出重复的  比较productId
            if(vm.customerHub.customerProductManages&&vm.customerHub.customerProductManages.length){
                vm.arr = uniqeByKeys(vm.customerHub.customerProductManages, ['productId']);
                if(vm.arr.length!= vm.customerHub.customerProductManages.length){
                    vm.isSaving = false;
                    MessageService.error("已有该属性，请勿重复添加");
                    return
                }

            }

            if(vm.customerHub.id){
                CustomerHub.update(vm.customerHub,function (res) {
                    angular.forEach(vm.userGroups,function (item) {
                        item.customerHubId = res.id;
                    })
                    //更新用户
                    User.batchSave(vm.userGroups)
                    //删除用户
                    if(vm.userDel.length){
                        User.batchDelete({ids:vm.userDel})
                    }

                    MessageService.success("更新成功！");
                    $state.go('customerHub',{page: $stateParams.page});
                },function (error) {
                    vm.isSaving = false;
                    MessageService.error("更新失败！");
                });
            }else{
                CustomerHub.save(vm.customerHub,function (res) {
                    angular.forEach(vm.userGroups,function (item) {
                        item.customerHubId = res.id;
                    })
                    //更新用户
                    User.batchSave(vm.userGroups)
                    MessageService.success("客户新建成功！");
                    $state.go('customerHub',{page: $stateParams.page});
                },function (error) {
                    vm.isSaving = false;
                    MessageService.error("客户新建失败！");
                });
            }
        }
        //返回
        $scope.goBack = function () {
            $state.go("customerHub",{page: $stateParams.page});
        }

        function obj2key(obj, keys) {
            var n = keys.length,
                key = [];
            while (n--) {
                key.push(obj[keys[n]]);
            }
            return key.join('|');
        }

        function uniqeByKeys(array, keys) {
            var arr = [];
            var hash = {};
            for (var i = 0, j = array.length; i < j; i++) {
                if(!array[i].cancelled&&!array[i].deleted){
                    var k = obj2key(array[i], keys);
                    if (!(k in hash)) {
                        hash[k] = true;
                        arr.push(array[i]);
                    }
                }else{
                    arr.push(array[i]);
                }

            }
            return arr;
        }
    }
})();
