(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('DeliveryRequest', DeliveryRequest);

    DeliveryRequest.$inject = ['$resource'];

    function DeliveryRequest ($resource) {
        var resourceUrl =  'api/delivery-request/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'delivery': {
                method: 'GET',
                params: {
                    path: 'delivery'
                }
            },
            'query': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'page'
                }
            },
            'getAll': {
                method:'GET',
                isArray: true
            },
            'detail': {
                method:'GET'
            },
            'getData2': {
                method:'GET',
                params: {
                    path: 'getData2'
                },
                isArray: true
            },
            'getData': {
                method:'GET',
                params: {
                    path: 'getData'
                },
                isArray: true
            },

            'mergeRequestList': {
                method: 'GET',
                params: {
                    path: 'mergeRequestList'
                },
                isArray: true
            },
            'mergeDeliveryRequest': {
                method: 'GET',
                params: {
                    path: 'mergeDeliveryRequest'
                },
                isArray: true
            },
            'splitDeliveryRequest': {
                method: 'GET',
                params: {
                    path: 'splitDeliveryRequest'
                }
            },
            'bySaleOrder': {
                method: 'GET',
                params: {
                    path: 'bySaleOrder'
                },
                isArray: true
            },
            'bySaleOrderOld': {
                method: 'GET',
                params: {
                    path: 'bySaleOrder-old'
                },
                isArray: true
            },
            'getRealTimeExpress': {
                method: 'GET',
                params: {
                    path: 'getRealTimeExpress'
                }
            },
            'mergeRequestPage': {
                method: 'GET',
                params: {
                    path: 'mergeRequestPage'
                }
            },
            'rebuildDeliveryRequest': {
                method: 'POST',
                params: {
                    path: 'rebuildDeliveryRequest'
                }
            }
        });
    }
})();
