(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SetPriceController', SetPriceController);

    SetPriceController.$inject = ['$scope','$uibModalInstance','MessageService', 'msgdata','Makeups'];

    function SetPriceController ($scope,$uibModalInstance,MessageService,msgdata,Makeups) {
        var vm = this;
        vm.save = save;
        vm.addPage = msgdata.addPage;
        console.log(msgdata)
        vm.priceTypes=[
            {name:'单价',value:'UNITPRICE'},
            {name:'一口价',value:'PRICE'}
        ];

        if(msgdata.data.ladderSetups.length>0){
            vm.ladderSetups=msgdata.data.ladderSetups;
        }else{
            vm.ladderSetups=[];
        }

        var obj={
            quantityMin:'',
            quantityMax:'',
            priceType:'',
            price:'',
            vipPrice:""
        };

        //新增
        $scope.priceNew =function(){
            var flag = false;
            if(vm.ladderSetups.length >0){
                angular.forEach(vm.ladderSetups,function(item){
                    if(!item.quantityMin || !item.quantityMax || !item.priceType || !item.price){
                        flag = true;
                    }
                });
            }
            if(flag){
                MessageService.error('请将空白项填写完整再添加！！！！');
            }else {
                vm.ladderSetups.push(angular.copy(obj));
            }
        };


        $scope.cancel =function(){
            $uibModalInstance.dismiss();
        };

        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }

        $scope.validate = function(){
            if(vm.ladderSetups&&vm.ladderSetups.length){
                vm.ladderSetups.sort(compare('quantityMin'))
            }else{
                MessageService.error("请添加价格行");
                return false;
            }

            for (var i = vm.ladderSetups.length - 1; i >= 0; i--) {
                var cond =  vm.ladderSetups[i];
                if (!cond.quantityMin) {
                    MessageService.error("数量最小值不能为空");
                    return false;
                }
                if (!cond.quantityMax) {
                    MessageService.error("数量最大值不能为空");
                    return false;
                }
                if (cond.priceType == null||cond.priceType == '') {
                    MessageService.error("请选择价格类型")
                    return false;
                }

                if (cond.price == null||cond.price == '') {
                    MessageService.error("请录入价格")
                    return false;
                }
                if (cond.vipPrice == null||cond.vipPrice == '') {
                    MessageService.error("请录入会员价")
                    return false;
                }
                if(cond.quantityMax <= cond.quantityMin){
                    MessageService.error("请录入正确的阶梯数量")
                    return
                }
                if(vm.addPage&&(cond.piecePrice == '' || cond.piecePrice == null)){
                    MessageService.error("每加1P（标准价）不能为空！");
                    return;
                }
                if(vm.addPage&&(cond.vipPiecePrice == '' || cond.vipPiecePrice == null)){
                    MessageService.error("每加1P（标准价）不能为空！");
                    return;
                }

            }
            return true;
        }


        function save () {
            if (!$scope.validate()) {
                return;
            }
            for (var i = 0; i < vm.ladderSetups.length-1; i++) {
                var cond =  vm.ladderSetups[i];
                var cond2 =  vm.ladderSetups[i+1];
                if(cond.quantityMax <= cond.quantityMin){
                    MessageService.error("请录入正确的阶梯数量")
                    return
                }
                if(cond.quantityMax+1!=cond2.quantityMin){
                    MessageService.error("请录入正确的阶梯数量")
                    return
                }
            }
            $uibModalInstance.close(vm.ladderSetups);
        }

        $scope.defaultDelete =function(index){
            vm.ladderSetups.splice(index,1);
        };
    }
})();
