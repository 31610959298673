(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('UserEditController', UserEditController);

    UserEditController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'Role', 'entity', '$stateParams'];

    function UserEditController ($scope, Principal, LoginService, $state,User,MessageService,Role, entity, $stateParams) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_USER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有用户访问的权限！");
                $state.go("home");
            }
        });
        vm.user = entity;

        init();
        function init(){
            Role.query({type:"内部"},function(data){
                vm.role = data;
                for(var i=0;i<vm.role.length;i++){
                    if(vm.role[i].id==entity.roleId){
                        vm.user.role = vm.role[i].id;
                    }
                }
                vm.roleList = data;
                vm.role = [];
                if(vm.roleList.length>0){
                    for(var i=0;i<vm.roleList.length;i++){
                        if(vm.roleList[i].enabled){
                            vm.role.push(vm.roleList[i]);
                        }
                    }
                }
            });
        }

        // Role.query({},function(data){
        //     vm.roleList = data;
        //     vm.role = [];
        //     if(vm.roleList.length>0){
        //         for(var i=0;i<vm.roleList.length;i++){
        //             if(vm.roleList[i].enabled){
        //                 vm.role.push(vm.roleList[i]);
        //             }
        //         }
        //     }
        // });

        $scope.save = function () {
            if(!vm.user.username){
                MessageService.error("用户名不能为空");
                return;
            }
            if(!vm.user.role){
                MessageService.error("角色不能为空");
                return;
            }

            if (vm.user.phone == null || vm.user.phone == "") {
                MessageService.error("联系方式不能为空");
                return false;
            }
            // if(!(/^1[345678]\d{9}$/.test(vm.user.phone))){
            //     MessageService.error("请输入正确的手机号");
            //     return;
            // }
            vm.isSaving = true;
            for(var i=0;i<vm.role.length;i++){
                if(vm.role[i].id == vm.user.role){
                    vm.user.roleId = vm.role[i].id;
                    vm.user.roleName = vm.role[i].name;
                }
            }
            User.update(vm.user, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("修改成功");
                $state.go("user",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("修改失败，请重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("user",{page: $stateParams.page});
        }
    }
})();
