(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('inventory2', {
                parent: 'module',
                url: '/inventory2',
                params:{page: null},
                data: {
                    authorities: ['ROLE_MATERIAL_INVENTORY']
                },
                views: {
                    'content': {
                        templateUrl: 'app/accessories/inventory/inventory.html',
                        controller: 'InventoryTwoController',
                        controllerAs: 'vm'
                    }
                }
            })
    }
})();
