(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('RegulationsController', RegulationsController);

    RegulationsController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService','ProductCategory', 'ParseLinks', '$uibModal','$uibModalInstance', 'entity'];

    function RegulationsController ($scope, Principal, LoginService, $state,User,MessageService, ProductCategory, ParseLinks, $uibModal, $uibModalInstance, entity) {
        var vm = this;
        if(typeof entity == "object"){
            vm.data = entity.usingRule;
            if(vm.data.value.split(',').length > 1){
                vm.isOperator = true;
                vm.minValue = vm.data.value.split(',')[0];
                vm.maxValue = vm.data.value.split(',')[1];
            }else {
                vm.isOperator = false;
                vm.minValue = vm.data.value;
            }
        }else {
            vm.data = {
                    key: '',
                    op: '',
                    value: '',
                    quantity:''
                }
            vm.isOperator = false;
            vm.minValue = "";
            vm.maxValue = "";
        }

        //基于
        vm.baseOnList = [
            {name:'订单份数',id:'订单份数'}
        ]
        //运算符
        vm.operatorList = [
            {name:'=',id:'='},
            {name:'>=',id:'>='},
            {name:'<=',id:'<='},
            {name:'>',id:'>'},
            {name:'<',id:'<'},
            {name:'<>',id:'<>'},
            {name:'介于',id:'介于', operator: true}
        ];
        //选择运算符
        $scope.operatorSelect = function(){
            vm.isOperator = false;
            for(var i=0; i<vm.operatorList.length; i++){
                if(vm.operatorList[i].id == vm.data.op){
                    vm.isOperator = vm.operatorList[i].operator;
                }
            }
        }
        

        //确认
        $scope.save = function () {
            if(!vm.data.key){
                MessageService.error("基于不能为空");
                return
            }
            if(!vm.data.op){
                MessageService.error("运算符不能为空");
                return
            }
            if(vm.isOperator){
                if(!vm.minValue || !vm.maxValue){
                    MessageService.error("使用数量不能为空");
                    return
                }
                if(parseInt(vm.minValue) > parseInt(vm.maxValue)){
                    MessageService.error("请正确填写使用数量");
                    return
                }
                vm.data.value = vm.minValue + ',' + vm.maxValue;
            }else {
                if(vm.minValue == ''){
                    MessageService.error("使用数量不能为空");
                    return
                }
                vm.data.value = vm.minValue;
            }
            if(vm.data.value == ''){
                MessageService.error("使用数量不能为空");
                return
            }
            console.log(vm.data)
            $uibModalInstance.close(vm.data);
        }
        //取消
        $scope.modalHide = function () {
            $uibModalInstance.close();
        }
    }
})();
