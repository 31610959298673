(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('sorting', {
            parent: 'module',
            url: '/sorting',
            data: {
                authorities: ['ROLE_ITEM']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/sorting/sorting.html',
                    controller: 'SortingController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })
        .state('sortingAdd', {
            parent: 'module',
            url: '/sortingAdd',
            data: {
                authorities: ['ROLE_ITEM']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/sorting/sorting-add.html',
                    controller: 'SortingAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Channels', function ($stateParams, Channels) {
                    return Channels.getAll();
                }]
            }
        })
        .state('sortingEdit', {
            parent: 'module',
            url: '/sortingEdit/{id}',
            params:{page: null},
            data: {
                authorities: ['ROLE_ITEM']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/sorting/sorting-edit.html',
                    controller: 'SortingEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Sorting', function ($stateParams, Sorting) {
                    return Sorting.detail({id: $stateParams.id});
                }]
            }
        });
    }
})();
