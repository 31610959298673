(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CProvinceController', CProvinceController);

    CProvinceController.$inject = ['$scope','$state','MessageService', 'Channels', 'msgdata', '$uibModalInstance','LocationProvice'];

    function CProvinceController ($scope, $state,MessageService,Channels, msgdata,$uibModalInstance,LocationProvice) {
        var vm = this;
        vm.freight = msgdata.freight;
        vm.msgdata = msgdata.remoteProvinces || [];

        LocationProvice.query({},function (data) {
            for(var i=0; i<data.length; i++){
                if(vm.msgdata.length > 0){
                    for(var j=0; j<vm.msgdata.length; j++){
                        if(data[i].id == vm.msgdata[j].id){
                            data[i].selected = true
                        }
                    }
                }
            }
            vm.provinces =  data;
        });


        $scope.save = function(){
            var freight;
            var _provinces = [];
            for(var i=0; i<vm.provinces.length; i++){
                if(vm.provinces[i].selected){
                    _provinces.push(vm.provinces[i])
                }
            }
            freight = vm.freight;
            var data = {
               "remoteProvinces" :_provinces,
               "freight":freight
            };
            $uibModalInstance.close(data);
        }
        $scope.modalHide = function(){
            $uibModalInstance.close(false);
        };
    }
})();
