(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ResetPasswordController', ResetPasswordController);

    ResetPasswordController.$inject = ['$scope', 'Principal', 'LoginService','$state','User','$uibModal','$uibModalInstance','MessageService','$timeout', 'entity'];

    function ResetPasswordController ($scope, Principal, LoginService,$state,User,$uibModal,$uibModalInstance,MessageService,$timeout, entity) {
        var vm = this;
        vm.user = entity;


        $scope.save = function() {
            console.log(vm.user)
            if(vm.user.password == "" || vm.user.password == null){
                MessageService.error("新密码不能为空");
                return;
            }
            if (vm.user.password.length<4 || vm.user.password.length >100) {
                MessageService.error("新密码长度在4-100位之间");
                return false;
            }
            if (vm.user.confirmPassword == null || vm.user.confirmPassword == "") {
                MessageService.error("确认密码不能为空");
                return false;
            }
            if (vm.user.confirmPassword != vm.user.password) {
                MessageService.error("确认密码与新密码不一致");
                return false;
            }
            if (vm.user.confirmPassword.length<4 || vm.user.confirmPassword.length >100) {
                MessageService.error("确认密码长度在4-100位之间");
                return false;
            }
            vm.isSaving = true;
            User.reset({
                id: vm.user.id,
                username: vm.user.username,
                password: vm.user.password
            }, function (res) {
                vm.isSaving = false;

                MessageService.success('重置密码成功');
                $uibModalInstance.close(vm.user);
            }, function (error) {
                vm.isSaving = false;
                MessageService.error("重置密码失败");
            });
        };
        $scope.modalHide = function(){
            $uibModalInstance.close(false);
        };
    }
})();
