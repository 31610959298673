(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ClientController', ClientController);

    ClientController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService','$localStorage','$location','System'];

    function ClientController ($scope, Principal, LoginService, $state,User,MessageService,$localStorage,$location,System) {
        var vm = this;
        vm.token = $localStorage.authenticationToken;
        vm.flag = false;

        System.getAll({}, function (res) {
            for(var i=0; i<res.length; i++){
                if(res[i].key == 'jhi_key'){
                    vm.jhiKey = res[i];
                }
                if (res[i].key == 'flow_file') {
                    vm.flowFile = res[i];
                    vm.flag = true;
                }
            }
        });

        window.uploadExe = function($event) {
            var ext,idx;
            var fileObj = $event.target.files[0]; // js 获取文件对象
            idx = fileObj.name.lastIndexOf(".");
            ext = fileObj.name.substr(idx+1).toUpperCase();
            ext = ext.toLowerCase();
            if (ext != 'exe'){
                angular.element("#file").val("");
                MessageService.error("只能上传.exe类型的文件!");
                return;
            }
            var url =  $location.protocol() + '://' + $location.host() + ':' + $location.port() +  "/api/qiniu/upload";

            var formData = new FormData();
            formData.append('file', fileObj);

            $.ajax({
                cache: true,
                type: "POST",
                url: url,
                data: formData,
                async: false,
                contentType: false,
                processData: false,
                headers: {
                    Authorization: "Bearer " + vm.token
                },
                accept: 'file',
                success: function (data) {
                    angular.element("#file").val("");
                    MessageService.success("文件上传成功");
                    vm.flag =true;
                //  这里返回一个key值 可以用来保存
                    console.log(data.key);
                    vm.flowFile={
                        key:'flow_file',
                        value: data.key
                    };
                    $scope.$apply();
                },
                error: function (error) {
                    angular.element("#file").val("");
                    MessageService.error("文件上传失败，请稍后重试！");
                    vm.flag = false;
                }
            });
        };

        $scope.save = function () {
            if(!vm.jhiKey.value){
                MessageService.error("客户端版本号不能为空!");
                return;
            }
            if(!vm.flowFile.value){
                MessageService.error("客户端文件包不能为空!");
                return;
            }
            vm.isSaving = true;
            vm.jhiKey.key = 'jhi_key';
            System.update(vm.jhiKey, function onSaveSuccess () {
                vm.flowFile.key = 'flow_file';
                System.update(vm.flowFile, function onSaveSuccess () {
                    vm.isSaving = false;
                    MessageService.success("保存成功");
                }, function onSaveError () {
                    vm.isSaving = false;
                    MessageService.error("保存失败，请稍后重试！");
                });
            }, function onSaveError () {
                vm.isSaving = false;
                MessageService.error("保存失败，请稍后重试！");
            });
        };
    }
})();
