(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('DeliveryDetailsController', DeliveryDetailsController);

    DeliveryDetailsController.$inject = ['$q', '$scope', 'Principal', '$state', 'MessageService', 'LocationProvice', '$localStorage', '$uibModalInstance', 'entity','DeliveryRequest','SalesOrder'];

    function DeliveryDetailsController ($q, $scope, Principal, $state, MessageService, LocationProvice, $localStorage, $uibModalInstance, entity,DeliveryRequest,SalesOrder) {
        var vm = this;
        DeliveryRequest.get({id: entity.id}, function (res) {
            vm.data = res;
        })
        //取消
        $scope.modalHide = function () {
            $uibModalInstance.close();
        }
    }
})();
