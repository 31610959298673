(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SkuPriceController', SkuPriceController);

    SkuPriceController.$inject = ['$scope', 'msgdata', '$uibModalInstance', 'MessageService', 'Product', '$q'];

    function SkuPriceController ($scope, msgdata, $uibModalInstance, MessageService, Product, $q) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;

        vm.propertyPriceList = [];
        vm.product = Product.get({id: msgdata.productId});
        $q.all([vm.product.$promise]).then(function(){

            for (var i = vm.product.propertyConfigs.length - 1; i >= 0; i--) {
                var propertyPrice = {
                    propertyConfigId: vm.product.propertyConfigs[i].id, 
                    propertyConfigSku: vm.product.propertyConfigs[i].sku, 
                    price: 0.00,
                    productionManageId: msgdata.id};

                if (msgdata.propertyPriceList != null && msgdata.propertyPriceList.length != 0) {
                    for (var j = msgdata.propertyPriceList.length - 1; j >= 0; j--) {
                        if (vm.product.propertyConfigs[i].id == msgdata.propertyPriceList[j].propertyConfigId) {
                            propertyPrice.price = msgdata.propertyPriceList[j].price;
                        }
                    }
                }
                vm.propertyPriceList.push(propertyPrice);
            }
        });

        function clear(){
            $uibModalInstance.dismiss();
        }

        function save() {
            vm.isSaving = true;

            $uibModalInstance.close(vm.propertyPriceList);
        }
    }
})();
