(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('InvoicingController', InvoicingController);

    InvoicingController.$inject = ['$scope', 'Principal', 'LoginService', '$state','CustomerHub','MessageService', 'ParseLinks', '$stateParams','$uibModal', 'Invoices'];

    function InvoicingController ($scope, Principal, LoginService, $state,CustomerHub,MessageService, ParseLinks, $stateParams,$uibModal, Invoices) {
        var vm = this;

        vm.categories =[{
            name:'outsource',
            value:'委外'
        },{
            name:'SourceMaterials',
            value:'原辅材料'
        }];
        vm.loadAll = loadAll;
        vm.clear =clear;

        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];

        //待审核  已审核  已拒绝     配送中        已签收
        // CREATED, APPROVE, REJECTED, RECEIVING, RECEIVED
        vm.conditionList = [
            {name:'全部', id:''},
            {name:'待审核', id: 'CREATED'},
            {name:'已审核', id: 'APPROVE'},
            {name:'已拒绝', id: 'REJECTED'},
            {name:'已配送', id: 'RECEIVING'},
            {name:'已签收', id: 'RECEIVED'},
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            keyword:'',
            invoiceStatus: $stateParams.status|| ''
        };

        loadAll();
        function loadAll() {
            vm.invoices = [];
            Invoices.page(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                vm.invoices = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            loadAll();
                        }
                    }
                });
            }, function (error) {

            });
        }

        $scope.operate = function(data,attr){
            $uibModal.open({
                templateUrl: 'app/customerHub/invoicing-operate.html',
                controller: 'InvoicingOperateController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return {
                            title:attr,
                            data:data
                        }
                    }
                }
            }).result.then(function(result){
                // console.log(result);
                console.log(result)
                console.log(data)
                // data.invoiceStatus = 'REJECTED';
                loadAll();
            });
        }

        $scope.approve = function(data){
            Invoices.approve({id: data.id}, function (res) {
                MessageService.success('已通过');
                loadAll();
            })
        }


        $scope.conditionSelect = function(data){
            vm.tableData.invoiceStatus = data.id;
            vm.tableData.page = 0;
            loadAll();
        };

        $scope.search =function(){
            vm.tableData.page = 0;
            loadAll();
        };

        function clear(){
            vm.tableData.keyword = null;
            vm.tableData.page = 0;
            loadAll();
        }

        $scope.deleteData = function(id){
            MessageService.confirm("确认要删除该条数据？", function () {
                Invoices.delete({id: id}, function () {
                    loadAll();
                    MessageService.success("删除成功");
                }, function (error) {
                    MessageService.error("删除失败，请重试！");
                });
            });
        };

    }
})();
