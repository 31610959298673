(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('Invoice', Invoice)
        .factory('OrderTraces', OrderTraces);

    Invoice.$inject = ['$resource'];
    OrderTraces.$inject = ['$resource'];

    function Invoice ($resource) {
        var resourceUrl =  'api/deliver-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'page'
                }
            },
            'getAll': {
                method:'GET',
                isArray: true
            },
            'detail': {
                method:'GET'
            },
            'getRealTimeExpress': {
                method: 'GET',
                params: {
                    path: 'getRealTimeExpress'
                }
            }
        });
    }
    function OrderTraces ($resource) {
        var resourceUrl =  'api/order-traces/:path/:id';

        return $resource(resourceUrl, {}, {
            'getByDeliveryRequestId': {
                method: 'GET',
                params: {
                    path: 'getByDeliveryRequestId'
                }
            }
        });
    }
})();
