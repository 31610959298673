(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('InvoiceController', InvoiceController);

    InvoiceController.$inject = ['$scope', '$http', 'Principal', 'LoginService', '$state', 'DeliveryRequest', 'Express', 'MessageService',
    'ParseLinks', '$stateParams', 'SalesOrder', 'Supplier', 'DateUtils'];

    function InvoiceController ($scope, $http, Principal, LoginService, $state, DeliveryRequest, Express, MessageService,
        ParseLinks, $stateParams, SalesOrder, Supplier, DateUtils) {
        var vm = this;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: ['createdDate,DESC'],
            expressSettingId:'',
            keyword: '',
            deliveryStart:'',
            deliveryEnd:'',
            supId:'',
            deliveryStatus:'',
            delivered:true
        };
        vm.conditionList = [
            {name:'全部', value:''},
            {name:'已发货', value:'Delivered'},
            {name:'已揽件', value:'Receiving'},
            {name:'已签收', value:'Received'},
            {name:'物流异常', value:'Abnormal'},
        ];
        angular.element("#startDate").val(GetDateStr(-31));
        angular.element("#endDate").val(GetDateStr(0));

        Express.getAll(function(res){
            vm.expressCompanies = res.filter(function(item){return item.enable});
        });
        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }
        vm.clear =clear;
        init();
        function init() {
            vm.tableList = [];
            vm.tableData.deliveryStart = angular.copy(angular.element("#startDate").val());
            vm.tableData.deliveryEnd = angular.copy(angular.element("#endDate").val());
            DeliveryRequest.query(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };

        $scope.conditionSelect = function(data){
            vm.tableData.page = 0;
            vm.tableData.deliveryStatus = data.value;
            init()
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };

        function clear(){
            vm.tableData.keyword = '';
            vm.tableData.page = 0;
            init();
        }
        $scope.reset = function(){
            vm.tableData = {
                sort: ['createdDate,DESC'],
                page: 0,
                size: 20,
                expressSettingId:'',
                keyword: '',
                deliveryStart:'',
                deliveryEnd:'',
                supId:"",
                deliveryStatus:''
            };
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            init();
        };
        $scope.deliverChange = function(){
            for(var i=0; i<vm.expressCompanies.length; i++){
                if(vm.expressCompanies[i].id == vm.tableData.expressSettingId){
                    vm.tableData.supId = vm.expressCompanies[i].supplierId
                }
            }
        }


        //导出
        $scope.orderExport = function () {
            $http({
                url: 'api/delivery-request/exportData',
                method: "GET",
                params: vm.tableData,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "发货记录_" +DateUtils.convertLocalDateToServer(Date.now())+ ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);

            }).error(function (data, status, headers, config) {
            });
        };
    }
})();
