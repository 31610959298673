(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PaymentSettingsDetailController', PaymentSettingsDetailController);

    PaymentSettingsDetailController.$inject = ['$scope', '$rootScope', 'previousState', 'entity'];

    function PaymentSettingsDetailController($scope, $rootScope, previousState, entity) {
        var vm = this;

        vm.paymentSettings = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('gwApp:paymentSettingsUpdate', function(event, result) {
            vm.paymentSettings = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
