(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('UserAddController', UserAddController);

    UserAddController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'Role'];

    function UserAddController ($scope, Principal, LoginService, $state,User,MessageService,Role) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_USER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有用户访问的权限！");
                $state.go("home");
            }
        });

        init();
        function init(){
            vm.user = {
                username: '',
                role: '',
                password: '',
                confirmpassword: '',
                fullname: '',
                phone: '',
            };
        }

        Role.query({type:"内部"},function(data){
            vm.roleList = data;
            vm.role = [];
            if(vm.roleList.length>0){
                for(var i=0;i<vm.roleList.length;i++){
                    if(vm.roleList[i].enabled){
                        vm.role.push(vm.roleList[i]);
                    }
                }
            }
        });

        $scope.save = function () {
            if(!vm.user.username){
                MessageService.error("用户名不能为空");
                return;
            }
            if(!vm.user.role){
                MessageService.error("角色不能为空");
                return;
            }
            if(!vm.user.password){
                MessageService.error("密码不能为空");
                return;
            }
            if(vm.user.confirmpassword!=vm.user.password){
                MessageService.error("两次输入的密码不匹配");
                return;
            }
            if (vm.user.phone == null || vm.user.phone == "") {
                MessageService.error("联系方式不能为空");
                return false;
            }
            // if(!(/^1[345678]\d{9}$/.test(vm.user.phone))){
            //     MessageService.error("请输入正确的手机号");
            //     return;
            // }
            vm.isSaving = true;
            for(var i=0;i<vm.role.length;i++){
                if(vm.role[i].id == vm.user.role){
                    vm.user.roleId = vm.role[i].id;
                    vm.user.roleName = vm.role[i].name;
                }
            }
            vm.user.name = vm.user.username;
            User.validate({name:vm.user.name},function (res) {
                vm.isSaving = false;
                if (res.message) {
                    User.save(vm.user, onSaveSuccess, onSaveError);
                } else {
                    MessageService.error("系统存在重复的用户名");
                }
            },function (error) {
                vm.isSaving = false;
                MessageService.error("操作失败");
            });
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("用户添加成功");
                $state.go("user");
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("系统存在重复的用户名");
            }
        };
    }
})();
