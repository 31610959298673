(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('makeup', {
                parent: 'module',
                url: '/makeup',
                params:{page: null},
                data: {
                    authorities: ['ROLE_SET_MAKEUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/makeup/makeup.html',
                        controller: 'MakeupController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('makeupAdd', {
                parent: 'module',
                url: '/makeupAdd',
                data: {
                    authorities: ['ROLE_SET_MAKEUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/makeup/makeup-add.html',
                        controller: 'MakeupAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('makeupEdit', {
                parent: 'module',
                url: '/makeupEdit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_SET_MAKEUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/makeup/makeup-edit.html',
                        controller: 'MakeupEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Makeup', function($stateParams, Makeup) {
                        return Makeup.detail({id : $stateParams.id});
                    }],
                }
            })

        ;
    }
})();
