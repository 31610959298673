(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ReplenishmentChartController', ReplenishmentChartController);

    ReplenishmentChartController.$inject = ['$scope', '$state', 'MessageService', 'SalesOrder', '$uibModal', 'Supplier','Product', '$localStorage','$http', '$stateParams', '$rootScope','ReplenishmentReason'];

    function ReplenishmentChartController ($scope, $state, MessageService, SalesOrder, $uibModal, Supplier, Product, $localStorage, $http, $stateParams, $rootScope,ReplenishmentReason) {
        var vm = this;
        vm.token = $localStorage.authenticationToken;
        vm.timeName = 'day';
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            supId: '',
            productId: '',
            sku: '',
            startDate: '',
            endDate: '',
        };
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.typeName = '';

        $scope.getProductId =function(data){
            vm.tableData.productId =data ? data.id :'';
        };

        init();
        function init() {
            ReplenishmentReason.getAll(function (res) {
                vm.reasonList = res;
            })
            Product.getAll(function (res) {
                vm.productList = res.filter(function(item){return item.enable});
            });
            Supplier.getAll(function (res) {
                vm.supplierList = res.filter(function(item){return item.enable});
                vm.tableData.supId = res[0].id;
                vm.timeList = [
                    {name:'昨日', value:'day'},
                    {name:'最近7天', value:'week'},
                    {name:'最近30天', value:'month'}
                ];
                angular.element("#endDate").val(GetDateStr(-0)+' 23:59:59');
                angular.element("#startDate").val(GetDateStr(-30)+' 00:00:00');
                initData();
            });
        }
        $scope.timeCb = function (time) {
            // vm.timeName = time.value;
            angular.element("#endDate").val(GetDateStr(-1)+' 23:59:59');
            if(time.value == 'day'){
                angular.element("#startDate").val(GetDateStr(-1)+' 00:00:00');
            }
            if(time.value == 'week'){
                angular.element("#startDate").val(GetDateStr(-7)+' 00:00:00');
            }
            if(time.value == 'month'){
                angular.element("#startDate").val(GetDateStr(-30)+' 00:00:00');
            }
            initData();
        };

        $scope.inquire = function () {
            initData();
        }
        function initData() {
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            if(vm.tableData.supId == ""){
                MessageService.error("供应商不能为空");
                return
            }
            if(vm.tableData.startDate == ""){
                MessageService.error("开始时间不能为空");
                return
            }
            if(vm.tableData.endDate == ""){
                MessageService.error("结束时间不能为空");
                return
            }
            var _startDate = new Date(vm.tableData.startDate);
            var _endDate = new Date(vm.tableData.endDate);
            var _mistiming = parseInt(_endDate - _startDate) / 1000 / 60 / 60 / 24;
            vm.timeName = "";
            if(GetDateStr(-1) == vm.tableData.endDate){
                if(_mistiming == 0){vm.timeName = "day";}
                if(_mistiming == 6){vm.timeName = "week";}
                if(_mistiming == 29){vm.timeName = "month";}
            }

            var myChart = echarts.init(document.getElementById('main'));
            myChart.showLoading();
            SalesOrder.getReplenishData2(vm.tableData, function (res) {
                chart(res.data, myChart);
                myChart.hideLoading();

                // 表格数据
                vm.tableData2 =angular.copy(vm.tableData);
                // vm.tableData2.page= 0;
                vm.tableData2.size= 20;
                SalesOrder.getReplenishData(vm.tableData2, function (data) {
                    vm.typeData = data;
                }, function (error) {});
            }, function () {
                MessageService.error("查询数据失败，请稍后重试！");
                myChart.hideLoading();
            })
        }


        function chart(data, myChart) {
            var _time = [];
            var _legend = "订单数";
            if(vm.tableData.startDate == vm.tableData.endDate){
                _time.push(vm.tableData.startDate)
            }else {
                _time = getDiffDate(vm.tableData.startDate,vm.tableData.endDate);
                _time.push(vm.tableData.endDate)
            }
            var option = {
                tooltip: {trigger: 'axis'},
                legend: {
                    data:[_legend]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    data: _time
                },
                yAxis: {},
                series: [{
                    name: _legend,
                    type: 'line',
                    itemStyle : {
                        normal : {
                            color:'#ff9601',
                            lineStyle:{
                                color:'#ff9601'
                            }
                        }
                    },
                    data: data
                }]
            };
            myChart.setOption(option);
        }

        //下载
        $scope.chartExport = function () {
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            if(vm.tableData.startDate == ""){
                MessageService.error("开始时间不能为空");
                return
            }
            if(vm.tableData.endDate == ""){
                MessageService.error("结束时间不能为空");
                return
            }
            $http({
                url: 'api/sales_order/downloadReplenishData',
                method: "GET",
                params: vm.tableData,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = Date.now() + ".xls";
                a.setAttribute('download', '');
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        };

        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }
        function getDate (datestr) {
            datestr = datestr.split(" ")[0];
            var temp = datestr.split("-");
            if (temp[1] === '01') {
                temp[0] = parseInt(temp[0],10) - 1;
                temp[1] = '12';
            } else {
                temp[1] = parseInt(temp[1],10) - 1;
            }
            //new Date()的月份入参实际都是当前值-1
            var date = new Date(temp[0], temp[1], temp[2]);
            return date;
        }
        function getDiffDate (start, end) {
            var startTime = getDate(start);
            var endTime = getDate(end);
            var dateArr = [];
            while ((endTime.getTime() - startTime.getTime()) > 0) {
                var year = startTime.getFullYear();
                var month = startTime.getMonth().toString().length === 1 ? "0" + (parseInt(startTime.getMonth().toString(),10) + 1) : (startTime.getMonth() + 1);
                var day = startTime.getDate().toString().length === 1 ? "0" + startTime.getDate() : startTime.getDate();
                dateArr.push(year + "-" + month + "-" + day);
                startTime.setDate(startTime.getDate() + 1);
            }
            return dateArr;
        }
    }
})();
