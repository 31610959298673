var app = angular.module('editor', []); 
    app.directive('contenteditable', function() {
      return {
        require: 'ngModel',
        link: function(scope, element, attrs, ctrl) {
       
          element.bind('keyup', function() {
            scope.$apply(function() {
              var html=element.html();

              ctrl.$setViewValue(html);
            });
          });

        }
      };
    });

