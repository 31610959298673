/**
 * Created by 小猪猪lover on 2019/1/10.
 */
(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('supplyManagement', {
                parent: 'module',
                url: '/supplyManagement',
                params:{page: null},
                data: {
                    authorities: ['ROLE_SUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/supply/supply.html',
                        controller: 'SupplyController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }]
                }
            })
            .state('supplyNew', {
                parent: 'module',
                url: '/supply-new',
                data: {
                    authorities: ['ROLE_SUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/supply/supply-dialog.html',
                        controller: 'SupplyNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return {
                            id:''
                        };
                    }
                }
            })
            .state('supplyEdit', {
                parent: 'module',
                url: '/supply-edit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_SUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/supply/supply-dialog.html',
                        controller: 'SupplyNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Supplier', function ($stateParams, Supplier) {
                        return Supplier.get({id: $stateParams.id});
                    }]
                }
            })
            .state('supplyDetail', {
                parent: 'module',
                url: '/supply-detail/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_SUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/supply/supply-detail.html',
                        controller: 'SupplyDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Supplier', function ($stateParams, Supplier) {
                        return Supplier.get({id: $stateParams.id});
                    }]
                }
            })
    }
})();
