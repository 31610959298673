(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PurchasingApplicationPageController', PurchasingApplicationPageController);

    PurchasingApplicationPageController.$inject = ['$scope','$state', 'entity'];

    function PurchasingApplicationPageController ($scope, $state, entity) {
        var vm = this;

        vm.purchaseOrder = entity;

        $scope.cancelSave = function() {
            $state.go("purchasingApplication");
        }
    }
})();
