(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('PaymentSettings', PaymentSettings);

    PaymentSettings.$inject = ['$resource'];

    function PaymentSettings($resource) {
        var resourceUrl = 'api/payment-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET' },
            'getOne': {
                method: 'GET',
                params:{
                    path:"getOne"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' }
        });
    }
})();
