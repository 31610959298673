(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ExpressApiConfigController', ExpressApiConfigController);

    ExpressApiConfigController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'System', 'Qinius', '$q', '$timeout','ExpressApiSetting'];

    function ExpressApiConfigController ($scope, Principal, LoginService, $state,User,MessageService,System, Qinius, $q, $timeout,ExpressApiSetting) {
        var vm = this;
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.expressApiSettings = {};

        init()

        function init(){
            ExpressApiSetting.get(function(res){
                vm.expressApiSettings = res
            })
        }


        $scope.saveExpress = function(){
            ExpressApiSetting.update(vm.expressApiSettings, function onSaveSuccess () {
                vm.isSaving = false;
                MessageService.success("保存成功");
                init()
            }, function onSaveError () {
                vm.isSaving = false;
                MessageService.error("保存失败，请稍后重试！");
            })

        }
    }
})();
