(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('priceList', {
                parent: 'module',
                url: '/priceList',
                params:{page: null},
                data: {
                    authorities: ['ROLE_PRICE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/priceList/priceList.html',
                        controller: 'PriceListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('priceListAdd', {
                parent: 'module',
                url: '/priceListAdd',
                data: {
                    authorities: ['ROLE_PRICE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/priceList/priceList-edit.html',
                        controller: 'PriceListEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function() {
                        return {
                            name: '',
                            customerHubId: null,
                            priceLines: [],
                            enabled: true,
                            base: false
                        };
                    }
                }
            })
            .state('priceListEdit', {
                parent: 'module',
                url: '/priceListEdit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_PRICE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/priceList/priceList-edit.html',
                        controller: 'PriceListEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Prices', function($stateParams, Prices) {
                        return Prices.detail({id : $stateParams.id});
                    }]
                }
            });
    }
})();
