(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('Material', Material);

    Material.$inject = ['$resource'];

    function Material ($resource) {
        var resourceUrl =  'api/materials/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true,
                    params: {
                        id: 'page'
                    },
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                isArray: true
            } ,
            'getNumber': {
                method:'GET',
                params: {
                    id: 'getNumber'
                }
            } ,
            'delete':{
                method:'DELETE',
                params: {
                    login: 'id'
                }
            },
            'save': { method:'POST' } ,
            'update': { method:'PUT' },
            'detail': {method:'GET',
                params:{
                    id:'exists'
                }
            }
        });
    }
})();
