(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('supplyApi', {
            parent: 'module',
            url: '/supplyApi',
            params:{page: null},
            data: {
                authorities: []
            },
            views: {
                'content': {
                    templateUrl: 'app/supplyApi/supplyApi.html',
                    controller: 'SupplyApiController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })
        .state('supplyApiAdd', {
            parent: 'module',
            url: '/add',
            data: {
                authorities: []
            },
            views: {
                'content': {
                    templateUrl: 'app/supplyApi/supplyApi-details.html',
                    controller: 'SupplyApiDetailsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SupplierAbutSettings', function ($stateParams, SupplierAbutSettings) {
                    return
                }]
            }
        })
        .state('supplyApiEdit', {
            parent: 'module',
            url: '/edit/{id}',
            data: {
                authorities: []
            },
            views: {
                'content': {
                    templateUrl: 'app/supplyApi/supplyApi-details.html',
                    controller: 'SupplyApiDetailsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SupplierAbutSettings', function ($stateParams, SupplierAbutSettings) {
                    return SupplierAbutSettings.get({id: $stateParams.id});
                }]
            }
        })
    }
})();
