(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('payment-settings', {
                parent: 'module',
                url: '/payment-settings',
                data: {
                    authorities: [],
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/payment-settings/payment-settings-detail.html',
                        controller: 'PaymentSettingsDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['PaymentSettings', function(PaymentSettings) {
                        return PaymentSettings.getOne().$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'payment-settings',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('payment-settings.new', {
                parent: 'module',
                url: '/payment-settings/new',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/payment-settings/payment-settings-dialog.html',
                        controller: 'PaymentSettingsDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['PaymentSettings', function(PaymentSettings) {
                        return {
                            weChatKey: null,
                            weChatAppid: null,
                            weChatMchid: null,
                            weChatNotifyUrl: null,
                            weChatCertificatePath: null,
                            id: null
                        };
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'payment-settings',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('payment-settings.edit', {
                parent: 'module',
                url: '/payment-settings/{id}/edit',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/payment-settings/payment-settings-dialog.html',
                        controller: 'PaymentSettingsDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['PaymentSettings', function(PaymentSettings) {
                        return PaymentSettings.getOne().$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'payment-settings',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
