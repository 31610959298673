(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('Province1Controller', Province1Controller);

    Province1Controller.$inject = ['$scope','$state','MessageService', 'Express', 'msgdata', '$uibModalInstance','LocationProvice','LocationCity'];

    function Province1Controller ($scope, $state,MessageService,Express, msgdata,$uibModalInstance,LocationProvice,LocationCity) {
        var vm = this;
        vm.msgdata0 = msgdata ? msgdata.cities : [];
        vm.msgdata1 = [];
        msgdataInit()
        function msgdataInit(){
            var _data = [];
            for(var i= 0 ; i<vm.msgdata0.length; i++){
                _data.push(vm.msgdata0[i].provinceId);
            }
            var _data1 = uniq(_data);
            for(var i=0; i<_data1.length; i++){
                var _obj={
                    id:_data1[i],
                    name:"",
                    cities:[]
                }
                vm.msgdata1.push(_obj)
            }
            for(var j=0; j<vm.msgdata0.length; j++){
                for(var k=0; k<vm.msgdata1.length; k++){
                    if(vm.msgdata1[k].id == vm.msgdata0[j].provinceId){
                        vm.msgdata1[k].cities.push(vm.msgdata0[j])
                    }
                }
            }
            vm.msgdata = vm.msgdata1;
            LocationProvice.query({},function (data) {
                vm.provinces =  data;
                for(var i=0; i<data.length; i++){
                    city(data[i])
                }
            });
        }
        function uniq(array){
            var temp = []; //一个新的临时数组
            for(var i = 0; i < array.length; i++){
                if(temp.indexOf(array[i]) == -1){
                    temp.push(array[i]);
                }
            }
            return temp;
        }

        function city(data){
            LocationCity.findByProvince({provinceId: data.id}, function (res) {
                data.cities = res;
                for (var i = data.cities.length - 1; i >= 0; i--) {
                    var c = data.cities[i];
                    for(var j=0; j<vm.msgdata.length; j++){
                        if(data.id == vm.msgdata[j].id){
                            data.selected = true;
                            for(var k=0; k<vm.msgdata[j].cities.length; k++){
                                if(c.id == vm.msgdata[j].cities[k].id){
                                    c.selected = true;
                                }
                            }
                        }
                    }
                }
            });
        }
        $scope.provinceSelect = function(data,event){
            data.selected = event.target.checked;
            for(var i=0; i<data.cities.length; i++){
                data.cities[i].selected = event.target.checked;
            }
            selectinit()
        };
        $scope.citySelect = function(data,city,event){
            city.selected = event.target.checked;
            var isCity = false;
            for(var i=0; i<data.cities.length; i++){
                if(data.cities[i].selected){
                    isCity = true;
                }
            }
            if(isCity){
                data.selected = true;
            }else {
                data.selected = false;
            }
            selectinit()
        };
        function selectinit(){
            vm.data = [];
            for(var i=0; i<vm.provinces.length; i++){
                if(vm.provinces[i].selected){
                    for(var j=0; j<vm.provinces[i].cities.length; j++){
                        if(vm.provinces[i].cities[j].selected){
                            vm.data.push(vm.provinces[i].cities[j])
                        }
                    }
                }
            }
        }


        $scope.save = function(){
            $uibModalInstance.close(vm.data);
        }
        $scope.modalHide = function(){
            $uibModalInstance.close(false);
        };
    }
})();
