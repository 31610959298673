(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('Express', Express);

    Express.$inject = ['$resource'];

    function Express ($resource) {
        var resourceUrl =  'api/express-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'page': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'page'
                }
            },
            'getAll': {
                method:'GET',
                isArray: true
            } ,
            'delete':{method:'DELETE'},
            'add': { method:'POST' } ,
            'update': { method:'PUT' },
            'detail': {
                method:'GET'
            }
        });
    }
})();
