(function () {
    'use strict';

    angular
        .module('gwApp')
        .controller('PaymentSettingsDialogController', PaymentSettingsDialogController);

    PaymentSettingsDialogController.$inject = ['$timeout', '$scope', '$state', 'entity', 'PaymentSettings', 'MessageService'];

    function PaymentSettingsDialogController($timeout, $scope, $state, entity, PaymentSettings, MessageService) {
        var vm = this;

        vm.paymentSettings = entity;
        vm.clear = clear;
        vm.save = save;

        if(vm.paymentSettings==null){
            vm.paymentSettings={
                'weChatKey': null,
                'weChatAppid': null,
                'weChatMchid': null,
                'weChatNotifyUrl': null,
                'weChatCertificatePath': null,
                'id': null
            }
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go('payment-settings', null, { reload: 'payment-settings' });
        }

        function save() {
            vm.isSaving = true;
            PaymentSettings.update(vm.paymentSettings, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(res) {
            if(res.id){
                $state.go('payment-settings', null, { reload: 'payment-settings' });
            }else {
                MessageService.error(res.message);
            }
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
