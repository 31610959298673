(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MinWarningController', MinWarningController);

    MinWarningController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService','ProductCategory', 'ParseLinks', '$uibModal','$uibModalInstance', 'entity', 'Depository'];

    function MinWarningController ($scope, Principal, LoginService, $state,User,MessageService, ProductCategory, ParseLinks, $uibModal, $uibModalInstance, entity, Depository) {
        var vm = this;
        vm.isMaterial = entity.type ? true : false;
        // if(typeof entity == "object"){
            vm.data = {
                minStorage: entity.data ? entity.data.minStorage : '',
                depotsWarning:entity.data ? (entity.data.warehouseConfigs.length > 0 ? true : false) : false,
                warehouseConfigs: entity.data ? entity.data.warehouseConfigs : []
            }
        // }else {
        //     vm.data = {
        //         minStorage:'',
        //         depotsWarning:false,
        //         warehouseConfigs: []
        //     }
        // }
        //仓库列表
        if(vm.isMaterial){
            vm.warehouseList = Depository.getAll({type: "accessories"});
        }else {
            vm.warehouseList = Depository.getAll();
        }

        //新增
        $scope.add = function () {
            var _obj = {
                warehouseId: '',
                warehouseName: '',
                minStorage: ''
            };
            vm.data.warehouseConfigs.push(_obj);
        }
        //选择仓库
        $scope.warehouseSelect = function(data){
            for(var i=0; i<vm.warehouseList.length; i++){
                if(vm.warehouseList[i].id == data.warehouseId){
                    data.warehouseName = vm.warehouseList[i].name;
                }
            }
        }
        //是否启动分库预警
        $scope.depotsWarningCb = function(){
            if(!vm.data.depotsWarning){
                vm.data.warehouseConfigs = [];
            }else{
                vm.data.minStorage = null;
            }
        }

        //删除
        $scope.delete = function (index) {
            vm.data.warehouseConfigs.splice(index, 1)
        };
        //确认
        $scope.save = function () {
            $uibModalInstance.close(vm.data);
        }
        //取消
        $scope.modalHide = function () {
            $uibModalInstance.close();
        }
    }
})();
