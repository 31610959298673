(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MaterialEditController', MaterialEditController);

    MaterialEditController.$inject = ['$scope','$state','MessageService','$uibModal', 'MaterialTemplates', 'UnitMeasurement', 'Material','entity','$q','ProductCategory'];

    function MaterialEditController ($scope,$state,MessageService,$uibModal, MaterialTemplates, UnitMeasurement, Material,entity,$q,ProductCategory) {
        var vm = this;

        //计量单位
        UnitMeasurement.getAll(function(res){
            vm.unitList =res.filter(function(item){return item.defaulted;});
        });
        vm.materialTypeList =[
            {name: '原材料', id: '原材料'},
            {name: '纸张', id: '纸张'},
            {name: '包材', id: '包材'}
        ];
        vm.data = entity;
        vm.superiorName = null;
        //辅材品类一级
        vm.productCategoryList = ProductCategory.getAll({type:'accessories'});
        $q.all([vm.data.$promise]).then(function(){
            vm.data.propertyConfigs.sort(function(a, b) {
                return a.sort - b.sort
            })
            //商品属性
            vm.commodityPropertyList = vm.data.materialTemplate.items;
        });

        //辅材模板
        MaterialTemplates.getAll({type: 'accessories'}, function (res) {
            vm.materialTemplatesList = res.filter(function(item){return item.enable;})
            for(var i=0; i<res.length; i++){
                if(res[i].id == vm.data.materialTemplate.id){
                    vm.materialTemplate = res[i];
                    $scope.materialTemplateSelect();
                }
            }
            for(var i=0; i<vm.productCategoryList.length; i++){
                if(vm.productCategoryList[i].id == vm.materialTemplate.productCategoryId && vm.productCategoryList[i].superiorId != "") {
                    vm.superiorName = vm.productCategoryList[i].superiorName;
                }
            }
        });

        //计量单位选中
        $scope.unitSelect = function(){
            for(var i=0; i<vm.unitList.length; i++){
                if(vm.unitList[i].id == vm.data.measurementId){
                    vm.data.measurementName = vm.unitList[i].name;
                }
            }
        };

        //辅材模板选中
        $scope.materialTemplateSelect = function(){
            vm.commodityProperty =[];
            vm.superiorName = null;
            if(vm.materialTemplate ==null){
                vm.commodityPropertyList =[];
                vm.data.materialTemplate.id = '';
                vm.data.type ='';
            }else{
                vm.commodityPropertyList = vm.materialTemplate.items;
                vm.data.materialTemplate.id = vm.materialTemplate.id;
                vm.data.type = vm.data.materialTemplate.productCategoryName;

                for(var i=0; i<vm.productCategoryList.length; i++){
                    if(vm.productCategoryList[i].id == vm.materialTemplate.productCategoryId){
                        vm.materialTemplate.productCategoryName = vm.productCategoryList[i].name;
                        if(vm.productCategoryList[i].superiorId){
                            vm.superiorName = vm.productCategoryList[i].superiorName;
                        }
                    }
                }
            }
        };

        $scope.propertyDelete = function(index){
            vm.data.propertyConfigs.splice(index,1);
        };

        vm.commodityProperty = [];
        $scope.commodityPropertyFn = function($event, data){
            //var checkbox = $event.target.checked;
            //if(checkbox){
            //    vm.commodityProperty.push(data)
            //}else {
            //    for(var i=0; i<vm.commodityProperty.length; i++){
            //        if(vm.commodityProperty[i].id == data.id){
            //            vm.commodityProperty.splice(i, 1)
            //        }
            //    }
            //}

            vm.commodityProperty =vm.commodityPropertyList.filter(function(item){
                return item.checked;
            });
        };

        //商品属性快速生成
        $scope.rapidGenerating = function () {
            if(!vm.data.name){
                MessageService.error("请填写辅材名称！");
                return;
            }else if(vm.data.materialTemplate.id == undefined){
                MessageService.error("请选择辅材模板！");
                return;
            }else if(vm.commodityProperty.length ==0){
                MessageService.error("请选择属性！");
                return;
            }else {
                $uibModal.open({
                    templateUrl: 'app/product/finished/modal/property.html',
                    controller: 'PropertyController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function () {
                            return vm.commodityProperty;
                        }]
                    }
                }).result.then(function (backData) {
                    if (typeof backData == "object") {
                        var _backData = [];
                        for (var x = 0; x < backData.length; x++) {
                            if (backData[x].length > 0) {
                                _backData.push(backData[x]);
                            }
                        }
                        var _arr = getCombination(_backData);
                        if (typeof _arr == "object") {
                            for (var i = 0; i < _arr.length; i++) {
                                var _sku = vm.data.name + _arr[i];
                                var _isSku = false;
                                for(var j=0; j<vm.data.propertyConfigs.length; j++){
                                    var _sku1 = vm.data.propertyConfigs[j].sku;
                                    if(_sku == _sku1){
                                        _isSku = true;
                                    }
                                }
                                if(!_isSku) {
                                    var _obj = {
                                        sku: vm.data.name + _arr[i],
                                        number: vm.data.number + '-' + (vm.data.propertyConfigs.length + 1),
                                        minStorage: '',
                                        warehouseConfigs: null
                                    };
                                    vm.data.propertyConfigs.push(_obj);
                                }
                            }
                        }
                    }
                });
            }
        };

        function getCombination (array) {
            if(array.length > 0){
                var _arr = [];
                if (array.length < 2) {
                    for(var j =0; j<array[0].length; j++){
                        _arr.push([array[0][j]])
                    }
                }else {
                    _arr = [].reduce.call(array, function (col, set) {
                        var res = [];
                        col.forEach(function (c) {
                            set.forEach(function (s) {
                                var t = [].concat(Array.isArray(c) ? c : [c]);
                                t.push(s);
                                res.push(t);
                            })
                        });
                        return res;
                    });
                }
                for(var i=0; i<_arr.length; i++){
                    var _h = '';
                    var _arrI = _arr[i].sort();
                    for(var j=0; j<_arrI.length; j++){
                        _h += '_' + _arrI[j];
                    }
                    _arr[i] = _h
                }
                return _arr
            }
        }

        //设置最小库存预警
        $scope.warningSet = function(index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/minWarning.html',
                controller: 'MinWarningController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        {type: true}
                    }]
                }
            }).result.then(function (backData) {
                console.log(backData)
                if(typeof backData == "object"){
                    vm.data.propertyConfigs[index].minStorage = backData.minStorage;
                    vm.data.propertyConfigs[index].depotsWarning = backData.depotsWarning;
                    vm.data.propertyConfigs[index].warehouseConfigs = backData.warehouseConfigs;
                }
            });
        }
        $scope.warningEdit = function(data,index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/minWarning.html',
                controller: 'MinWarningController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {type: true, data: data};
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    vm.data.propertyConfigs[index].minStorage = backData.minStorage;
                    vm.data.propertyConfigs[index].depotsWarning = backData.depotsWarning;
                    vm.data.propertyConfigs[index].warehouseConfigs = backData.warehouseConfigs;
                }
            });
        }
        $scope.save = function() {
            // for(var i=0; i<vm.data.propertyConfigs.length; i++){
            //     vm.data.propertyConfigs[i].number = vm.data.number + '-' + (Number(i)+Number(1));
            // }
            if(!vm.data.name){
                MessageService.error("辅材名称不能为空");
                return;
            }
            if(vm.data.name.length >20){
                MessageService.error("辅材名称不能超过20位");
                return;
            }
            if(!vm.data.materialTemplate.id){
                MessageService.error("辅材模板不能为空");
                return;
            }
            if(!vm.data.type){
                MessageService.error("辅材品类不能为空");
                return;
            }
            if(vm.data.propertyConfigs.length == 0){
                MessageService.error("属性不能为空");
                return;
            }

            vm.isSaving = true;
            Material.update(vm.data, function (res) {
                MessageService.success("保存成功");
                $state.go("material");
            }, function (error) {
                vm.isSaving = false;
                MessageService.error("保存失败，请检查是否误删使用中的辅料SKU");
            });
        };

    }
})();
