(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('AllocationTaskController', AllocationTaskController);

    AllocationTaskController.$inject = ['$scope','$state','$uibModalInstance','MessageService','Product','Allocations','Depository','msgdata'];

    function AllocationTaskController ($scope, $state,$uibModalInstance,MessageService,Product,Allocations,Depository,msgdata) {
        var vm = this;
        vm.allocation = msgdata;
        //type  accessories 辅料 / product 成品
        Depository.getAll({type: "product"},function(res){
            vm.depositories = res.filter(function(item){return item.defaulted});
        });

        Product.getAll({},function(res){
            vm.products = res.filter(function(item){return item.enable});
        });

        $scope.validate = function() {
            if (vm.inWarehouse == null || vm.inWarehouse == "") {
                MessageService.error("请选择调入仓库");
                return false;
            };
            if (vm.allocation.warehouse.id ==vm.inWarehouse.id) {
                MessageService.error("调入仓库和调出仓库一致，无效");
                return false;
            };
            if (!vm.num) {
                MessageService.error("请选择调整数量");
                return false;
            };
            if (vm.num > vm.allocation.usableQuantity) {
                MessageService.error("调出库存不足！");
                return false;
            };
            if (!vm.reason) {
                MessageService.error("请选择调整原因");
                return false;
            };
            return true;
        };

        $scope.saveAllocation = function(){
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            var obj={
                inWarehouse:vm.inWarehouse,
                outWarehouse:vm.allocation.warehouse,
                measurement:vm.allocation.measurement,
                propertyConfig:vm.allocation.propertyConfig,
                type:'product',
                num:vm.num,
                reason:vm.reason
            };
            Allocations.create(obj,function (res) {
                MessageService.success("挑拨记录新建成功!");
                $uibModalInstance.close();
            },function (error) {
                vm.isSaving = false;
                MessageService.error("挑拨记录新建失败！");
            });
        };

        $scope.cancelRecord = function(){
            $uibModalInstance.close();
        };

    }
})();
