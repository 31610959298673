(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('System', System)
        .factory('Qinius', Qinius)
        .factory('SystemLog', SystemLog);

    System.$inject = ['$resource'];
    SystemLog.$inject = ['$resource'];
    Qinius.$inject = ['$resource'];

    function Qinius($resource) {
        var resourceUrl = 'api/qiniu/:path/:storeId';
        var service = $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                params: {
                    path: 'upload-token'
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getDomain': {
                method: 'GET',
                params: {
                    path: 'bucket-domains'
                }
            }
        });
        return service;
    }


    function System ($resource) {
        var resourceUrl =  'api/system-settings/:id';

        return $resource(resourceUrl, {}, {
            'getAll':{
                method:'GET', isArray: true,
                params: {
                    id: 'getAll'
                }
            },
            'update': { method:'PUT' }
        });
    }

    function SystemLog ($resource) {
        var resourceUrl =  'api/operation-logs/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAll':{
                method:'GET',
                isArray: true
            },
            page:{
                method:'GET',
                params: {
                    path: 'page'
                },
                isArray: true
            },
            'update': { method:'PUT' },
            'create': { method:'POST' },
            'detail': { method:'GET' },
            'delete': { method:'DELETE'}
        });
    }
})();
