(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MerchantController', MerchantController);

    MerchantController.$inject = ['$scope', 'Principal', 'LoginService', '$state','Merchant','MessageService', 'ParseLinks', '$localStorage', '$location', '$stateParams'];

    function MerchantController ($scope, Principal, LoginService, $state,Merchant,MessageService, ParseLinks, $localStorage, $location, $stateParams) {
        var vm = this;
        vm.token = $localStorage.authenticationToken;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: ['createdDate,DESC'],
            keyword: ''
        };

        init()
        function init() {
            vm.tableList = [];
            Merchant.query(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };

        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        //删除
        $scope.userDelete = function (data) {
            MessageService.confirm("确认要删除该数据？", function () {
                Merchant.delete({id: data.id}, function () {
                    init();
                    MessageService.success("删除成功");
                }, function (error) {
                    MessageService.error("删除失败，请重试！");
                });
            });
        };

        //批量上传
        window.upladFile = function($event) {
            var ext,idx;
            var fileObj = $event.target.files[0]; // js 获取文件对象
            idx = fileObj.name.lastIndexOf(".");
            ext = fileObj.name.substr(idx+1).toUpperCase();
            ext = ext.toLowerCase();
            if (ext != 'xlsx' && ext != 'xls' && ext != 'csv'){
                angular.element("#file").val("");
                MessageService.error("只能上传.xlsx  .xls类型的文件!");
                return;
            }
            var url =  $location.protocol() + '://' + $location.host() + ':' + $location.port() +  "/api/merchants/importData"; // 接收上传文件的后台地址

            var formData = new FormData();
            formData.append('file', fileObj);
            $.ajax({
                cache: true,
                type: "POST",
                url: url,
                data: formData,
                async: false,
                contentType: false,
                processData: false,
                headers: {
                    Authorization: "Bearer " + vm.token
                },
                accept: 'file',
                success: function (data) {
                    angular.element("#file").val("");
                    MessageService.success("批量上传成功");
                    init();
                },
                error: function (error) {
                    angular.element("#file").val("");
                    MessageService.error("文件导入失败，请稍后重试！");
                }
            });
        };
    }
})();
