(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('DeliveryRequestShipmentsController', DeliveryRequestShipmentsController);

    DeliveryRequestShipmentsController.$inject = ['$q', '$scope', 'Principal', '$state', 'MessageService', 'Express', 'entity', '$uibModalInstance', 'DeliveryRequest'];

    function DeliveryRequestShipmentsController ($q, $scope, Principal, $state, MessageService, Express, entity, $uibModalInstance, DeliveryRequest) {
        var vm = this;
        vm.delivery = entity;

        Express.getAll(function(res){
            vm.expressCompanies = res.filter(function(item){return item.enable});
        });
        //取消
        $scope.cancelRecord = function () {
            $uibModalInstance.close();
        }

        $scope.save = function () {
            DeliveryRequest.delivery({
                deliveryRequestId: vm.delivery.id,
                expressSettingId: vm.expressSettingId,
                logisticsNumber: vm.logisticsNumber
            }, function (res) {
                if(res.state == 200){
                    MessageService.success("发货成功");
                    $uibModalInstance.close(true);
                }else{
                    MessageService.error(res.message || "发货失败");
                }
            })
        }

    }
})();
