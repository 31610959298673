(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('InventoryAddTwoController', InventoryAddTwoController);

    InventoryAddTwoController.$inject = ['$scope','$state','Reserves','$uibModalInstance','MessageService','Inventory','$stateParams'];

    function InventoryAddTwoController ($scope, $state,Reserves,$uibModalInstance,MessageService,Inventory,$stateParams) {
        var vm = this;
        //获取产品 、sku 仓库
        Reserves.getAllReserveByType({type:'accessories'},function(res){
            vm.reserves =res;
        });

        vm.flag=false;
        //获取记录数量
        $scope.getRecordNum = function(data){
            if(data ==null){
                vm.quantity='';
                vm.flag=false;
            }else{
                Reserves.getByWarehouseAndProductNumber({
                    warehouseId:vm.warehouse.warehouse.id,
                    propertyConfigId:vm.propertyConfig.id,
                    type:'accessories'
                },function(res){
                    vm.quantity = res.availableQuantity;
                    vm.flag=false;
                },function(error){
                    vm.quantity =0;
                    vm.flag=true;
                });
            }
        };

        $scope.validate = function() {
            if (vm.warehouse == null || vm.warehouse == "") {
                MessageService.error("请选择仓库");
                return false;
            };

            if (vm.material == null || vm.material == '') {
                MessageService.error('请选择辅料');
                return false;
            }
            if (vm.propertyConfig == null || vm.propertyConfig == "") {
                MessageService.error("请选择SKU");
                return false;
            };

            if (vm.quantity == 0) {
                MessageService.error("所选辅料无库存记录,无法保存");
                return false;
            };
            if (vm.num == null || vm.num == "") {
                MessageService.error("请输入调整数量");
                return false;
            };

            if (vm.reason == null || vm.reason == "") {
                MessageService.error("请输入调整原因");
                return false;
            };
            return true;
        };

        $scope.saveInventory = function(){
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            var obj={
                skuName:vm.propertyConfig.sku,
                type:vm.num > vm.quantity ?'in':'out',
                pageType:'accessories',
                num: vm.num > vm.quantity ? vm.num - vm.quantity : vm.quantity - vm.num,
                quantity:vm.quantity,
                warehouse:vm.warehouse.warehouse,
                reason:vm.reason,
                propertyConfig: {id: vm.propertyConfig.id}
            };

            Inventory.create(obj,function (res) {
                MessageService.success("盘点调整新建成功!");
                $uibModalInstance.close();
            },function (error) {
                vm.isSaving = false;
                MessageService.error("盘点调整新建失败！");
            });
        };

        $scope.cancelRecord = function(){
            $uibModalInstance.close();
        };

    }
})();
