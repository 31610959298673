(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MaterialsEditController', MaterialsEditController);

    MaterialsEditController.$inject = ['$scope','$state','MessageService', 'MaterialTemplates', 'Items', 'entity', '$stateParams', 'ProductCategory','$timeout'];

    function MaterialsEditController ($scope,$state,MessageService,MaterialTemplates, Items, entity, $stateParams, ProductCategory,$timeout) {
        var vm = this;
        vm.data = entity;
        vm.itemsList = Items.getAll();

        // accessories 辅料 / product 成品
        vm.typeList = [
            {name:'商品类', value: 'product'},
            {name:'辅材类', value: 'accessories'}
        ];

        //商品品类
        $timeout(function () {
            //商品品类
            ProductCategory.getAll({type: vm.data.type}, function (res) {
                vm.productCategoryList = res;
                vm.superiorName = null;
                for(var i=0; i<vm.productCategoryList.length; i++){
                    if(vm.productCategoryList[i].id == vm.data.productCategoryId && vm.productCategoryList[i].superiorId != "") {
                        vm.superiorName = vm.productCategoryList[i].superiorName;
                    }
                }
            });
            vm.superiorName = null;
        },100);

        $scope.typeSelect = function(value){
            if(!value){
                vm.productCategoryList = [];
            }else {
                vm.productCategoryList = ProductCategory.getAll({type: value, subordinate: "0"});
            }
        };

        //商品品类选择
        $scope.productCategorySelect = function(){
            vm.superiorName = null;
            for(var i=0; i<vm.productCategoryList.length; i++){
                if(vm.productCategoryList[i].id == vm.data.productCategoryId){
                    vm.data.productCategoryName = vm.productCategoryList[i].name;
                    if(vm.productCategoryList[i].superiorId){
                        vm.superiorName = vm.productCategoryList[i].superiorName;
                    }
                }
            }
        }

        //属性删除
        $scope.propertyDelete = function(index){
            vm.data.items.splice(index,1);
        };
        //属性新增
        $scope.propertyAdd = function(){
            vm.data.items.push({id: null});
        };
        $scope.itemSelect = function(data, index){
            for(var i=0; i<vm.itemsList.length; i++){
                if(data.id == vm.itemsList[i].id){
                    vm.data.items[index] = vm.itemsList[i];
                }
            }
        }

        $scope.save = function () {
            if(!vm.data.name){
                MessageService.error("名称不能为空");
                return;
            }
            if(vm.data.name.length >20){
                MessageService.error("名称不能大于20位");
                return;
            }
            if(!vm.data.type){
                MessageService.error("类型不能为空");
                return;
            }
            if(!vm.data.productCategoryId){
                MessageService.error("商品品类不能为空");
                return;
            }
            if(vm.data.description.length >50){
                MessageService.error("描述不能大于50位");
                return;
            }
            for(var i=0; i<vm.data.items.length; i++){
                if(!vm.data.items[i].id){
                    MessageService.error("属性不能为空");
                    return;
                }
            }
            vm.isSaving = true;
            MaterialTemplates.update(vm.data, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("编辑成功");
                $state.go("materials",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("编辑失败，请稍后重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("materials",{page: $stateParams.page});
        }
    }
})();
