(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('classify', {
                parent: 'module',
                url: '/classify',
                data: {
                    authorities: ['ROLE_CLASSIFY']
                },
                views: {
                    'content': {
                        templateUrl: 'app/product/classify.html',
                        controller: 'ClassifyController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('classifyAdd', {
                parent: 'module',
                url: '/classifyAdd',
                data: {
                    authorities: ['ROLE_CLASSIFY']
                },
                views: {
                    'content': {
                        templateUrl: 'app/product/classify-add.html',
                        controller: 'ClassifyAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('commodity', {
                parent: 'module',
                url: '/commodity',
                params:{page: null},
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/product/commodity.html',
                        controller: 'CommodityController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('commodityAdd', {
                parent: 'module',
                url: '/commodityAdd',
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/product/commodity-add.html',
                        controller: 'CommodityAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProductCategory', function($stateParams, ProductCategory) {
                        return ProductCategory.get();
                    }],
                }
            })
            .state('commodityEdit', {
                parent: 'module',
                url: '/commodityEdit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/product/commodity-edit.html',
                        controller: 'CommodityEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Product', function($stateParams, Product) {
                        return Product.validate({id: $stateParams.id});
                    }],
                }
            });
    }
})();
