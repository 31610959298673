(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SetImpositionController', SetImpositionController);

    SetImpositionController.$inject = ['$scope', 'entity','Principal', 'LoginService','$state','User','$uibModal','$uibModalInstance','MessageService', 'msgdata','Makeups'];

    function SetImpositionController ($scope, entity,Principal, LoginService,$state,User,$uibModal,$uibModalInstance,MessageService,msgdata,Makeups) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        loadAll();
        vm.items=[];

        function loadAll(){
            Makeups.getAllMakeups({},function(data){
                vm.items = data.filter(function(item){
                    return item.enable == true;
                });
                repeat(vm.items,msgdata);
            },function(error){
            });
        };

        //获取选中状态
        function repeat(items,list){
            if(list == null || list.length == 0){
                angular.forEach(items,function(item){
                    item.selected = false;
                })
            }else {
                for (var i = 0; i < items.length; i++) {
                    var item = items[i].name;
                    for (var j = 0; j < list.length; j++) {
                        if (item == list[j].name) {
                            items[i].selected = true;
                        }
                    }
                }
            }
        }

        function clear(){
            $uibModalInstance.dismiss();
        }

        $scope.chooseItem = function(item){
            item.selected = !item.selected;
        };

        function save () {
            vm.selectedItems = vm.items.filter(function(item){
                return item.selected;
            });
            $uibModalInstance.close(vm.selectedItems);
        }




    }
})();
