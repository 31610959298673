(function() {
    'use strict';

    angular
        .module('gwApp')
        .factory('Password', Password);

    Password.$inject = ['$resource'];

    function Password($resource) {
        var resourceUrl =  'api/account/:id';

        return $resource(resourceUrl, {}, {
            'change': { method: 'POST', isArray: true,
                params: {
                    id: 'change_password'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
