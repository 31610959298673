(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('unitMeasurement', {
                parent: 'module',
                url: '/unitMeasurement',
                params:{page: null},
                data: {
                    authorities: ['ROLE_UNIT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/unitMeasurement/unitMeasurement.html',
                        controller: 'UnitMeasurementController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('unitMeasurementAdd', {
                parent: 'module',
                url: '/unitMeasurementAdd',
                data: {
                    authorities: ['ROLE_UNIT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/unitMeasurement/unitMeasurement-add.html',
                        controller: 'UnitMeasurementAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('unitMeasurementEdit', {
                parent: 'module',
                url: '/unitMeasurementEdit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_UNIT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/unitMeasurement/unitMeasurement-edit.html',
                        controller: 'UnitMeasurementEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'UnitMeasurement', function($stateParams, UnitMeasurement) {
                        return UnitMeasurement.detail({id : $stateParams.id});
                    }]
                }
            });
    }
})();
