(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SupplyApiDetailsController', SupplyApiDetailsController);

    SupplyApiDetailsController.$inject = ['$scope', '$state','MessageService', 'SupplierAbutSettings','ProductCategory','Supplier','$q', '$stateParams','entity'];

    function SupplyApiDetailsController ($scope, $state,MessageService,SupplierAbutSettings,ProductCategory,Supplier,$q, $stateParams,entity) {
        var vm = this;
        vm.data = entity;
        vm.outwardMerchants=[
            {name:'亮丽',id:'LIANGLI'},
            {name:'天意有福',id:'TIANYIYOUFU'},
            {name:'goflow',id:'GOFLOW'}
        ];

        $scope.save = function () {
            if(!vm.data.name){
                MessageService.error("名称不能为空");
                return;
            }
            if(vm.data.name.length >20){
                MessageService.error("名称不能大于20位");
                return;
            }
            if(!vm.data.outwardMerchant){
                MessageService.error("接口类型不能为空");
                return;
            }
            if(!vm.data.factorySecret){
                MessageService.error("appSecret不能为空");
                return;
            }
            if(!vm.data.factoryId){
                MessageService.error("appid不能为空");
                return;
            }
            if(!vm.data.domain){
                MessageService.error("第三方域名不能为空");
                return;
            }
             
            vm.isSaving = true;
            if($stateParams.id){
                SupplierAbutSettings.update(vm.data, onSaveSuccess, onSaveError);
            }else {
                SupplierAbutSettings.save(vm.data, onSaveSuccess, onSaveError);
            }
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("成功");
                $state.go("supplyApi");
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("失败，请稍后重试！");
            }
        };
    }
})();
