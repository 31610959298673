(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SkuFileController', SkuFileController);

    SkuFileController.$inject = ['$scope', 'msgdata', '$uibModalInstance', 'MessageService'];

    function SkuFileController ($scope, msgdata, $uibModalInstance, MessageService) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.data = msgdata;

        vm.inputFileFormats = [
            {name:'zip',selected:false},
            {name:'jpg',selected:false},
            {name:'pdf',selected:false},
            {name:'png',selected:false},
        ]

        if(vm.data.inputFileFormat){
            angular.forEach(vm.inputFileFormats,function(item){
                if(vm.data.inputFileFormat.indexOf(item.name)!=-1){
                    item.selected = true;
                }
            });
        }

        function clear(){
            $uibModalInstance.dismiss();
        }

        $scope.fileFormatChange = function () {
            if(vm.data.outFileFormat=='PDF'){
                vm.data.outFileType = null;
                vm.data.crossPageType = null;
            }else{
                vm.data.flatten = false;
            }
        }

        $scope.outFileTypeChange = function () {
            if(vm.data.outFileType=='Only'){
                vm.data.crossPageType = null;
            }
        }

        $scope.validate = function () {
            vm.data.inputFileFormat = null;
            angular.forEach(vm.inputFileFormats,function(item){
                if(item.selected){
                    if(vm.data.inputFileFormat == null){
                        vm.data.inputFileFormat = item.name;
                    }else{
                        vm.data.inputFileFormat=vm.data.inputFileFormat+','+item.name;
                    }
                }
            });
            if(vm.data.inputFileFormat == null){
                MessageService.error("输入文件类型不能为空");
                return;
            }

            if(vm.data.outFileFormat =='' || vm.data.outFileFormat == null){
                MessageService.error("输出文件类型不能为空");
                return;
            }

            if(vm.data.outFileFormat == 'PNG'||vm.data.outFileFormat == 'JPG'){
                vm.data.flatten = false;
                if(vm.data.outFileType =='' || vm.data.outFileType == null){
                    MessageService.error("图片文件样式不能为空");
                    return;
                }
                if(vm.data.imgDpi =='' || vm.data.imgDpi == null){
                    MessageService.error("图片dpi尺寸不能为空");
                    return;
                }
                if(vm.data.outFileType == 'Page'&&(vm.data.crossPageType =='' || vm.data.crossPageType == null)){
                    MessageService.error("跨页方式不能为空");
                    return;
                }
            }else{
                vm.data.crossPageType = null;
                vm.data.outFileType = null;
            }

            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            $uibModalInstance.close(vm.data);
        }
    }
})();
