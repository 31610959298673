(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('MetaTags', MetaTags);

    MetaTags.$inject = ['$resource'];

    function MetaTags ($resource) {
        var resourceUrl =  'api/meta-tags/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                params: {
                    path: 'pages'
                },
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                params:{
                    path: 'list'
                },
                isArray: true
            } ,
            'delete':{method:'DELETE'},
            'add': { method:'POST' } ,
            'update': { method:'PUT' },
            'detail': {
                method: 'GET',
                params: {
                    path: 'getOne'
                }
            },
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray:true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray:true
            },
        });
    }
})();
