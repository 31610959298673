(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('TaskAllocationController', TaskAllocationController);

    TaskAllocationController.$inject = ['$scope', 'Principal', '$state','User','MessageService','Product', 'ParseLinks', '$uibModal', 'SalesOrder', '$timeout', '$stateParams'];

    function TaskAllocationController ($scope, Principal, $state,User,MessageService, Product, ParseLinks, $uibModal, SalesOrder, $timeout, $stateParams) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_PRODUCT').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有商品访问的权限！");
                $state.go("home");
            }
        });

        vm.isTableState = false;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.selection = [
            {name:'全部', value:'all'},
            {name:'待分配', value:'ToAssigned'},
            {name:'已分配', value:'Assigned'},
            {name:'已回传', value:'Uploaded'}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: ['createdDate,DESC'],
            keyword: '',
            status:'all'
        };

        //获取选中的数组
        $scope.checked =[];
        $scope.selectOne = function(data){
            var index = $scope.checked.indexOf(data);
            if(index == -1 && data.checked){
                $scope.checked.push(data);
            }else if (!data.checked && index !== -1){
                $scope.checked.splice(index, 1);
            }
            if (vm.tableList.length === $scope.checked.length) {
                $scope.select_all = true;
            } else {
                $scope.select_all = false;
            }
        };

        $scope.selectAll = function(){
            if($scope.select_all) {
                angular.forEach(vm.tableList, function (item) {
                    item.checked = true;
                });
                $scope.checked = vm.tableList.map(function(value){
                    return value;
                })
            }else {
                angular.forEach(vm.tableList, function (item) {
                    item.checked = false;
                });
                $scope.checked = [];
            }
        };

        //点击分配 可多选 最终只取待分配的订单
        $scope.distribute = function(){
            var existing = $scope.checked.filter(function(item){
                return item.composingStatus =='Assigned'|| item.composingStatus =='Uploaded';
            });
            if(existing.length >0){
                MessageService.error('请选择待分配的订单分配！')
            }else if($scope.checked.length==0){
                MessageService.error('请选择列表项');
            }else{
                $uibModal.open({
                    templateUrl: 'app/task/task-distribute.html',
                    controller: 'TaskDistributeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            return $translate.refresh();
                        }],
                        msgdata: function() {
                            return $scope.checked;
                        }
                    }
                }).result.then(function(result){
                    angular.forEach(vm.tableList, function (item) {
                        item.checked = false;
                    });
                    init();
                });
            }
        };


        //点击备注  单选
        $scope.addRemark = function(){
            if($scope.checked.length==0){
                MessageService.error('请选择列表项');
            }else if($scope.checked.length>1){
                MessageService.error('请选择一项添加备注');
            }else{
                $uibModal.open({
                    templateUrl: 'app/task/remark.html',
                    controller: 'RemarkController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            return $translate.refresh();
                        }],
                        msgdata: function() {
                            return $scope.checked;
                        }
                    }
                }).result.then(function(result){
                    angular.forEach(vm.tableList, function (item) {
                        item.checked = false;
                    });
                    init();
                });
            }
        };


        var _h = angular.element(".table-top").find(".table-state").height();
        if(_h > 40){
            vm.isTableState = true;
        }else {
            vm.isTableState = false;
        }

        init();
        function init() {
            vm.tableList = [];
            $scope.checked = [];
            $scope.select_all = false;
            SalesOrder.getOrderTask(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        }

        $scope.conditionSelect = function(data){
            vm.tableData.status = data.value;
            vm.tableData.page = 0;
            init();
        };

        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };

        $scope.tableStateBtn = function () {
            var _h = angular.element(".table-top").height();
            if(_h > 40){
                angular.element(".table-top").height("40");
                angular.element(".table-state-btn").removeClass("table-state-btnOn");
            }else {
                angular.element(".table-top").height("auto");
                angular.element(".table-state-btn").addClass("table-state-btnOn");
            }
        };

        window.onresize = function(){
            var _h = angular.element(".table-top").find(".table-state").height();
            $timeout(function () {
                if(_h > 40){
                    vm.isTableState = true;
                }else {
                    vm.isTableState = false;
                }
            })
        }
    }

})();
