(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SortingEditController', SortingEditController);

    SortingEditController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'Sorting','entity', '$stateParams', 'Channels'];

    function SortingEditController ($scope, Principal, LoginService, $state,User,MessageService,Sorting,entity, $stateParams, Channels) {
        var vm = this;
        vm.data = entity;
        console.log(vm.data)
        Channels.getAll({}, function(res) {
            vm.channelList = res;
            for(var i=0; i<res.length; i++){
                if(res[i].id == vm.data.channelId){
                    vm.channel = res[i];
                }
            }
        });
        $scope.channelSelect = function () {
            vm.data.channelId = vm.channel.id;
            vm.data.channelName = vm.channel.name;
        }
        $scope.save = function () {
            if(!vm.data.merchantId){
                MessageService.error("所属渠道不能为空");
                return;
            }
            if(!vm.data.channelName){
                MessageService.error("商户唯一标识不能为空");
                return;
            }
            if(vm.data.channelName.length >20){
                MessageService.error("商户唯一标识不能大于20位");
                return;
            }
            vm.isSaving = true;
            Sorting.update(vm.data, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("编辑成功");
                $state.go("sorting",{page: $stateParams.page});
            }

            function onSaveError (error) {
                vm.isSaving = false;
                MessageService.error(error.data.message);
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("sorting",{page: $stateParams.page});
        }
    }
})();
