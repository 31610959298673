(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CustomerHubAmountController', CustomerHubAmountController);

    CustomerHubAmountController.$inject = ['$scope','$state','PayOrder','msgdata', '$uibModalInstance','MessageService'];

    function CustomerHubAmountController ($scope,$state,PayOrder, msgdata ,$uibModalInstance,MessageService) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.amount = 0;
        vm.customer = msgdata;

        vm.orders = PayOrder.getAllByCondition({items:[
            {key:"invoiced",value:"false", op:"="}, 
            {key:"paid",value:"true", op:"="}, 
            {key:"refund",value:"false", op:"="}]}, function(data){
                if (data && data.length >0) {
                   for (var i = data.length - 1; i >= 0; i--) {
                       vm.amount = vm.amount + data[i].amount;
                    } 
                }
            })

        function clear(){
            $uibModalInstance.dismiss();
        }

        $scope.validate = function () {
            if (vm.balance == null || vm.balance == "") {
                MessageService.error("退款金额不能为空");
                return false;
            }
            if (vm.balance <0.01) {
                MessageService.error("退款金额不能小于0.01");
                return false;
            }
            if (vm.amount < vm.balance) {
                MessageService.error("退款金额不能大于"+ vm.amount);
                return false;
            }
            return true;
        };

        function save () {
            if (!$scope.validate()) {
                return;
            }

            //plus充值/minus消费
            PayOrder.refund({
                customerId: msgdata.id,
                amount: vm.balance,
                type: 'balance'
            }, function (res) {
                vm.isSaving = false;
                    MessageService.success("退款成功");
                    $uibModalInstance.close(res);
            }, function (error) {
                vm.isSaving = false;
                MessageService.error("退款失败");
            });
        }

    }
})();
