(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ReplenishmentReasonEditController', ReplenishmentReasonEditController);

    ReplenishmentReasonEditController.$inject = ['$scope', '$state','MessageService', 'ReplenishmentReason', 'entity', '$stateParams'];

    function ReplenishmentReasonEditController ($scope,$state,MessageService,ReplenishmentReason, entity, $stateParams) {
        var vm = this;
        vm.replenishmentReason = entity;
        vm.belongs =[
            {name:'平台',value:'platform'},
            {name:'供应商',value:'supplier'}
        ];

        $scope.save = function () {
            if(!vm.replenishmentReason.reason){
                MessageService.error("原因不能为空");
                return;
            }
            if(vm.replenishmentReason.reason.length >20){
                MessageService.error("原因不能大于20位");
                return;
            }
            if(!vm.replenishmentReason.remarks){
                MessageService.error("描述不能为空");
                return;
            }
            if(vm.replenishmentReason.remarks >50){
                MessageService.error("描述不能大于50位");
                return;
            }
            if(!vm.replenishmentReason.type){
                MessageService.error("所属不能为空");
                return;
            }
            vm.isSaving = true;
            ReplenishmentReason.update(vm.replenishmentReason, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("修改成功");
                $state.go("replenishmentReason",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("修改失败，请稍后重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("replenishmentReason",{page: $stateParams.page});
        }
    }
})();
