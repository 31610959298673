(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('GoodsShelves', GoodsShelves);

    GoodsShelves.$inject = ['$resource'];

    function GoodsShelves ($resource) {
        var resourceUrl =  'api/goods-shelves/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'page'
                }
            },
            'create': {
                method: 'POST',
                params: {
                    path: 'create'
                }
            },
            'createBatch': {
                method: 'POST',
                isArray: true,
                params: {
                    path: 'create-batch'
                }
            },
            'getAll': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'getAll'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'delete':{method:'DELETE'},
            'update': { method:'PUT' },
            'detail': {method:'GET'}
        });
    }
})();
