(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('InvoicingDetailController', InvoicingDetailController);

    InvoicingDetailController.$inject = ['$scope', 'Principal','entity', '$stateParams', '$state','$uibModal','Invoices','MessageService','CustomerHub','$q','SalesOrder','PayOrders'];

    function InvoicingDetailController ($scope, Principal, entity, $stateParams, $state,$uibModal,Invoices,MessageService,CustomerHub,$q,SalesOrder,PayOrders) {
        var vm = this;

        vm.invoice = entity;
        vm.salesOrders = [];
        vm.payOrders = [];
        $q.all([vm.invoice.$promise]).then(function(){
            console.log(vm.invoice)
            vm.customerHub = CustomerHub.get({id: vm.invoice.customerHubId});
            // if(vm.invoice)
            angular.forEach(vm.invoice.invoiceOrders,function (order) {
                if(order.invoiceOrderType=='SALESORDER'){
                    SalesOrder.get({id:order.orderId},function (res) {
                        vm.salesOrders.push(res);
                    })
                }else{
                    PayOrders.get({id:order.orderId},function (res) {
                        vm.payOrders.push(res);
                    })
                }
            })
        })

        //返回
        $scope.goBack = function () {
            $state.go("invoicing",{page: $stateParams.page});
        };

        $scope.approve = function(){
            Invoices.approve({id: vm.invoice.id}, function (res) {
                MessageService.success('已通过');
                vm.invoice = res;
            })
        }

        $scope.operate = function(attr){
            $uibModal.open({
                templateUrl: 'app/customerHub/invoicing-operate.html',
                controller: 'InvoicingOperateController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return {
                            title:attr,
                            data:vm.invoice
                        }
                    }
                }
            }).result.then(function(result){
                console.log(result);
                vm.invoice.invoiceStatus = 'REJECTED';
                vm.invoice.remark = result.remark;
                // vm.invoice = result;
                // $scope.$digest()
            });
        }

    }
})();
