(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('proofRule', {
                parent: 'module',
                url: '/proofRule',
                params:{page: null},
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/proofRule/proofRule.html',
                        controller: 'ProofRuleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('proofRuleAdd', {
                parent: 'module',
                url: '/proofRuleAdd',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/proofRule/proofRule-edit.html',
                        controller: 'ProofRuleEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity:  function(){
                        return {
                            dpi:300
                        };
                    }
                }
            })
            .state('proofRuleEdit', {
                parent: 'module',
                url: '/proofRuleEdit/{id}',
                params:{page: null},
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/proofRule/proofRule-edit.html',
                        controller: 'ProofRuleEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProofRule', function($stateParams, ProofRule) {
                        return ProofRule.get({id : $stateParams.id});
                    }],
                }
            })

        ;
    }
})();
