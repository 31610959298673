(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CustomerHubController', CustomerHubController);

    CustomerHubController.$inject = ['$scope', 'Principal', 'LoginService', '$state','CustomerHub','MessageService', 'ParseLinks', '$stateParams'];

    function CustomerHubController ($scope, Principal, LoginService, $state,CustomerHub,MessageService, ParseLinks, $stateParams) {
        var vm = this;

        vm.categories =[{
            name:'outsource',
            value:'委外'
        },{
            name:'SourceMaterials',
            value:'原辅材料'
        }];
        vm.loadAll = loadAll;
        vm.clear =clear;

        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            keyword:''
        };

        loadAll();
        function loadAll() {
            vm.customerHubs = [];
            CustomerHub.page(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                vm.customerHubs = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            loadAll();
                        }
                    }
                });
            }, function (error) {

            });
        }

        $scope.search =function(){
            vm.tableData.page = 0;
            loadAll();
        };

        function clear(){
            vm.tableData.keyword = null;
            vm.tableData.page = 0;
            loadAll();
        }

        $scope.deleteCustomerHub = function(id){
            MessageService.confirm("确认要删除该客户？", function () {
                CustomerHub.delete({id: id}, function () {
                    loadAll();
                    MessageService.success("客户删除成功");
                }, function (error) {
                    MessageService.error("客户删除失败，请重试！");
                });
            });
        };

    }
})();
