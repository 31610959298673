(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('RemarkController', RemarkController);

    RemarkController.$inject = ['$scope','$state','msgdata','SalesOrder','$uibModalInstance','MessageService'];

    function RemarkController ($scope,$state,msgdata,SalesOrder,$uibModalInstance,MessageService) {
        var vm = this;

        console.log(msgdata);
        vm.remark =msgdata[0].serviceRemarks;

        $scope.assignRemark = function(){
            if(!vm.remark){
                MessageService.error('备注不能为空');
                return;
            }
            SalesOrder.addRemarks({
                orderId:msgdata[0].id,
                remarks:vm.remark
            },function(res){
                MessageService.success('添加备注成功！');
                $uibModalInstance.close();
            },function(error){
                MessageService.error('添加备注失败');
            });
        };

        $scope.cancelRemark = function(){
            $uibModalInstance.close();
        };
    }
})();
