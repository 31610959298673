(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PurchaseOrderEditController', PurchaseOrderEditController);

    PurchaseOrderEditController.$inject = ['$scope','$state', '$q', 'Material', 'MessageService', 'Reserves', 'PurchaseProductLine', 'Supplier', 'Depository', 'UnitMeasurement', 'Product', 'PurchaseOrder', 'entity'];

    function PurchaseOrderEditController ($scope, $state, $q, Material, MessageService, Reserves, PurchaseProductLine, Supplier, Depository, UnitMeasurement, Product, PurchaseOrder, entity) {
        var vm = this;
        vm.productAdd = productAdd;
        vm.materialAdd = materialAdd;
        vm.materialDelete = materialDelete;
        vm.productDelete = productDelete;
        vm.getWarehouse = getWarehouse;

        console.log(entity);
        vm.purchaseOrder = entity;
        // vm.purchaseOrder = PurchaseOrder.get({id: entity.id});
        $q.all([vm.purchaseOrder.$promise]).then(function(){
            console.log(vm.purchaseOrder);
            vm.types = [
                {name:'成品采购', value:'Outsourcing'},
                {name:'材料采购', value:'RawMaterial'}
            ];
            init();
        });


        $scope.selectStatus = function(data){
            if(data =='Outsourcing'){
                vm.warehousis = Depository.getAll({type: 'product'});
            }else if(data =='RawMaterial'){
                vm.warehousis = Depository.getAll({type: "accessories"});
            }else{
                vm.warehousis = Depository.getAll({type: null});
            }
        };
        
        function init() {
            vm.suppliers = Supplier.getAll();
            vm.warehousis2 = Depository.getAll({type: "accessories"});
            vm.products = Product.getAll();
            vm.materials = Material.getAll();
            
            if(vm.purchaseOrder.status =='Outsourcing'){
                vm.warehousis = Depository.getAll({type: 'product'});
            }else if(vm.purchaseOrder.status =='RawMaterial'){
                vm.warehousis = Depository.getAll({type: "accessories"});
            }else{
                vm.warehousis = Depository.getAll({type: null});
            }

            vm.deliveryDate = vm.purchaseOrder.deliveryDate == null ? null : vm.purchaseOrder.deliveryDate.substr(0, 10);
            if (vm.purchaseOrder.warehouse.type == 'product') {
                vm.pageType = '成品库';
            } else {
                vm.pageType = '';
            }
        }

        $scope.getInventory = function(warehouse, propertyConfigId, index) {
            if (warehouse == undefined || propertyConfigId == undefined) {
                vm.purchaseOrder.purchaseMaterialLines[index].num = 0;
                return;
            }
            Reserves.getByWarehouseAndProductNumber({warehouseId: warehouse, propertyConfigId: propertyConfigId, type: 'accessories'}, function(date) {
                vm.purchaseOrder.purchaseMaterialLines[index].num = date.usableQuantity;
            });
        };

        //去掉选中入库之后的sku null
        $scope.selectPurchaseProduct = function(data){
            if(data.product ==null){
                data.product.propertyConfigs =[];
            }
        };
        $scope.selectPurchaseMaterial = function(data){
            if(data.material ==null){
                data.material.propertyConfigs =[];
            }
        };

        function productAdd() {
            if (vm.purchaseOrder.supplier == null) {
                MessageService.error('请选择供应商');
                return;
            }
            if (vm.purchaseOrder.purchaseProductLines == undefined) {
                vm.purchaseOrder.purchaseProductLines = [];
            }
            //var propertyConfig = {id: null, sku:null}
            var purchaseProductLine = {
                product: {
                    id: null,
                    name: null,
                    propertyConfigs: []},
                material: {
                    id: null,
                    name: null,
                    propertyConfigs: []},
                measurement: {
                    id: null,
                    name: null},
                num: 0,
                price: 0,
                tax: vm.purchaseOrder.supplier.taxRate,
                warehouse: {
                    id: null,
                    name: null},
                propertyConfig:{id: null}
            };
            //purchaseProductLine.product.propertyConfigs.push(propertyConfig);
            vm.purchaseOrder.purchaseProductLines.push(purchaseProductLine);
        }

        function materialAdd() {
            if (vm.purchaseOrder.purchaseMaterialLines == undefined) {
                vm.purchaseOrder.purchaseMaterialLines = [];
            }
            //var propertyConfig = {id: null, sku:null}
            var purchaseMaterialLine = {
                product: {
                    id: null,
                    name: null,
                    propertyConfigs: []},
                measurement: {
                    id: null,
                    name: null},
                num: 0,
                price: 0,
                warehouse: {
                    id: null,
                    name: null}
            };
            //purchaseMaterialLine.product.propertyConfigs.push(propertyConfig);
            vm.purchaseOrder.purchaseMaterialLines.push(purchaseMaterialLine);
        }

        function materialDelete(index) {
            vm.purchaseOrder.purchaseMaterialLines.splice(index, 1);
        }

        function productDelete(index) {
            vm.purchaseOrder.purchaseProductLines.splice(index, 1);
        }

        // 默认入库，需要考虑商品的类型
        function getWarehouse(warehouse) {
            if(warehouse ==null){}else{
                // console.log(warehouse.type);
                if (warehouse.type == 'product') {
                    vm.pageType = '成品库';
                    // vm.products = Product.getAllByCategory({type: 'StandardProduct'});
                }
                // console.log(vm.products);
                vm.purchaseOrder.address = warehouse.province + warehouse.district + warehouse.city + warehouse.address;
            }
        }

        function getDate(strDate) {
            var st = strDate;
            var a = st.split(" ");
            var b = a[0].split("-");
            var c = a[1].split(":");
            var date = new Date(b[0], b[1] - 1, b[2], c[0], c[1], c[2]);
            return date;
        }

        $scope.saveNum = function(){
            if (vm.purchaseOrder.supplier == null) {
                MessageService.error("请选择供应商");
                return false;
            }
            if (vm.purchaseOrder.status == null || vm.purchaseOrder.status == '') {
                MessageService.error("请选择采购类型");
                return false;
            }

            var deliveryDate = getDate($('#startDate').val() + " 23:59:59");
            vm.purchaseOrder.deliveryDate = deliveryDate;
            // console.log(vm.purchaseOrder.deliveryDate);
            if (vm.purchaseOrder.deliveryDate == null || vm.purchaseOrder.deliveryDate == '') {
                MessageService.error("请选择交货日期");
                return false;
            }
            if (vm.purchaseOrder.warehouse == null) {
                MessageService.error("请选择默认入库");
                return false;
            }
            if (vm.purchaseOrder.contacts == null || vm.purchaseOrder.contacts == '') {
                MessageService.error("请输入联系人");
                return false;
            }
            if (vm.purchaseOrder.phone == null || vm.purchaseOrder.phone == '') {
                MessageService.error("请输入联系电话");
                return false;
            }
            if (vm.purchaseOrder.address == null || vm.purchaseOrder.address == '') {
                MessageService.error("请输入收货地址");
                return false;
            }
            if (vm.purchaseOrder.freight == null || vm.purchaseOrder.freight == '') {
                MessageService.error("请输入运费");
                return false;
            }

            if (vm.purchaseOrder.purchaseProductLines != null && vm.purchaseOrder.purchaseProductLines.length > 0) {
                for (var i = 0; i < vm.purchaseOrder.purchaseProductLines.length; i++) {
                    var purchaseProductLine = vm.purchaseOrder.purchaseProductLines[i];
                    if (vm.pageType == '成品库') {
                        if (purchaseProductLine.product.id == null || purchaseProductLine.propertyConfig.id == null) {
                            MessageService.error("商品信息中的商品选择不完整");
                            return false;
                        }
                    } else {
                        if (purchaseProductLine.material.id == null || purchaseProductLine.propertyConfig.id == null) {
                            MessageService.error("商品信息中的商品选择不完整");
                            return false;
                        }
                    }
                    if (purchaseProductLine.num == null || purchaseProductLine.num <= 0) {
                        MessageService.error("请输入商品信息中的数量");
                        return false;
                    }
                    if (purchaseProductLine.price == null || purchaseProductLine.price <= 0) {
                        MessageService.error("请输入商品信息中的单价");
                        return false;
                    }

                    vm.purchaseOrder.purchaseProductLines[i].tax = vm.purchaseOrder.supplier.taxRate;
                    if (purchaseProductLine.tax == null || purchaseProductLine.tax <= 0) {
                        MessageService.error("请输入商品信息中的税率");
                        return false;
                    }

                    vm.purchaseOrder.purchaseProductLines[i].total = (purchaseProductLine.num * purchaseProductLine.price).toFixed(2);
                    vm.purchaseOrder.purchaseProductLines[i].taxPrice = (purchaseProductLine.price * purchaseProductLine.tax / 100) + purchaseProductLine.price;
                    vm.purchaseOrder.purchaseProductLines[i].taxTotal = (((purchaseProductLine.price * purchaseProductLine.tax / 100) + purchaseProductLine.price) * purchaseProductLine.num).toFixed(2);
                }
            } else {
                MessageService.error("请输入商品信息");
                return false;
            }

            if (vm.purchaseOrder.provideMaterials) {
                if (vm.purchaseOrder.purchaseMaterialLines != null && vm.purchaseOrder.purchaseMaterialLines.length > 0) {
                    console.log(vm.purchaseOrder.purchaseMaterialLines);
                    for (var i = 0; i < vm.purchaseOrder.purchaseMaterialLines.length; i++) {
                        var purchaseMaterialLine = vm.purchaseOrder.purchaseMaterialLines[i];
                        if (purchaseMaterialLine.material.id == null || purchaseMaterialLine.propertyConfig.id == null) {
                            MessageService.error("请选择供料信息中的商品");
                            return false;
                        }
                        if (purchaseMaterialLine.warehouse.id == null) {
                            MessageService.error("请选择来源仓库");
                            return false;
                        }
                        if (purchaseMaterialLine.num == null || purchaseMaterialLine.num <= 0) {
                            MessageService.error("请输入供料信息中的数量");
                            return false;
                        }
                    }
                } else {
                    MessageService.error("请输入供料信息");
                    return false;
                }
            }

            vm.isSaving = true;
            console.log(vm.purchaseOrder);
            if (!vm.purchaseOrder.provideMaterials) {
                vm.purchaseOrder.purchaseMaterialLines = null;
            }
            PurchaseOrder.save(vm.purchaseOrder, function(res){
                vm.isSaving = false;
                MessageService.success('新建采购成功！');
                $state.go("purchaseProductLine");
            },function(error){
                vm.isSaving = false;
                MessageService.error('新建采购失败');
            });
        };

        $scope.cancelSave = function() {
            $state.go("purchaseProductLine");
        }

        $scope.getSupplier = function(supplier) {
            vm.taxRate = vm.purchaseOrder.supplier.taxRate;
        }
    }
})();
