(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SortingAddController', SortingAddController);

    SortingAddController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'Sorting', 'entity'];

    function SortingAddController ($scope, Principal, LoginService, $state,User,MessageService,Sorting, entity) {
        var vm = this;
        vm.channelList = entity;
        vm.channel = "";
        vm.data = {
            merchantId: '',
            channelName: '',
            enable: true
        };
        vm.isSaving = false;

        $scope.save = function () {
            if(!vm.data.merchantId){
                MessageService.error("所属渠道不能为空");
                return;
            }
            if(!vm.data.channelName){
                MessageService.error("商户唯一标识不能为空");
                return;
            }
            if(vm.data.channelName.length >20){
                MessageService.error("商户唯一标识不能大于20位");
                return;
            }
            vm.isSaving = true;
            Sorting.add(vm.data, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("添加成功");
                $state.go("sorting");
            }

            function onSaveError (error) {
                vm.isSaving = false;
                MessageService.error(error.data.message);
            }
        };

        $scope.channelSelect = function () {
            vm.data.channelId = vm.channel.id;
            vm.data.channelName = vm.channel.name;
        }
    }
})();
