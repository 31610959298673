(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MakeupEditController', MakeupEditController);

    MakeupEditController.$inject = ['$scope','$state','MessageService', 'Makeup', 'entity', '$stateParams'];

    function MakeupEditController ($scope, $state,MessageService,Makeup, entity, $stateParams) {
        var vm = this;
        //vm.ffcFileList = [
        //    {name:'13拼', value:'13拼'},
        //    {name:'6拼', value: '6拼'}
        //];
        vm.makeup = entity;

        $scope.save = function () {
            if(!vm.makeup.name){
                MessageService.error("名称不能为空");
                return;
            }
            if(vm.makeup.name.length >20){
                MessageService.error("名称不能大于20位");
                return;
            }
            if(!vm.makeup.channel){
                MessageService.error("拼版通道名称不能为空");
                return;
            }
            if(vm.makeup.channel.length >20){
                MessageService.error("拼版通道名称不能大于20位");
                return;
            }
            // if(!vm.makeup.collectionNum){
            //     MessageService.error("收集数量不能为空");
            //     return;
            // }
            if(!vm.makeup.description){
                MessageService.error("描述不能为空");
                return;
            }
            if(vm.makeup.description.length >50){
                MessageService.error("描述不能大于50位");
                return;
            }
            vm.isSaving = true;
            Makeup.update(vm.makeup, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("修改成功");
                $state.go("makeup",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("修改失败，请稍后重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("makeup",{page: $stateParams.page});
        }
    }
})();
