(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('ExpressApiSetting', ExpressApiSetting)

    ExpressApiSetting.$inject = ['$resource'];

    function ExpressApiSetting ($resource) {
        var resourceUrl =  'api/express-api-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            get:{
                method:'GET',
                params: {
                    path: 'get'
                },
            },
            'update': { method:'POST' },
        });
    }
})();
