(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MerchantEditController', MerchantEditController);

    MerchantEditController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'Merchant', 'entity', '$stateParams',
     'Channels', 'CustomerHub', 'LocationProvice', 'LocationCity', 'LocationDistrict', '$q' ];

    function MerchantEditController ($scope, Principal, LoginService, $state,User,MessageService,Merchant, entity, $stateParams,
     Channels, CustomerHub, LocationProvice, LocationCity, LocationDistrict, $q) {
        var vm = this;
        vm.merchant = entity;
        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;

        Channels.getAll({}, function(res) {
            vm.channelList = res;
        });

        CustomerHub.getAll({}, function(res) {
            vm.customerHubList = res;
        });

        $q.all([vm.merchant.$promise]).then(function(){
            loadData();
        })

        
        
        function loadData() {
            LocationProvice.query({},function (data) {
                vm.provinces =  data;
                if(vm.merchant.province) {
                    for (var i = vm.provinces.length - 1; i >= 0; i--) {
                        var p = vm.provinces[i];
                        if (p.name == vm.merchant.province) {
                            vm.province = p;
                            break;
                        }
                    }
                    if (vm.merchant.city != '') {
                        vm.districts = [];
                        LocationCity.findByProvince({provinceId: vm.province.id}, function (data) {
                            vm.citys = data;
                            for (var i = vm.citys.length - 1; i >= 0; i--) {
                                var c = vm.citys[i];
                                if (c.name == vm.merchant.city) {
                                    vm.city = c;
                                    break;
                                }
                            }
                            if (vm.city != null) {
                                LocationDistrict.findByCities({cityId: vm.city.id}, function (data) {
                                    vm.districts = data;
                                    for (var i = vm.districts.length - 1; i >= 0; i--) {
                                        var di = vm.districts[i];
                                        if (di.name == vm.merchant.district) {
                                            vm.district = di;
                                        }
                                    }
                                });
                            }
                        });
                    }
                }
            });

        }

        function changeProvince() {
            LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                vm.citys = data;
                vm.districts = [];
            });
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
            });
        }
        $scope.save = function () {
            if (vm.merchant.shipment) {
                if (vm.province == null || vm.province == "") {
                    MessageService.error("请选择省份");
                    return false;
                };
                vm.merchant.province = vm.province.name;

                if (vm.city == null || vm.city == '') {
                    MessageService.error('请选择城市');
                    return false;
                }
                vm.merchant.city = vm.city.name;

                if (vm.district == null || vm.district == "") {
                    MessageService.error("请选择区县");
                    return false;
                };
                vm.merchant.district = vm.district.name;

                if (vm.merchant.address == null || vm.merchant.address == "") {
                    MessageService.error("请输入详细地址");
                    return false;
                };

                if (vm.merchant.address.length >50) {
                    MessageService.error("详细地址不能大于50位");
                    return false;
                };

                if (vm.merchant.contact == null || vm.merchant.contact == "") {
                    MessageService.error("请输入联系人姓名");
                    return false;
                };
                if (vm.merchant.contact.length >20) {
                    MessageService.error("联系人姓名不能超过20位");
                    return false;
                };
                if (vm.merchant.phone == null || vm.merchant.phone == "") {
                    MessageService.error("请输入联系方式");
                    return false;
                };

            }
            if(!vm.merchant.name){
                MessageService.error("商户名不能为空");
                return;
            }
            if(vm.merchant.name.length >20){
                MessageService.error("商户名不能超过20位");
                return;
            }
            if(!vm.merchant.number){
                MessageService.error("商户号不能为空");
                return;
            }
            if(vm.merchant.number.length >20){
                MessageService.error("商户号不能超过20位");
                return;
            }
            if(!vm.merchant.sortingTips){
                MessageService.error("分拣提示不能为空");
                return;
            }
            if(vm.merchant.sortingTips.length >20){
                MessageService.error("分拣提示不能超过20位");
                return;
            }
            if (!vm.merchant.channelId && !vm.merchant.customerHubId) {
                MessageService.error("请选择所属对象，至少选一个");
                return;
            }
            vm.isSaving = true; 
            Merchant.update(vm.merchant, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("修改成功");
                $state.go("merchant",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("修改失败，请稍后重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("merchant",{page: $stateParams.page});
        }
    }
})();
