(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ZoneDetailController', ZoneDetailController);

    ZoneDetailController.$inject = ['$scope','msgdata','$uibModalInstance','MessageService','Provinces','$q','LocationProvice'];

    function ZoneDetailController ($scope,msgdata,$uibModalInstance,MessageService,Provinces,$q,LocationProvice) {
        var vm = this;
        vm.clear = clear;
        vm.areas =[];

        loadData();
        function loadData() {
            // Provinces.getAll({},function(res){
            //     vm.areas = res;
            //     $q.all([vm.areas.$promise]).then(function(){
            //         if(msgdata == null || msgdata.length ==0){
            //             angular.forEach(vm.areas,function(item){
            //                 item.selected = false;
            //                 angular.forEach(item.provinces,function(value){
            //                     value.selected = false;
            //                 })
            //             })
            //         }else{
            //             angular.forEach(vm.areas,function(item){
            //                 angular.forEach(msgdata,function(value){
            //                     if(item.area == value.area && item.provinces.length == value.provinces.length){
            //                         item.selected = true;
            //                     }
            //                     angular.forEach(item.provinces,function(item1){
            //                         angular.forEach(value.provinces,function(value2){
            //                             if(item1.name == value2.name){
            //                                 item1.selected = true;
            //                             }
            //                         })
            //                     })
            //                 })
            //             });
            //             console.log(vm.areas)
            //         }
            //     })
            // });

            LocationProvice.query({},function (data) {
                vm.areas = arrInit(data);
                $q.all([vm.areas.$promise]).then(function(){
                    if(msgdata == null || msgdata.length ==0){
                        angular.forEach(vm.areas,function(item){
                            item.selected = false;
                            angular.forEach(item.provinces,function(value){
                                value.selected = false;
                            })
                        })
                    }else{
                        angular.forEach(vm.areas,function(item){
                            angular.forEach(msgdata,function(value){
                                if(item.area == value.area && item.provinces.length == value.provinces.length){
                                    item.selected = true;
                                }
                                angular.forEach(item.provinces,function(item1){
                                    angular.forEach(value.provinces,function(value2){
                                        if(item1.name == value2.name){
                                            item1.selected = true;
                                        }
                                    })
                                })
                            })
                        });
                    }
                    console.log(vm.areas)
                })
            });
        }

        function clear(){
            $uibModalInstance.dismiss();
        }

        function arrInit(data){
            var map = {},
                provinces = [];
            for(var i = 0; i < data.length; i++){
                var ai = data[i];
                if(!map[ai.area]){
                    provinces.push({
                        area: ai.area,
                        provinces: [ai]
                    });
                    map[ai.area] = ai;
                }else{
                    for(var j = 0; j < provinces.length; j++){
                        var dj = provinces[j];
                        if(dj.area == ai.area){
                            dj.provinces.push(ai);
                            break;
                        }
                    }
                }
            }
            return provinces;
        }
    }
})();
