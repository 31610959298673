(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderRemarkController', OrderRemarkController);

    OrderRemarkController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'ParseLinks', 'SalesOrder','$uibModalInstance'];

    function OrderRemarkController ($scope, Principal, LoginService, $state,User,MessageService, ParseLinks, SalesOrder, $uibModalInstance) {
        var vm = this;
        vm.data = {
            description: ''
        };
        vm.isSaving = false;
        $scope.save = function () {
            if(!vm.data.description){
                MessageService.error("备注不能为空");
                return
            }
            vm.isSaving = true;
            $uibModalInstance.close();
        }
        $scope.hide = function () {
            $uibModalInstance.close();
        }
    }
})();
