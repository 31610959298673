(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ModuleResetPasswordController', ModuleResetPasswordController);

    ModuleResetPasswordController.$inject = ['Auth', 'Principal','$uibModalInstance','User','$state','Password'];

    function ModuleResetPasswordController (Auth, Principal,$uibModalInstance,User,$state,Password) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.cancel = cancel;
        vm.success = null;
        vm.postData = {
            password: null
        };

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function changePassword () {
            if (vm.password != vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                vm.postData.password = vm.password;
                Password.change(vm.password,function(data){
                    vm.error = null;
                    vm.success = 'OK';
                    $uibModalInstance.close();
                    Auth.logout();
                    $state.go('login');
                });
            }
        }

        function cancel (){
            $state.go("home");
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
