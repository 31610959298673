(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SupplierDowmloadChartController', SupplierDowmloadChartController);

    SupplierDowmloadChartController.$inject = ['$scope', '$state', 'Supplier', 'MessageService', 'SalesOrder', '$uibModal','Product', '$localStorage','$http',
        '$stateParams', '$rootScope', 'Channels'];

    function SupplierDowmloadChartController ($scope, $state, Supplier, MessageService, SalesOrder, $uibModal, Product, $localStorage, $http,
        $stateParams, $rootScope, Channels) {
        var vm = this;
        vm.token = $localStorage.authenticationToken;
        vm.timeName = 'day';
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            supId: '',
            productName: '',
            sku: '',
            type: 'justify',
            startDate: '',
            endDate: '',
        };
        vm.backList = [
            {name:'已回件', value: "true"},
            {name:'未回件', value: "false"}
        ];
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.typeName = '';

        $scope.getProductId =function(data){
            vm.tableData.productName = data ? data.name :'';
        };

        init();
        function init() {
            Supplier.getAll(function (res) {
                vm.supplierList = res.filter(function(item){return item.enable});
            });

            // 获取商品信息
            Product.getAll(function (res) {
                vm.productList = res.filter(function(item){return item.enable});
            });

            vm.covarianceList = [
                {name:'未拼版下载', value:'justify'},
                {name:'已拼版下载', value:'makeup'}
            ];
            vm.timeList = [
                {name:'昨日', value:'day'},
                {name:'最近7天', value:'week'},
                {name:'最近30天', value:'month'}
            ];
            angular.element("#endDate").val(GetDateStr(-1)+' 23:59:59');
            angular.element("#startDate").val(GetDateStr(-30)+' 00:00:00');
            initData();
        }
        $scope.timeCb = function (time) {
            // vm.timeName = time.value;
            angular.element("#endDate").val(GetDateStr(-1)+' 23:59:59');
            if(time.value == 'day'){
                angular.element("#startDate").val(GetDateStr(-1)+' 00:00:00');
            }
            if(time.value == 'week'){
                angular.element("#startDate").val(GetDateStr(-7)+' 00:00:00');
            }
            if(time.value == 'month'){
                angular.element("#startDate").val(GetDateStr(-30)+' 00:00:00');
            }
            initData();
        };

        $scope.inquire = function () {
            initData();
        }
        function initData() {
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            if(vm.tableData.startDate == ""){
                MessageService.error("开始时间不能为空");
                return
            }
            if(vm.tableData.endDate == ""){
                MessageService.error("结束时间不能为空");
                return
            }
            var _startDate = new Date(vm.tableData.startDate);
            var _endDate = new Date(vm.tableData.endDate);
            var _mistiming = parseInt(_endDate - _startDate) / 1000 / 60 / 60 / 24;
            vm.timeName = "";
            if(GetDateStr(-1) == vm.tableData.endDate){
                if(_mistiming == 0){vm.timeName = "day";}
                if(_mistiming == 6){vm.timeName = "week";}
                if(_mistiming == 29){vm.timeName = "month";}
            }

            SalesOrder.getData4DowmloadPage(vm.tableData, function (res) {
                vm.typeData = res;

                // 表格数据
                // vm.tableData2 =angular.copy(vm.tableData);
                // vm.tableData2.page= 0;
                // vm.tableData2.size= 20;
                // SalesOrder.getData4ChannelPage(vm.tableData2, function (data) {
                //     vm.typeData = data;
                // }, function (error) {});
            }, function () {
                MessageService.error("查询数据失败，请稍后重试！");
            })
        }

        function total(data){
            vm.typeData = [];
            vm.orderNumSum = 0;
            vm.quantitySum = 0;
            vm.pagesSum = 0;
            vm.downloadsSum = 0;
            if(vm.tableData.type == 'completed'){
                vm.typeName = '完成订单数';
            }
            if(vm.tableData.type == 'assigned'){
                vm.typeName = '分配订单数';
            }
            if(vm.tableData.type == 'downloaded'){
                vm.typeName = '下载次数';
                for(var item in data){
                    var _obj = {
                        productName: item,
                        downloads: data[item]
                    };
                    vm.downloadsSum += data[item];
                    vm.typeData.push(_obj)
                }
            }else {
                vm.typeData = data;
                for(var i=0; i<data.length; i++){
                    if(data[i].orderNum){
                        vm.orderNumSum += data[i].orderNum;
                    }
                    if(data[i].quantity){
                        vm.quantitySum += data[i].quantity;
                    }
                    if(data[i].pages){
                        vm.pagesSum += data[i].pages;
                    }
                }
            }
        }

        //下载
        $scope.chartExport = function () {
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            if(vm.tableData.startDate == ""){
                MessageService.error("开始时间不能为空");
                return
            }
            if(vm.tableData.endDate == ""){
                MessageService.error("结束时间不能为空");
                return
            }
            $http({
                url: 'api/sales_order/downloadData4Dowmload',
                method: "GET",
                params: vm.tableData,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = Date.now() + ".xls";
                a.setAttribute('download', '');
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        };

        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }
        function getDate (datestr) {
            datestr = datestr.split(" ")[0];
            var temp = datestr.split("-");
            if (temp[1] === '01') {
                temp[0] = parseInt(temp[0],10) - 1;
                temp[1] = '12';
            } else {
                temp[1] = parseInt(temp[1],10) - 1;
            }
            //new Date()的月份入参实际都是当前值-1
            var date = new Date(temp[0], temp[1], temp[2]);
            return date;
        }
        function getDiffDate (start, end) {
            var startTime = getDate(start);
            var endTime = getDate(end);
            var dateArr = [];
            while ((endTime.getTime() - startTime.getTime()) > 0) {
                var year = startTime.getFullYear();
                var month = startTime.getMonth().toString().length === 1 ? "0" + (parseInt(startTime.getMonth().toString(),10) + 1) : (startTime.getMonth() + 1);
                var day = startTime.getDate().toString().length === 1 ? "0" + startTime.getDate() : startTime.getDate();
                dateArr.push(year + "-" + month + "-" + day);
                startTime.setDate(startTime.getDate() + 1);
            }
            return dateArr;
        }
    }
})();
