(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('materials', {
            parent: 'module',
            url: '/materials',
            params:{page: null},
            data: {
                authorities: ['ROLE_MATERIALTEMPLATE']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/materials/materials.html',
                    controller: 'MaterialsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })
        .state('materialsAdd', {
            parent: 'module',
            url: '/materialsAdd',
            data: {
                authorities: ['ROLE_MATERIALTEMPLATE']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/materials/materials-add.html',
                    controller: 'MaterialsAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })
        .state('materialsEdit', {
            parent: 'module',
            url: '/materialsEdit/{id}',
            params:{page: null},
            data: {
                authorities: ['ROLE_MATERIALTEMPLATE']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/materials/materials-edit.html',
                    controller: 'MaterialsEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'MaterialTemplates', function ($stateParams, MaterialTemplates) {
                    return MaterialTemplates.detail({id: $stateParams.id});
                }]
            }
        });
    }
})();
