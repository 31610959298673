(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderReplenishmentPicController', OrderReplenishmentPicController);

    OrderReplenishmentPicController.$inject=['entity','Qinius','$q','$scope','$uibModalInstance'];
    function OrderReplenishmentPicController(entity,Qinius,$q,$scope,$uibModalInstance){
        var vm = this;
        vm.salesOrder = entity;

        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();

        $q.all([vm.tokens.$promise, vm.domains.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        });

        $scope.modalHide = function(){
            $uibModalInstance.close(false);
        };
    }

})();
