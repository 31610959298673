(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderExpressController', OrderExpressController);

    OrderExpressController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'ParseLinks', 'SalesOrder','$uibModalInstance', 'entity', 'Express'];

    function OrderExpressController ($scope, Principal, LoginService, $state,User,MessageService, ParseLinks, SalesOrder, $uibModalInstance, entity, Express) {
        var vm = this;
        vm.order_express = entity;
        vm.order_expressIds =vm.order_express.map(function(item){return item.number;});

        Express.getAll(function(res){
            vm.express = res.filter(function(item){return item.enable})
        });

        $scope.modalHide = function(){
            $uibModalInstance.close(false);
        };

        //保存
        $scope.save = function () {
            if(!vm.code){
                MessageService.error("物流指定不能为空");
                return;
            }

            vm.orderData ={
                numbers: vm.order_expressIds.join(','),
                expressId: vm.code
            };
            SalesOrder.appointLogisticsCompany(vm.orderData, function (res) {
                MessageService.success("物流公司指定成功");
                $uibModalInstance.close(true);
            }, function (error) {
                MessageService.error("物流公司指定失败");
            });
        }
    }
})();
