(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('EditPhoneController', EditPhoneController);

    EditPhoneController.$inject = ['$scope', 'Principal', 'LoginService','$state','User','$uibModal','$uibModalInstance','MessageService','$timeout', 'entity'];

    function EditPhoneController ($scope, Principal, LoginService,$state,User,$uibModal,$uibModalInstance,MessageService,$timeout, entity) {
        var vm = this;
        vm.user = angular.copy(entity);
        vm.user.phone = Number(vm.user.phone);
        console.log(vm.user)


        $scope.save = function() {
            if (vm.user.phone == null || vm.user.phone == "") {
                MessageService.error("联系方式不能为空");
                return false;
            }
            // var myreg=/^[1][3,4,5,6,7,8][0-9]{9}$/;
            // if (!myreg.test(vm.user.phone)) {
            //     MessageService.error("请输入11位符合要求的电话号码");
            //     return;
            // };
            vm.isSaving = true;
            User.update(vm.user, function (res) {
                vm.isSaving = false;
                MessageService.success('更新成功');
                $uibModalInstance.close(vm.user);
            }, function (error) {
                vm.isSaving = false;
                MessageService.error("更新失败");
            });
        };
        $scope.modalHide = function(){
            $uibModalInstance.close(false);
        };
    }
})();
