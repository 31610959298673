(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ReserveController', ReserveController);

    ReserveController.$inject = ['$scope','Reserves','Depository','MessageService', '$http','ParseLinks', '$localStorage','$stateParams','$uibModal'];

    function ReserveController ($scope,Reserves,Depository,MessageService, $http,ParseLinks, $localStorage,$stateParams,$uibModal) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.clear =clear;
        vm.token = $localStorage.authenticationToken;
        //type  accessories 辅料 / product 成品
        Depository.getAll({type: "product"},function(res){
            vm.categories = res.filter(function(item){return item.defaulted});
        });

        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            type: 'product',// 类型。accessories 辅料 / product 成品
            key:'',
            state:'all',
            warehouseId:''
        };

        vm.conditionList = [
            {name:'全部', value:'all'},
            {name:'正常', value: 'normal'},
            {name:'预警', value:'warning'}
        ];
        $scope.conditionSelect = function(data){
            vm.tableData.state = data.value;
            vm.tableData.page = 0;
            loadAll();
        };

        loadAll();
        function loadAll() {
            vm.inventories = [];
            Reserves.page(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                vm.inventories = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            loadAll();
                        }
                    }
                });
            }, function (error) {

            });
        }

        $scope.search =function(){
            vm.tableData.page = 0;
            loadAll();
        };

        function clear(){
            vm.tableData.key = null;
            vm.tableData.page = 0;
            loadAll();
        }

        $scope.inventoryTask = function(data){
            $uibModal.open({
                templateUrl: 'app/finishedWarehouse/reserve/inventoryTask.html',
                controller: 'InventoryTaskController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return data;
                    }
                }
            }).result.then(function(result){
                loadAll();
            });
        };

        $scope.allocateTask = function(data){
            $uibModal.open({
                templateUrl: 'app/finishedWarehouse/reserve/allocateTask.html',
                controller: 'AllocationTaskController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return data;
                    }
                }
            }).result.then(function(result){
                loadAll();
            });
        };


        //下载
        $scope.orderExport = function () {
            $http({
                url: 'api/reserves/exportData',
                method: "GET",
                params: vm.tableData,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "成品仓库记录.xls";
                a.setAttribute('download', '');
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        };


    }
})();
