(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ProofRuleEditController', ProofRuleEditController);

    ProofRuleEditController.$inject = ['$scope','$state','MessageService', 'ProofRule', 'entity', '$stateParams', '$uibModal', 'UidService'];

    function ProofRuleEditController ($scope, $state,MessageService,ProofRule, entity, $stateParams, $uibModal, UidService) {
        var vm = this;
 
        vm.proofRule = entity;

        $scope.proofAdd = function(){
            var data = {applyAllPage:true, uuid:UidService.get(), added:true};
            if (vm.proofRule.sizeProofs == null) {
                vm.proofRule.sizeProofs = [];
            };
            // vm.proofRule.sizeProofs.push(data);
            $scope.proofEdit(data);
        }

        $scope.proofCopy = function(data){
            var proof = angular.copy(data);
            proof.id = null;
            if (vm.proofRule.sizeProofs == null) {
                vm.proofRule.sizeProofs = [];
            };
            proof.id = null;
            proof.uuid = UidService.get();
            vm.proofRule.sizeProofs.push(proof);
        }

        $scope.proofDelete = function(data, index){
            if (data.id == null) {
                vm.proofRule.sizeProofs.splice(index,1);
            }else{
               data.cancelled = true; 
            }
        }

        //设置区域
        $scope.proofEdit = function(data){
            $uibModal.open({
                templateUrl: 'app/setting/proofRule/sizeProof-edit.html',
                controller: 'SizeProofEditController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return data;
                    }
                }
            }).result.then(function(result){
                if (vm.proofRule.sizeProofs == null) {
                    vm.proofRule.sizeProofs = [];
                };
                if (result.added) {
                    result.added = false;
                    vm.proofRule.sizeProofs.push(result);
                }else{
                    if (result.id == null) {
                        for (var i = vm.proofRule.sizeProofs.length - 1; i >= 0; i--) {
                            var p = vm.proofRule.sizeProofs[i];
                            if (p.uuid == result.uuid) {
                                p = result;
                                break;
                            }
                        }
                    }else{
                        for (var i = vm.proofRule.sizeProofs.length - 1; i >= 0; i--) {
                            var p = vm.proofRule.sizeProofs[i];
                            if (p.id == result.id) {
                                p = result;
                                break;
                            }
                        }
                    }
                }
            });
        };


        $scope.save = function () {
            if(!vm.proofRule.name){
                MessageService.error("名称不能为空");
                return;
            }
            if(vm.proofRule.name.length >20){
                MessageService.error("名称不能大于20位");
                return;
            }
             
  
            vm.isSaving = true;
            if (vm.proofRule.id) {
                ProofRule.update(vm.proofRule, onSaveSuccess, onSaveError);
            }else{
                ProofRule.save(vm.proofRule, onSaveSuccess, onSaveError);
            }

            $scope.addproof =function (){

            }

            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("修改成功");
                $state.go("proofRule",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("修改失败，请稍后重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("proofRule",{page: $stateParams.page});
        }
    }
})();
