(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('merchant', {
            parent: 'module',
            url: '/merchant',
            params:{page: null},
            data: {
                authorities: ['ROLE_SET_SUP']
            },
            views: {
                'content': {
                    templateUrl: 'app/setting/merchant/merchant.html',
                    controller: 'MerchantController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })
            .state('merchantAdd', {
                parent: 'module',
                url: '/merchantAdd',
                data: {
                    authorities: ['ROLE_SET_SUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/merchant/merchant-add.html',
                        controller: 'MerchantAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('merchantEdit', {
                parent: 'module',
                url: '/merchantEdit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_SET_SUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/merchant/merchant-edit.html',
                        controller: 'MerchantEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Merchant', function($stateParams, Merchant) {
                        return Merchant.detail({id : $stateParams.id});
                    }],
                }
            });
    }
})();
