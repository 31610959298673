(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('OrderMakeupFile', OrderMakeupFile)
        .factory('Outsource', Outsource);

    Outsource.$inject = ['$resource'];

    OrderMakeupFile.$inject = ['$resource'];

    function Outsource ($resource) {
        var resourceUrl =  'api/sales_order/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                params: {
                    id: 'page'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    function OrderMakeupFile ($resource) {
        var resourceUrl =  'api/orderMakeupFile/:id';

        return $resource(resourceUrl, {}, {
            'page': {
                method: 'GET',
                isArray: true,
                params: {
                    id: 'page'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
