(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('CustomerHub', CustomerHub)
        .factory('PayOrder', PayOrder)
        .factory('Invoices', Invoices);

    CustomerHub.$inject = ['$resource'];
    PayOrder.$inject = ['$resource'];
    Invoices.$inject = ['$resource'];

    function PayOrder($resource) {
        var resourceUrl = 'api/pay-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            'refund': {
                method: 'POST',
                params: {
                    path: 'refund'
                }
            },
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray:true
            },
        });
    }

    function CustomerHub($resource) {
        var resourceUrl = 'api/customer-hubs/:path/:id';

        return $resource(resourceUrl, {}, {
            'create': {
                method: 'POST',
                isArray: false,
                params: {
                    path: 'create'
                }
            },
            'changeBalance': {
                method: 'GET',
                params: {
                    path: 'changeBalance'
                }
            },
            'getTransactionRecord': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'getTransactionRecord'
                }
            },
            'delete': {
                method: 'DELETE'
            },
            'page': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'page'
                }
            },
            'getAll': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'getAll'
                }
            },
            'getHomePageData': {
                method: 'GET',
                params: {
                    path: 'getHomePageData'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' ,
                headers: {
                    scope:'client'
                }
            },
            'save': { method: 'POST' ,
                headers: {
                    scope:'client'
                }
            }

        });
    }

    function Invoices($resource) {
        var resourceUrl = 'api/invoices/:path/:id';

        return $resource(resourceUrl, {}, {
            'delivery':{
                method:'GET',
                params: {
                    path: 'delivery'
                }
            },
            'reject': {
                method:'GET',
                params: {
                    path: 'reject'
                }
            },
            'approve': {
                method:'GET',
                params: {
                    path: 'approve'
                }
            },
            'create': {
                method: 'POST',
                isArray: false,
                params: {
                    path: 'create'
                }
            },
            'delete': {
                method: 'DELETE'
            },
            'page': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'page'
                }
            },
            'getAll': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'getAll'
                }
            },
            'getHomePageData': {
                method: 'GET',
                params: {
                    path: 'getHomePageData'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' }

        });
    }


})();
