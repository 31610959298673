(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ClassifyAddController', ClassifyAddController);

    ClassifyAddController.$inject = ['$scope', 'Principal', 'LoginService', '$state','ProductCategory','MessageService', 'Permission', 'entity', '$uibModalInstance'];

    function ClassifyAddController ($scope, Principal, LoginService, $state,ProductCategory,MessageService, Permission, entity, $uibModalInstance) {
        var vm = this;
        vm.getPcgs = getPcgs;
        vm.getPcg2 = getPcg2;

        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_CLASSIFY').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有分类管理访问的权限！");
                $state.go("home");
            }
        });
        // accessories 辅料 / product 成品
        vm.typeList = [
            {name:'商品类', value: 'product'},
            {name:'辅材类', value: 'accessories'}
        ];

        vm.data = entity;
        if (vm.data.state) {
            vm.saveData = {
                name: "",
                sort: "",
                enable: true,
                subordinate: false
            };
            vm.title = "新建分类";
        }else {
            vm.title = "编辑分类";
            ProductCategory.gets({id: vm.data.data.id}, function (res) {
                vm.saveData = res;
                ProductCategory.getAllSuperior({type: vm.saveData.sort}, function(res) {
                    vm.pcgs = res;

                    for (var i = 0; i < vm.pcgs.length; i++) {
                        if (vm.pcgs[i].id == vm.saveData.superiorId) {
                            vm.pcg = vm.pcgs[i];
                        }
                    }
                });

            });
        }

        function getPcg2(pcg) {
            if (pcg != null) {
                vm.saveData.superiorId = pcg.id;
            }
        }

        function getPcgs() {
            vm.pcgs = ProductCategory.getAllSuperior({type: vm.saveData.sort});
        }

        $scope.subordinateCb = function(){
            if(!vm.saveData.subordinate){
                vm.saveData.superiorId = "";
                vm.saveData.superiorName = "";
                vm.pcg = {}
            }
        }

        $scope.save = function() {
            if(!vm.saveData.name){
                MessageService.error("分类名称不能为空");
                return;
            }
            if(!vm.saveData.name.length>20){
                MessageService.error("分类名称不能超过20位");
                return;
            }
            if(!vm.saveData.sort){
                MessageService.error("类型不能为空");
                return;
            }
            if(!vm.saveData.categoryCode){
                MessageService.error("编码规则不能为空");
                return;
            }
            if(vm.saveData.categoryCode.length>4){
                MessageService.error("编码规则小于等于4位字符");
                return;
            }
            if (vm.saveData.subordinate && !vm.saveData.superiorId) {
                MessageService.error("上级不能为空");
                return;
            }
            vm.isSaving = true;
            ProductCategory.save(vm.saveData, function (res) {
                vm.isSaving = false;
                MessageService.success("保存成功");
                $uibModalInstance.close(true);
            }, function (error) {
                vm.isSaving = false;
                MessageService.error("保存失败，请稍后重试!");
            });
        };

        $scope.saveEdit = function(){
            if(!vm.saveData.name){
                MessageService.error("分类名称不能为空");
                return;
            }
            if(vm.saveData.name.length>20){
                MessageService.error("分类名称不能超过20位");
                return;
            }
            if(!vm.saveData.categoryCode){
                MessageService.error("编码规则不能为空");
                return;
            }
            if(vm.saveData.categoryCode.length>4){
                MessageService.error("编码规则小于等于4位字符");
                return;
            }
            if (vm.saveData.subordinate && !vm.saveData.superiorId) {
                MessageService.error("上级不能为空");
                return;
            }
            vm.isSavingEdit = true;
            ProductCategory.update(vm.saveData, function (res) {
                vm.isSavingEdit = false;
                MessageService.success("修改成功");
                $uibModalInstance.close(true);
            }, function (error) {
                vm.isSavingEdit = false;
                MessageService.error("保存失败，请检测数据是否正确!");
            });
        };

        $scope.modalHide = function(){
            $uibModalInstance.close(false);
        };
    }
})();
