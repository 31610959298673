(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderExternalController', OrderExternalController);

    OrderExternalController.$inject = ['$scope', '$stateParams', '$location', '$http', 'Principal', 'LoginService', '$state', 'SalesOrder',
    'MessageService', 'ParseLinks', 'Product', '$uibModal'];

    function OrderExternalController ($scope, $stateParams, $location, $http, Principal, LoginService, $state, SalesOrder,
        MessageService, ParseLinks, Product, $uibModal) {

        var vm = this;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.isFilter = false;
        vm.tablePitch = '';
        vm.merchantsList = [
            {name:'摩登印', value:'摩登印'},
            {name:'人人店', value:'人人店'}
        ];
        vm.backList = [
            {name:'已回件', value:true},
            {name:'未回件', value:false},
        ];
        vm.replenishmentList = [
            {name:'是', value:true},
            {name:'否', value:false},
        ];
        vm.refundList = [
            {name:'是', value:true},
            {name:'否', value:false},
        ];
        vm.conditionList = [
            {name:'全部', value:''},
            {name:'生产中', value:'Producing'},
            {name:'生产完成', value:'Finished'},
            {name:'已发货', value:'Delivered'}
        ];

        vm.predicate = 'createdDate';
        vm.resolve = false;

        vm.tableData = {
            page: 0,
            size: 20,
            sort: ['createdDate,DESC'],
            state:'',
            source:'',
            startDate: GetDateStr(-31),
            endDate: GetDateStr(0),
            merchants: '',
            platformName: '',
            port: '',
            back: '',
            replenishment: '',
            refund: '',
            payment: '',
            orderExternal: 'orderExternal',
            productId: $stateParams.productId || $location.search().productId ||'',
            sku: $stateParams.sku || $location.search().sku ||''
        };

        $scope.sort = function () {
            vm.tableData.sort = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            init();
        }

        //日期
        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }
        init()
        function init() {
            vm.tableList = [];
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            SalesOrder.query(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };

        Product.getAllByCategory({type: 'OrderingProduct'}, function (res) {
            vm.productList = res;
            if(vm.tableData.productId != ''){
                for(var i=0; i<vm.productList.length; i++ ){
                    if(vm.productList[i].id == vm.tableData.productId){
                        vm.product = vm.productList[i];
                        vm.skuList = vm.productList[i].propertyConfigs;
                    }
                }
            }
        });

        $scope.selectProduct =function(data){
            if(data){
                vm.tableData.productId = data.id;
                vm.skuList = data.propertyConfigs;
            }else {
                vm.tableData.productId = "";
                vm.tableData.sku = "";
                vm.skuList = [];
            }
        };

        $scope.filter = function(){
            vm.isFilter = !vm.isFilter;
            vm.tableData.back = '';
            vm.tableData.replenishment= '';
            vm.tableData.refund= '';
        };
        $scope.conditionSelect = function(data){
            vm.tableData.state = data.value;
            vm.tableData.page = 0;
            init()
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        $scope.tableTr = function(data){
            vm.tablePitch = data;
        };
        $scope.reset = function(){
            vm.isFilter = false;
            vm.tableData = {
                page: 0,
                size: 20,
                sort: ['createdDate,DESC'],
                state:'',
                source:'',
                startDate: '',
                endDate: '',
                merchants: '',
                payment: '',
                port: '',
                back: '',
                replenishment: '',
                refund: '',
                orderExternal: 'orderExternal',
                productId: $stateParams.productId || $location.search().productId ||'',
                sku:$stateParams.sku || $location.search().sku ||''
            };
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            init();
        }
        //查看订单
        $scope.orderLook = function () {
            $state.go('orderVipDetails',{id:vm.tablePitch.id});
        };

        //导出
        $scope.orderExport = function () {
            $http({
                url: 'api/sales_order/exportDataExternal',
                method: "GET",
                params: vm.tableData,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "外部订单" + Date.now() + ".xls";
                a.setAttribute('download', '');
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        };

        // 上传文件CSV
        $scope.orderUploadCsv = function(){
            $uibModal.open({
                templateUrl: 'app/order/order-upload-scv.html',
                controller: 'OrderUploadCsvController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return null;
                    }]
                }
            }).result.then(function (backData) {
                init();
            });
        };
    }
})();
