/**
 * Created by 小猪猪lover on 2019/1/10.
 */
(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('customerHub', {
                parent: 'module',
                url: '/customerHub',
                params:{page: null},
                data: {
                    authorities: ['ROLE_SUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/customerHub/customerHub.html',
                        controller: 'CustomerHubController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('customerHub');
                        return $translate.refresh();
                    }]
                }
            })
            .state('customerHubNew', {
                parent: 'module',
                url: '/customerHub-new',
                data: {
                    authorities: ['ROLE_SUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/customerHub/customerHub-dialog.html',
                        controller: 'CustomerHubNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('customerHub');
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return {
                            id:''
                        };
                    }
                }
            })
            .state('customerHubEdit', {
                parent: 'module',
                url: '/customerHub-edit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_SUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/customerHub/customerHub-dialog.html',
                        controller: 'CustomerHubNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('customerHub');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'CustomerHub', function ($stateParams, CustomerHub) {
                        return CustomerHub.get({id: $stateParams.id});
                    }]
                }
            })
            .state('customerHubDetail', {
                parent: 'module',
                url: '/customerHub-detail/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_SUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/supply/customerHub-detail.html',
                        controller: 'CustomerHubDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('customerHub');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'CustomerHub', function ($stateParams, CustomerHub) {
                        return CustomerHub.get({id: $stateParams.id});
                    }]
                }
            })
            .state('invoicing', {
                parent: 'module',
                url: '/invoicing',
                params:{page: null,status: null,keyword: null},
                data: {
                    authorities: ['ROLE_SUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/customerHub/invoicing.html',
                        controller: 'InvoicingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('invoicing');
                        return $translate.refresh();
                    }]
                }
            })
            .state('invoicing-detail', {
                parent: 'module',
                url: '/invoicing-detail/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_SUP']
                },
                views: {
                    'content': {
                        templateUrl: 'app/customerHub/invoicing-detail.html',
                        controller: 'InvoicingDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('invoicing');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Invoices', function ($stateParams, Invoices) {
                        return Invoices.get({id: $stateParams.id});
                    }]
                }
            })
    }
})();
