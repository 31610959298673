(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ShelfManagementEditController', ShelfManagementEditController);

    ShelfManagementEditController.$inject = ['$scope','$state','MessageService', 'GoodsShelves','entity', '$stateParams','ProductCategory','Supplier','$q'];

    function ShelfManagementEditController ($scope,$state,MessageService,GoodsShelves,entity, $stateParams,ProductCategory,Supplier,$q) {
        var vm = this;
        vm.data = entity;
        vm.productTypes = ProductCategory.getAll();
        vm.supplier = Supplier.getAll();
        $q.all([vm.productTypes.$promise,vm.supplier.$promise]).then(function() {
            vm.typeList =vm.productTypes.filter(function(item){return item.enable == true;});
            vm.suppliers =vm.supplier.filter(function(item){return item.enable == true;});
        });

        $scope.save = function () {
            if(!vm.data.supplierId){
                MessageService.error("供应商不能为空");
                return;
            }
            vm.isSaving = true;
            GoodsShelves.update(vm.data, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("编辑成功");
                $state.go("shelfManagement",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("编辑失败，请稍后重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("shelfManagement",{page: $stateParams.page});
        }
    }
})();
