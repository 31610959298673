(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MaterialController', MaterialController);

    MaterialController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService','Product', 'ParseLinks', '$uibModal', 'ProductCategory', 'Makeups', '$timeout', '$stateParams', 'Material'];

    function MaterialController ($scope, Principal, LoginService, $state,User,MessageService, Product, ParseLinks, $uibModal, ProductCategory, Makeups, $timeout, $stateParams, Material) {
        var vm = this;
        vm.isTableState = false;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: 'lastModifiedDate,DESC',
            keyword: ''
        };
        init()
        function init() {
            vm.tableList = [];
            Material.query(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        //删除
        $scope.delete = function (data) {
            MessageService.confirm("确认要删除该辅材？", function () {
                Material.delete({id: data.id}, function () {
                    init();
                    MessageService.success("删除成功");
                }, function (error) {
                    MessageService.error("删除失败，请重试！");
                });
            });
        };
    }
    
})();
