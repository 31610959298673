(function() {
    'use strict';

    angular
        .module('gwApp')
        .factory('LocationProvice', LocationProvice)
        .factory('LocationCity', LocationCity)
        .factory('LocationDistrict', LocationDistrict);

    LocationProvice.$inject = ['$resource'];

    LocationCity.$inject = ['$resource'];

    LocationDistrict.$inject = ['$resource'];

    function LocationProvice($resource) {
        var resourceUrl = 'api/location-provinces/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            "findByName":{
                method:"GET",
                params:{
                    path:"findByName"
                }
            }
        });
    }

    function LocationCity($resource) {
        var resourceUrl = 'api/location-cities/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            "findByProvince":{
                method:"GET",
                params:{
                    path:"findByProvince"
                },
                isArray: true
            },
            "findByName":{
                method:"GET",
                params:{
                    path:"findByName"
                }
            }
        });
    }

    function LocationDistrict($resource) {
        var resourceUrl = 'api/location-districts/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            "findByCities":{
                method:"GET",
                params:{
                    path:"findByCities"
                },
                isArray: true
            },
            "findByName":{
                method:"GET",
                params:{
                    path:"findByName"
                }
            }

        });
    }
})();
