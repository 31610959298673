(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SkuFileDetailController', SkuFileDetailController);

    SkuFileDetailController.$inject = ['$scope', 'msgdata', '$uibModalInstance'];

    function SkuFileDetailController ($scope, msgdata, $uibModalInstance) {
        var vm = this;
        vm.clear = clear;
        vm.data = msgdata;

        function clear(){
            $uibModalInstance.dismiss();
        }

    }
})();
