(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ProjectController', ProjectController);

    ProjectController.$inject = ['$scope', 'Principal', '$state','MessageService','$uibModal','Product', 'Project','ParseLinks','$timeout', '$stateParams'];

    function ProjectController ($scope, Principal, $state,MessageService, $uibModal,Product,Project, ParseLinks,$timeout, $stateParams) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_PRODUCT').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有商品访问的权限！");
                $state.go("home");
            }
        });
        vm.isTableState = false;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.conditionList = [
            {name:'全部', id:''},
            {name:'未提交', id:'CREATED'},
            {name:'待审核', id: 'SUBMITTED'},
            {name:'审核通过', id: 'APPROVED'},
            {name:'项目关闭', id: 'CLOSED'}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: 'lastModifiedDate,DESC',
            keyword: $stateParams.keyword ||'',
            status: $stateParams.status|| ''
        };

        //获取选中的数组
        $scope.checked =[];
        $scope.checkedList =[];
        vm.isSaving = false;
        $scope.selectOne = function(data){
            var index = $scope.checked.indexOf(data.id);
            if(index == -1 && data.checked){
                $scope.checked.push(data.id);
                $scope.checkedList.push(data);
            }else if (!data.checked && index !== -1){
                $scope.checked.splice(index, 1);
                $scope.checkedList.splice(index, 1);
            }
            if (vm.projects.length === $scope.checked.length) {
                $scope.select_all = true;
            } else {
                $scope.select_all = false;
            }
        };

        $scope.selectAll = function(){
            if($scope.select_all) {
                angular.forEach(vm.projects, function (item) {
                    item.checked = true;
                });
                $scope.checked = vm.projects.map(function(value){
                    return value.id;
                });
                $scope.checkedList = vm.projects.map(function(value){
                    return value;
                })
            }else {
                angular.forEach(vm.projects, function (item) {
                    item.checked = false;
                });
                $scope.checked = [];
                $scope.checkedList = [];
            }
        };

        //编辑项目
        $scope.projectEdit = function(){
            if ($scope.checked.length == 0) {
                MessageService.error('请选择列表项');
                return;
            }else if($scope.checked.length>1){
                MessageService.error('请选择其中一项进行订购');
                return;
            }else{
                $state.go('projectEdit',{
                    id:$scope.checked[0],
                    page: vm.tableData.page,
                    keyword:vm.tableData.keyword}
                )
            }
        };

        //提交项目
        $scope.projectSubmit = function(){
            console.log($scope.checkedList);
            var flag =true;
            if ($scope.checked.length == 0) {
                MessageService.error('请选择列表项');
                return;
            }else{
                angular.forEach($scope.checkedList,function(item){
                    if(item.status!='CREATED'){
                        flag =false;
                    }
                });
                if(flag){
                    $('#submitModal').modal('show');
                }else{
                    MessageService.error('请选择未提交的项目进行提交！');
                }
            }
        };

        //确认提交
        $scope.confirmSubmit = function(){
            vm.isSaving = true;
            Project.submit({ids:$scope.checked.join(',')},function(res){
                if (res.msg != '200') {
                    MessageService.error(res.msg);
                } else {
                    MessageService.success('提交成功');
                }
                vm.isSaving = false;
                $('#submitModal').modal('hide');
                init();
            })
        };

        //审核项目
        $scope.projectExam = function(){
            var flag =true;
            if ($scope.checked.length == 0) {
                MessageService.error('请选择列表项');
                return;
            }else{
                angular.forEach($scope.checkedList,function(item){
                    if(item.status!='SUBMITTED'){
                        flag =false;
                    }
                });
                if(flag){
                    $('#examModal').modal('show');
                }else{
                    MessageService.error('请选择待审核的项目进行提交！');
                }

            }
        };

        //确认审核  -通过
        $scope.confirmExam = function(){
            vm.isSaving = true;
            Project.approve({ids:$scope.checked.join(',')},function(res){
                MessageService.success('已通过');
                $('#examModal').modal('hide');
                init(); 
                vm.isSaving = false;
            })
        };

        //审核  -驳回
        $scope.rejectExam = function(){
            vm.isSaving = true;
            Project.reject({ids:$scope.checked.join(',')},function(res){
                MessageService.success('已驳回');
                $('#examModal').modal('hide');
                init();
                vm.isSaving = false;
            })
        };

        $scope.cancelModal =function(){
            $('#submitModal').modal('hide');
            $('#examModal').modal('hide');
            $('#withdrawModal').modal('hide');
            $scope.checked =[];
            $scope.checkedList =[];
            $scope.select_all = false;
            angular.forEach(vm.projects, function (item) {
                item.checked = false;
            });
        };

        //撤回项目
        $scope.projectWithdraw = function(){
            if ($scope.checked.length == 0) {
                MessageService.error('请选择列表项');
                return;
            }else{
                $('#withdrawModal').modal('show');
            }
        };

        //确认撤回  ---已经撤销的项目只在全部里面显示
        $scope.confirmWithdraw = function(){
            vm.isSaving = true;
            Project.cancel({ids:$scope.checked.join(',')},function(res){
                MessageService.success('已撤回');
                $('#withdrawModal').modal('hide');
                init();
                vm.isSaving = false;
            })
        };

        //关闭项目
        $scope.projectClose = function(){
            if ($scope.checked.length == 0) {
                MessageService.error('请选择列表项');
                return;
            }else{
                $('#closeModal').modal('show');
            }
        };

        //确认关闭
        $scope.confirmClose = function(){
            Project.close({ids:$scope.checked.join(',')},function(res){
                MessageService.success('订单关闭成功');
                $('#closeModal').modal('hide');
                init();
            })
        };

        //订购项目
        $scope.projectOrder = function(){
            if ($scope.checked.length == 0) {
                MessageService.error('请选择列表项');
                return;
            }else if($scope.checked.length>1){
                MessageService.error('请选择其中一项进行订购');
                return;
            }else{
                Project.detail({id: $scope.checked[0]},function(res){
                    var status =res.status;
                    if(status == 'APPROVED'){
                        $uibModal.open({
                            templateUrl: 'app/project/project-order.html',
                            controller: 'ProjectOrderController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            resolve: {
                                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                    return $translate.refresh();
                                }],
                                msgdata: function() {
                                    return $scope.checked;
                                }
                            }
                        }).result.then(function(result){
                            init();
                        });
                    }else{
                        MessageService.error('请选择审核通过的项目订购！');
                    }
                });


            }
        };

        init();
        function init() {
            vm.projects = [];
            $scope.checked =[];
            $scope.checkedList =[];
            $scope.select_all = false;
            angular.forEach(vm.projects, function (item) {
                item.checked = false;
            });
            Project.query(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.projects = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };

        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        $scope.conditionSelect = function(data){
            vm.tableData.status = data.id;
            vm.tableData.page = 0;
            init();
        };
        $scope.tableStateBtn = function () {
            var _h = angular.element(".table-top").height();
            if(_h > 40){
                $(".table-top").height("40");
                $(".table-state-btn").removeClass("table-state-btnOn");
            }else {
                $(".table-top").height("auto");
                $(".table-state-btn").addClass("table-state-btnOn");
            }
        }
        window.onresize = function(){
            var _h = angular.element(".table-top").find(".table-state").height();
            $timeout(function () {
                if(_h > 40){
                    vm.isTableState = true;
                }else {
                    vm.isTableState = false;
                }
            })
        }
    }

})();
