(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderReplenishController', OrderReplenishController);

    OrderReplenishController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'ParseLinks', 'SalesOrder',
        '$uibModal', '$localStorage','$http', '$stateParams', '$rootScope', '$location', 'Supplier', 'ReplenishmentReason'];

    function OrderReplenishController ($scope, Principal, LoginService, $state, User, MessageService, ParseLinks, SalesOrder,
        $uibModal, $localStorage, $http, $stateParams, $rootScope, $location, Supplier, ReplenishmentReason) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_SELF_SUPPORT_ORDER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });
        vm.token = $localStorage.authenticationToken;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tablePitch = '';
        vm.stateList = [
            {name:'待分配', value:'ToBeAssigned'},
            {name:'已分配', value: 'Assigned'},
            {name:'生产中', value: 'Producing'},
            {name:'已发货', value: 'Delivered'}
        ];
        vm.sourceList = [
            {name:'自营订单', value:'self'},
            {name:'第三方平台', value: '第三方平台'},
            {name:'外接订单', value: '外接订单'}
        ];
        vm.backList = [
            {name:'已回件', value: "true"},
            {name:'未回件', value: "false"}
        ];
        vm.conditionList = [
            {name:'全部', value:''},
            {name:'待分配', value:'ToBeAssigned'},
            {name:'已分配', value: 'Assigned'},
            {name:'生产中', value: 'Producing'},
            {name:'已发货', value: 'Delivered'}
        ];
        vm.page = $stateParams.page || $location.search().page;

        vm.predicate = 'createdDate';
        vm.resolve = false;

        vm.tableData = {
            sort: [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')],
            page: Number(vm.page) || 0,
            size: 20,
            state:$stateParams.state || $location.search().state || '',
            source:$stateParams.source || $location.search().source || '',
            startDate: $stateParams.startDate || $location.search().startDate || GetDateStr(-31),
            endDate: $stateParams.endDate || $location.search().endDate || GetDateStr(0),
            back: $stateParams.back || $location.search().back || '',
            keyword: $stateParams.keyword || $location.search().keyword || '',
            replenishment: true,
            reasonStr: '',
            supplierId: ''
        };
        vm.isFilter = vm.tableData.back ? true : false;
        angular.element("#startDate").val($stateParams.startDate || $location.search().startDate || GetDateStr(-31));
        angular.element("#endDate").val($stateParams.endDate || $location.search().endDate || GetDateStr(0));

        //日期
        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }
        //获取选中的数组
        $scope.checked =[];
        $scope.tableTr = function(data){
            var index = $scope.checked.indexOf(data);
            if(index == -1 && data.checked){
                $scope.checked.push(data);
            }else if (!data.checked && index !== -1){
                $scope.checked.splice(index, 1);
            }
            if (vm.salesOrders.length === $scope.checked.length) {
                $scope.select_all = true;
            } else {
                $scope.select_all = false;
            }

            vm.tablePitch = $scope.checked.length >0 ?true:false;
        };

        $scope.selectAll = function(){
            if($scope.select_all) {
                angular.forEach(vm.salesOrders, function (item) {
                    item.checked = true;
                });
                $scope.checked = vm.salesOrders.map(function(value){
                    return value;
                })
            }else {
                angular.forEach(vm.salesOrders, function (item) {
                    item.checked = false;
                });
                $scope.checked = [];
            }
            vm.tablePitch = $scope.checked.length >0 ?true:false;
        };

        init();
        function init() {
            vm.tablePitch = '';
            $scope.select_all = false;
            $scope.checked =[];
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            $location.search({
                'page':vm.tableData.page,
                'state':vm.tableData.state,
                'source':vm.tableData.source,
                'startDate':vm.tableData.startDate,
                'endDate':vm.tableData.endDate,
                'back':vm.tableData.back,
                'keyword':vm.tableData.keyword
            });
            Supplier.getAll("", function (res) {
                vm.suppliers = res;
            });
            ReplenishmentReason.getAll({}, function(res) {
                vm.replenishmentReasons = res;
            });
            SalesOrder.query(vm.tableData, function(res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.salesOrders = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };


        $scope.sort = function () {
            vm.tableData.sort = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            init();
        }

        $scope.filter = function(){
            vm.isFilter = !vm.isFilter;
            //vm.tableData.back = '';
        };
        $scope.conditionSelect = function(data){
            vm.tableData.state = data.value;
            vm.tableData.page = 0;
            init()
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        // $scope.tableTr = function(data){
        //     vm.tablePitch = data;
        //     for(var i=0; i<vm.salesOrders.length; i++){
        //         if(data.id == vm.salesOrders[i].id){
        //             vm.salesOrders[i].pitchOn = true;
        //         }else {
        //             vm.salesOrders[i].pitchOn = false;
        //         }
        //     }
        // };
        $scope.reset = function(){
            vm.isFilter = false;
            vm.tableData = {
                sort: ['createdDate,DESC'],
                page: 0,
                size: 20,
                state:'',
                source:'',
                startDate: '',
                endDate: '',
                back: '',
                condition: '全部',
                replenishment: true
            };
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            init();
            localStorage.removeItem('tableData');
        };
        //查看订单
        $scope.orderLook = function () {
            if($scope.checked.length >1){
                MessageService.error('仅能查看一个订单，请选择！');
                return;
            }else {
                $state.go('orderDetails', {
                    id: $scope.checked[0].id,
                    page: vm.tableData.page,
                    state: vm.tableData.state,
                    source: vm.tableData.source,
                    startDate: vm.tableData.startDate,
                    endDate: vm.tableData.endDate,
                    back: vm.tableData.back,
                    condition: vm.tableData.condition,
                    keyword: vm.tableData.keyword
                });
            }
        };
        //客户备注
        $scope.orderRemark = function () {
            // if(!vm.tablePitch){
            //     MessageService.error("请选择订单");
            //     return
            // }
            if($scope.checked.length >1){
                MessageService.error('只能选择一个订单 ！');
                return;
            }else {
                $uibModal.open({
                    templateUrl: 'app/order/order-remark.html',
                    controller: 'OrderRemarkController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function () {
                            return
                        }]
                    }
                }).result.then(function (backData) {
                    // console.log(backData);
                });
            }
        };
        //取消补货订单
        $scope.orderReplenishment = function () {
            if($scope.checked.length >1){
                MessageService.error('只能选择一个订单！');
                return;
            }else {
                if ($scope.checked[0].makeupId != null) {
                    MessageService.error('已经拼版的订单不可取消！');
                    return;
                }
                MessageService.confirm("确认取消当前补货订单？", function () {
                    SalesOrder.delete({id: $scope.checked[0].id}, function () {
                        init();
                        MessageService.success("补货订单取消成功");
                    }, function (error) {
                        MessageService.error("取消失败，请重试！");
                    });
                });
            }
        };

        //选择物流公司
        $scope.orderExpress = function(){
            //如果是已发货状态则不能修改物流公司
            var flagExpress =false;
            angular.forEach($scope.checked,function(item){
                if(item.status =="Delivered"){
                    flagExpress =true;
                    return;
                }
            });
            if(flagExpress){
                MessageService.error('存在已发货的订单，不能修改物流公司，请重新选择！');
                return;
            }else {
                $uibModal.open({
                    templateUrl: 'app/order/order-express.html',
                    controller: 'OrderExpressController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function () {
                            return $scope.checked
                        }]
                    }
                }).result.then(function (backData) {
                    if (backData) {
                        init()
                    }
                });
            }
        };
        //修改
        $scope.modification = function(){
            var flagExpress =false;
            var isStatus =false;
            var isStatusProducing =false;
            angular.forEach($scope.checked,function(item, index){
                if(item.status !="Assigned" && item.status !="Producing" && item.status != "Finished" && item.status !="Delivered"){
                    flagExpress =true;
                    return;
                }
                if(index>0 && item.status != $scope.checked[index-1].status){
                    isStatus =true;
                    return;
                }
                if(item.status == "Producing" && $scope.checked.length>1){
                    isStatusProducing =true;
                    return;
                }
            });
            if(flagExpress){
                MessageService.error('只能修改已分配、生产中、已发货这三个状态的订单');
                return;
            }
            if(isStatus){
                MessageService.error('请选择相同状态下的订单');
                return;
            }
            if(isStatusProducing){
                MessageService.error('生产中的订单只能选择一个');
                return;
            }
            $uibModal.open({
                templateUrl: 'app/order/order-modification.html',
                controller: 'OrderModificationController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return $scope.checked
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    init()
                }
            });
        }

        //导出
        $scope.orderExport = function () {
            $http({
                url: 'api/sales_order/exportReplenishData',
                method: "GET",
                params: vm.tableData,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = Date.now() + ".xls";
                a.setAttribute('download', '');
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        };


        $rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
            $rootScope.previousState = from;
            $rootScope.previousParams = fromParams;
        });
    }
})();
