(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('DeliveryRequestDetailsController', DeliveryRequestDetailsController);

    DeliveryRequestDetailsController.$inject = ['$q', '$scope', 'Principal', '$state', 'MessageService', 'DeliveryRequest', '$localStorage', '$uibModalInstance'];

    function DeliveryRequestDetailsController ($q, $scope, Principal, $state, MessageService, DeliveryRequest, $localStorage, $uibModalInstance) {
        var vm = this;

        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_DELIVER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });

        vm.dataList = DeliveryRequest.mergeRequestList();
        $q.all([vm.dataList.$promise]).then(function(){
            vm.checkedAll = false;
        });

        $scope.selectAll = function(data) {
            for (var i = data.deliveryRequestList.length - 1; i >= 0; i--) {
                data.deliveryRequestList[i].selected = data.selected;
            }
        }

        $scope.selectOne = function (obj, data) {
            vm.objList = [];
            var flag = true;
            for (var i = data.deliveryRequestList.length - 1; i >= 0; i--) {
                if (!data.deliveryRequestList[i].selected) {
                    flag = false;
                }
            }
            if (flag) {
                data.selected = true;
            } else {
                data.selected = false;
            }

            if (obj.selected) {
                vm.objList.push(obj.id);
            } else {
                for (var i = 0; i < vm.objList.length; i ++) {
                    if (vm.objList[i] == obj.id) {
                        if (vm.objList.length == 1) {
                            vm.objList = [];
                        } else {
                            vm.objList.splice(i, 1);
                        }
                    }
                }
            }
        };
        vm.colorList = [];
        vm.colorIndex = '';
        $scope.color = function(index, item){
            if(vm.colorIndex !== index){
                vm.colorIndex = index;
                vm.colorList = [];
            }
            var _index = vm.colorList.indexOf(item);
            if(_index == -1){
                vm.colorList.push(item)
            }
            return vm.colorList.indexOf(item)%6
        };

        $scope.mergeBtn = function () {
            vm.objList = [];

            for (var i = vm.dataList.length - 1; i >= 0; i--) {
                for (var j = vm.dataList[i].deliveryRequestList.length - 1; j >= 0; j--) {
                    if (vm.dataList[i].deliveryRequestList[j].selected) {
                        vm.objList.push(vm.dataList[i].deliveryRequestList[j].id);
                    }
                }
            }

            DeliveryRequest.mergeDeliveryRequest({ids:vm.objList.join(",")}, function(){
                MessageService.success("合并成功");
                $uibModalInstance.close();
            });
        }


        $scope.goBack = function(){
            $uibModalInstance.close();
        };
    }
})();
