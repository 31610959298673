(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderAddressController', OrderAddressController);

    OrderAddressController.$inject = ['$q', '$scope', 'Principal', '$state', 'MessageService', 'SalesOrder','$uibModalInstance', 'entity', 'LocationProvice', 'LocationCity', 'LocationDistrict', 'OrderPack'];

    function OrderAddressController ($q, $scope, Principal, $state, MessageService, SalesOrder, $uibModalInstance, entity, LocationProvice, LocationCity, LocationDistrict, OrderPack) {
        var vm = this;
        vm.salesOrder = entity;

        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;

        $q.all([vm.salesOrder.$promise]).then(function(){
            init();
        });

        function init() {
            console.log(vm.salesOrder)
            OrderPack.get({id:vm.salesOrder.orderPackId}, function (res) {
                vm.orderPack = res;
                // 获取地址
                LocationProvice.query({},function (data) {
                    vm.provinces =  data;
                    for (var i = vm.provinces.length - 1; i >= 0; i--) {
                        var p = vm.provinces[i];
                        if (res.orderLocation && p.name == res.orderLocation.address.split("-")[0]) {
                            vm.province = p;
                            break;
                        }
                    }
                    vm.districts = [];
                    if(vm.province){
                        LocationCity.findByProvince({provinceId: vm.province.id}, function (data) {
                            vm.citys = data;
                            for (var i = vm.citys.length - 1; i >= 0; i--) {
                                var c = vm.citys[i];
                                if (res.orderLocation && c.name == res.orderLocation.address.split("-")[1]) {
                                    vm.city = c;
                                    break;
                                }
                            }
                            if(vm.city){
                                LocationDistrict.findByCities({cityId: vm.city.id}, function (data) {
                                    vm.districts = data;
                                    for (var i = vm.districts.length - 1; i >= 0; i--) {
                                        var di = vm.districts[i];
                                        if (res.orderLocation && di.name == res.orderLocation.address.split("-")[2]) {
                                            vm.district = di;
                                            vm.address = res.orderLocation.address.split(vm.district.name+"-")[1];
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            })
        }

        function changeProvince() {
            LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                vm.citys = data;
                vm.districts = [];
            });
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
            });
        }

        $scope.modalHide = function(){
            $uibModalInstance.close(false);
        };

        $scope.save = function() {
            vm.orderPack.orderLocation.address = vm.province.name+"-"+vm.city.name+"-"+vm.district.name+"-"+vm.address;
            OrderPack.updateOrderLocation(vm.orderPack, function(data){
                MessageService.success("修改成功！");
                $uibModalInstance.close(true);
            });
        }
    }
})();
