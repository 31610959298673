(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('UnitMeasurement', UnitMeasurement)
        .factory('UnitMeasurementList', UnitMeasurementList);

    UnitMeasurement.$inject = ['$resource'];
    UnitMeasurementList.$inject = ['$resource'];

    function UnitMeasurement ($resource) {
        var resourceUrl =  'api/measurement/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'pages'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                isArray: true,
                params: {
                    path: 'getAll'
                }
            } ,
            'delete':{method:'DELETE'},
            'add': { method:'POST' } ,
            'update': { method:'PUT' },
            'detail': {
                method:'GET',
                params: {
                    path: 'getOne'
                }
            }
        });
    }
    function UnitMeasurementList ($resource) {
        var resourceUrl =  'api/measurementList/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
