(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('InventoryDetailController', InventoryDetailController);

    InventoryDetailController.$inject = ['$scope','$state','Reserves','$uibModalInstance','msgdata','MessageService'];

    function InventoryDetailController ($scope, $state,Reserves,$uibModalInstance,msgdata,MessageService) {
        var vm = this;
        vm.inventory= msgdata;

        if (vm.inventory.type == 'out') {
            vm.num = vm.inventory.quantity - vm.inventory.num;
        } else if (vm.inventory.type == 'in') {
            vm.num = vm.inventory.quantity + vm.inventory.num;
        }

        $scope.cancelRecord = function(){
            $uibModalInstance.close();
        };

    }
})();
