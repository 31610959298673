(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MachineAddController', MachineAddController);

    MachineAddController.$inject = ['$scope','$state','MessageService', 'Machine','Supplier','$uibModal'];

    function MachineAddController ($scope, $state,MessageService,Machine,Supplier,$uibModal) {
        var vm = this;
        init();
        function init(){
            vm.machine = {
                type: '',
                supplierId:'',
                makeups:[],
                capacity:'',
                enable: true
            };

            Supplier.getAll({},function(res){
                vm.suppliers =res.filter(function(item){
                    return item.enable;
                });
            });
        }

        $scope.setImposition = function(){
            $uibModal.open({
                templateUrl: 'app/setting/machineTypes/set-pin.html',
                controller: 'SetPinController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return vm.machine.makeups;
                    }
                }
            }).result.then(function(result){
                if(!result){
                    vm.machine.makeups = [];
                }else{
                    vm.machine.makeups = result;
                }
            });
        };

        $scope.save = function () {
            if(!vm.machine.type){
                MessageService.error("机型名称不能为空");
                return;
            }
            if(vm.machine.type.length >20){
                MessageService.error("机型名称不能大于20位");
                return;
            }
            if(!vm.machine.supplierId){
                MessageService.error("供应商不能为空");
                return;
            }
            if(vm.machine.makeups.length == 0){
                MessageService.error("拼版方式不能为空");
                return;
            }
            if(!vm.machine.capacity){
                MessageService.error("产能不能为空");
                return;
            }
            vm.isSaving = true;
            Machine.create(vm.machine, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("添加成功");
                $state.go("machine");
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("添加失败，请稍后重试！");
            }
        };
    }
})();
