(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderUploadCsvController', OrderUploadCsvController);

    OrderUploadCsvController.$inject = ['$scope', '$state', '$location', '$localStorage', 'MessageService','$uibModalInstance', 'Channels', 'Merchant'];

    function OrderUploadCsvController ($scope, $state, $location, $localStorage, MessageService, $uibModalInstance, Channels, Merchant) {
        var vm = this;
        vm.token = $localStorage.authenticationToken;
        vm.channels = Channels.getAll();

        vm.data = {
            description: ''
        };
        vm.isSaving = false;
        vm.fileObj;
        window.upladFile = function ($event) {
            vm.fileObj = $event.target.files[0]; // js 获取文件对象
        }

        $scope.save= function() {
            var formData = new FormData();
            if (!vm.channel) {
                MessageService.error("请选择渠道名");
                return;
            }
            if (!vm.merchant) {
                MessageService.error("请选择商户名");
                return;
            }
            formData.append('file', vm.fileObj);
            formData.append('merchantId', vm.merchant);

            var url =  $location.protocol() + '://' + $location.host() + ':' + $location.port() +  "/api/sales_order/importFile"; // 接收上传文件的后台地址
            vm.isSaving = true;

            $.ajax({
                cache: true,
                type: "POST",
                url: url,
                data: formData,
                async: false,
                contentType: false,
                processData: false,
                headers: {
                    Authorization: "Bearer " + vm.token
                },
                accept: 'file',
                success: function (data) {
                    angular.element("#file").val("");
                    MessageService.success("导入成功");
                    $uibModalInstance.close(true);
                },
                error: function (error) {
                    angular.element("#file").val("");
                    MessageService.error("文件导入失败，请稍后重试！");
                }
            });
            
        }

        $scope.hide = function () {
            $uibModalInstance.close();
        }

        $scope.changeChannel = function () {
            Merchant.getListByChannel({id: vm.channel}, function(data){
                vm.merchants = data;
            })
        }
    }
})();
