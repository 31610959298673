(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('allocation', {
                parent: 'module',
                url: '/allocation',
                params:{page: null},
                data: {
                    authorities: ['ROLE_ALLOCATION_WAREHOUSE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/finishedWarehouse/allocation/allocation.html',
                        controller: 'AllocationController',
                        controllerAs: 'vm'
                    }
                }
            })
    }
})();
