(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('TaskDistributeController', TaskDistributeController);

    TaskDistributeController.$inject = ['$scope','$state','User','msgdata','$uibModal','SalesOrder','$uibModalInstance','MessageService'];

    function TaskDistributeController ($scope, $state,User,msgdata,$uibModal,SalesOrder,$uibModalInstance,MessageService) {
        var vm = this;

        init();
        function init() {
            vm.tableList = User.getEmployee();
        }

        $scope.taskOne = function(data){
            if(data.selected){
                angular.forEach(vm.tableList,function(item){
                    if(item.selected && item == data){

                    }else{
                        item.selected = false;
                    }
                })
            }
        };

        var id;
        $scope.assignTask = function(){
            if(vm.tableList.filter(function(item){return item.selected;}).length >0){
                angular.forEach(vm.tableList,function(item){
                    if(item.selected){
                        id = item.id;
                    }
                });
                SalesOrder.taskAssigned({
                    ids:msgdata.map(function(item){return item.id}).join(','),
                    userId:id
                },function(res){
                    MessageService.success('分配成功！');
                    $uibModalInstance.close();
                },function(error){
                    MessageService.error('分配失败');
                });
            }else{
                MessageService.error('请选择分配员工');
            }
        };

        $scope.cancelTask = function(){
            angular.forEach(vm.tableList,function(item){
                item.selected = false;
            });
            $uibModalInstance.close();
        };

    }
})();
