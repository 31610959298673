(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ModalFlowController', ModalFlowController);

    ModalFlowController.$inject = ['$scope', 'Principal', 'LoginService', '$state','Product','MessageService', 'Permission', 'ProductCategory','entity', 'Supplier', '$uibModalInstance'];

    function ModalFlowController ($scope, Principal, LoginService, $state,Product,MessageService, Permission, ProductCategory, entity, Supplier, $uibModalInstance) {
        var vm = this;
        vm.flowData = {
            supplierName:'',
            supplierId:'',
            makeupId:'',
            makeupName:'',
            packings:'',
            dailyOutput:''
        };
        vm.makeupTypeList = [];
        init();
        function init(){
            Supplier.getAll({}, function (res) {
                vm.supplierList = res;

                if(entity.type == 'add'){
                    vm.type = true;
                }
                if(entity.type == 'edit'){
                    vm.type = false;
                    var data = entity.data;
                    for(var i=0; i<vm.supplierList.length; i++){
                        if(vm.supplierList[i].id == data.supplierId){
                            vm.supplierName = vm.supplierList[i];
                            vm.makeupTypeList = vm.supplierList[i].makeups;
                        }
                    }
                    for(var k=0; k<vm.makeupTypeList.length; k++){
                        if(vm.makeupTypeList[k].id == data.makeupId){
                            vm.makeup = vm.makeupTypeList[k];
                        }
                    }
                    vm.flowData = {
                        supplierName:data.supplierName,
                        supplierId:data.supplierId,
                        makeupName:data.makeupName,
                        makeupId:data.makeupId,
                        packings:data.packings,
                        dailyOutput:data.dailyOutput,
                        defaulted:data.defaulted
                    };
                }
            });
        };
        $scope.supplierSelect = function(data){
            vm.flowData.supplierName = vm.supplierName.name;
            vm.flowData.supplierId = vm.supplierName.id;
            vm.makeupTypeList = vm.supplierName.makeups;
        };
        $scope.makeupSelect = function(){
            vm.flowData.makeupId = (vm.makeup == null) ? "" : vm.makeup.id;
            vm.flowData.makeupName = (vm.makeup == null) ? "" : vm.makeup.name;
        };


        $scope.flowSave = function () {
            if(!vm.flowData.supplierName){
                MessageService.error("生产商不能为空");
                return;
            }
            if(!vm.flowData.makeupName){
                MessageService.error("拼版方式不能为空");
                return;
            }
            if(!vm.flowData.packings){
                MessageService.error("打包数不能为空");
                return;
            }
            if(!vm.flowData.dailyOutput){
                MessageService.error("日产额不能为空");
                return;
            }
            $uibModalInstance.close(vm.flowData);
        };
        $scope.modalHide = function(){
            $uibModalInstance.close();
        };
    }
})();
