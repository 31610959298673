(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('RoleAddController', RoleAddController);

    RoleAddController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'Permission', 'Role'];

    function RoleAddController ($scope, Principal, LoginService, $state,User,MessageService, Permission, Role) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_ROLE').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有角色访问的权限！");
                $state.go("home");
            }
        });
        vm.typeList = [
            {name:'内部', value:'内部'},
            {name:'供应商', value: '供应商'}
        ];
        vm.role = {
            enabled: true,
            type:'内部'
        }

        init();
        function init() {

            Permission.getAllByScope({permissionScope:'MAINEND'},function(result) {
                vm.permissions = result;
                if(vm.permissions.length>0){
                    $scope.groupBy(vm.permissions);
                }
            });
        }
        $scope.groupBy = function(list){
            if(list == null){
                return null;
            }
            vm.finalList = [];
            for(var j=0;j<list.length;j++){
                vm.pushFlag = false;
                if(vm.finalList.length<=0){
                    vm.finalList.push({module:list[j].module,names:[]});
                    vm.finalList[0].names.push({name:list[j].name,authority:list[j].authority,id:list[j].id});
                }else{
                    for(var k=0;k<vm.finalList.length;k++){
                        if(vm.finalList[k].module==list[j].module&&vm.finalList[k].names.indexOf(list[j].name)<0){
                            vm.finalList[k].names.push({name:list[j].name,authority:list[j].authority,id:list[j].id});
                            vm.pushFlag = true;
                        }
                    }
                    if(!vm.pushFlag){
                        vm.finalList.push({module:list[j].module,names:[{name:list[j].name,authority:list[j].authority,id:list[j].id}]});
                    }
                }
            }
        };
        $scope.permissionsSelect = function(data){
            for(var i=0; i<data.names.length; i++){
                data.names[i].selected = data.selected;
            }
        };
        $scope.permissionsRoleSelect = function(data){
            var _selected = true;
            for(var i=0; i<data.names.length; i++){
                if(!data.names[i].selected){
                    _selected = false;
                }
            }
            data.selected = _selected;
        };
        $scope.save = function() {
            vm.role.permissions = [];
            for(var i=0;i<vm.finalList.length;i++){
                for(var j=0;j<vm.finalList[i].names.length;j++){
                    if(vm.finalList[i].names[j].selected==true){
                        vm.role.permissions.push({id:vm.finalList[i].names[j].id,name:vm.finalList[i].names[j].name,
                            authority:vm.finalList[i].names[j].authority,module:vm.finalList[i].module
                        });
                    }
                }
            }
            if(!vm.role.name){
                MessageService.error("角色名称不能为空");
                return;
            }
            vm.isSaving = true;
            Role.validate({name:vm.role.name},function (res) {
                vm.isSaving = false;
                Role.save(vm.role, function (res) {
                    vm.isSaving = false;
                    MessageService.success("保存成功");
                    $state.go("role");
                }, function (error) {
                    vm.isSaving = false;
                    MessageService.error("修改失败，请稍后重试!");
                });
            },function (error) {
                vm.isSaving = false;
                MessageService.error("系统存在重复的用户名");
            });
        };
    }
})();
