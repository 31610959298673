(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CannelChooseProductController', CannelChooseProductController);

    CannelChooseProductController.$inject = ['$q', '$scope','$uibModalInstance','MessageService','Product','$timeout', 'entity'];

    function CannelChooseProductController ($q, $scope, $uibModalInstance,MessageService,Product,$timeout, entity) {
        var vm = this;
        vm.keyword ='';
        vm.selectIndex ='';
        vm.skus = entity;
        vm.first =[];
        vm.tableList =[];
        vm.data=[];
        var tempArr =[];

        var productArr = {key: null, value: null};

        vm.productList = Product.sortByCategory({keyword:vm.keyword});

        $scope.skuInclude = function(sku ){
            for(var i = 0; i< vm.skus.length; i++){
                var s = vm.skus[i];
                if (s.id == sku.id) {
                    return true;
                }
            }
            return false;
        }
        $q.all([vm.productList.$promise]).then(function() {
            if (vm.skus.length> 0 ) {
                for(var k in vm.productList){
                    if (vm.productList[k].length>0) {
                        var catrgories = vm.productList[k];
                        for (var i = catrgories.length - 1; i >= 0; i--) {
                            var category =  catrgories[i];
                            for(var k2 in category){
                                if (category[k2].length > 0) {
                                    for(var j = 0 ; j< category[k2].length; j++){
                                        var p = category[k2][j];
                                        var counts = 0;
                                        for(var k3 in p){
                                            var skus = p[k3];
                                            if (skus.length>0) {
                                                for(var z = 0; z< skus.length; z++){
                                                    var sku = skus[z];
                                                    vm.data.push(sku);
                                                    if ($scope.skuInclude(sku)) {
                                                        sku.checked = true;
                                                        counts++;
                                                    }
                                                }
                                            }
                                            if(counts==skus.length){
                                                skus.select_all = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });

        $timeout(function(){
            angular.element('.firstItem').click(function(){
                if(!$(this).hasClass('on')){
                    $(this).next('.subItems').slideDown(200).parent().siblings('.mainItems').find('.subItems').slideUp(200);
                    $(this).addClass('on').parent().siblings('.mainItems').find('.firstItem').removeClass('on');
                    $(this).find('.plus_first').addClass('rotateIcon').end().parent().siblings('.mainItems').find('.plus_first').removeClass('rotateIcon');
                }else{
                    $(this).removeClass('on');
                    $(this).next('.subItems').slideUp(200).parent().siblings('.mainItems').find('.subItems').slideUp(200);
                    $(this).find('.plus_first').removeClass('rotateIcon');
                }
            });
        },500);

        $scope.selectAll = function(key,data){
            if (data.select_all) {
                for (var i = data.length - 1; i >= 0; i--) {
                    data[i].checked = true;
                }
            }else{
                for (var i = data.length - 1; i >= 0; i--) {
                    data[i].checked = false;
                }
            }
            $scope.getProduct(key,data);
        }

        $scope.getProduct = function(key,data){
            vm.selectIndex =-1;
            vm.tableList=data;
            vm.navKey =key;
        };

        //这里搜索有问题
        $scope.search = function(){
            vm.tableList = vm.data.filter(function(item){
                return item.sku ==vm.keyword ||item.number ==vm.keyword;
            });
            //展开列表收起
            $('.firstItem').removeClass('on');
            $('.firstItem').next('.subItems').slideUp(200).parent().siblings('.mainItems').find('.subItems').slideUp(200);
            $('.firstItem').find('.plus_first').removeClass('rotateIcon');
        };

        $scope.productChoice = function(data, datas){
            for (var i = datas.length - 1; i >= 0; i--) {
                if (!datas[i].checked) {
                    datas.select_all = false;
                    return;
                }
            }
            datas.select_all = true;
        };

        $scope.confirm = function(){
          // if(vm.product==null){
          //     MessageService.error('请选择商品！');
          // }else{

          // }
            vm.skus = [];
            for(var k in vm.productList){
                if (vm.productList[k].length>0) {
                    var catrgories = vm.productList[k];
                    for (var i = catrgories.length - 1; i >= 0; i--) {
                        var category =  catrgories[i];
                        for(var k2 in category){
                            if (category[k2].length > 0) {
                                for(var j = 0 ; j< category[k2].length; j++){
                                    var p = category[k2][j];
                                    for(var k3 in p){
                                        var skus = p[k3];
                                        if (skus.length>0) {
                                            for(var z = 0; z< skus.length; z++){
                                                var sku = skus[z];
                                                if (sku.checked) {
                                                    vm.skus.push(sku);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            $uibModalInstance.close(vm.skus);
        };

        $scope.cancel = function(){
            $uibModalInstance.dismiss();
        };

    }
})();
