(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ProjectDetailController', ProjectDetailController);

    ProjectDetailController.$inject = ['$scope', '$state','entity','Project','$stateParams'];

    function ProjectDetailController ($scope, $state,entity,Project,$stateParams) {
        var vm = this;
        vm.data =entity;

        $scope.cancel = function(){
            $state.go('project', {
                    id: $stateParams.id,
                    page: $stateParams.page,
                    keyword: $stateParams.keyword
                }
            )
        };

    }
})();
