(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('RoleEditController', RoleEditController);

    RoleEditController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'Permission', 'Role', 'entity', '$stateParams','$q'];

    function RoleEditController ($scope, Principal, LoginService, $state,User,MessageService, Permission, Role, entity, $stateParams,$q) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_ROLE').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有角色访问的权限！");
                $state.go("home");
            }
        });

        vm.role = entity;
        vm.permissions = Permission.getAllByScope({permissionScope:'MAINEND'});

        $q.all([vm.role.$promise,vm.permissions.$promise]).then(function(){
            if(vm.permissions.length>0){
                $scope.groupBy(vm.permissions);
            }
        });

        $scope.groupBy = function(list){
            if(list == null){
                return null;
            }
            vm.finalList = [];
            for(var j=0;j<list.length;j++){
                vm.pushFlag = false;
                if(vm.finalList.length<=0){
                    vm.finalList.push({module:list[j].module,names:[]});
                    vm.finalList[0].names.push({name:list[j].name,authority:list[j].authority,id:list[j].id});
                }else{
                    for(var k=0;k<vm.finalList.length;k++){
                        if(vm.finalList[k].module==list[j].module&&vm.finalList[k].names.indexOf(list[j].name)<0){
                            vm.finalList[k].names.push({name:list[j].name,authority:list[j].authority,id:list[j].id});
                            vm.pushFlag = true;
                        }
                    }
                    if(!vm.pushFlag){
                        vm.finalList.push({module:list[j].module,names:[{name:list[j].name,authority:list[j].authority,id:list[j].id}]});
                    }
                }
            }

            for(var a=0; a<vm.finalList.length; a++){
                var _selected = true;
                for(var b=0; b<vm.finalList[a].names.length; b++){
                    for(var c=0; c<vm.role.permissions.length; c++){
                        if(vm.role.permissions[c].id === vm.finalList[a].names[b].id){
                            vm.finalList[a].names[b].selected = true;
                        }
                    }
                    if(!vm.finalList[a].names[b].selected){
                        _selected = false;
                    }
                }
                vm.finalList[a].selected = _selected;
            }
            console.log(vm.finalList)
        };
        $scope.permissionsSelect = function(data){
            for(var i=0; i<data.names.length; i++){
                data.names[i].selected = data.selected;
            }
        };
        $scope.permissionsRoleSelect = function(data){
            var _selected = true;
            for(var i=0; i<data.names.length; i++){
                if(!data.names[i].selected){
                    _selected = false;
                }
            }
            data.selected = _selected;
        };
        $scope.save = function() {
            vm.role.permissions = [];
            for(var i=0;i<vm.finalList.length;i++){
                for(var j=0;j<vm.finalList[i].names.length;j++){
                    if(vm.finalList[i].names[j].selected==true){
                        vm.role.permissions.push({id:vm.finalList[i].names[j].id,name:vm.finalList[i].names[j].name,
                            authority:vm.finalList[i].names[j].authority,module:vm.finalList[i].module
                        });
                    }
                }
            }
            if(!vm.role.name){
                MessageService.error("角色名称不能为空");
                return;
            }
            vm.isSaving = true;
            Role.update(vm.role, function (res) {
                vm.isSaving = false;
                MessageService.success("修改成功");
                $state.go("role",{page: $stateParams.page});
            }, function (error) {
                vm.isSaving = false;
                MessageService.error("修改失败，请稍后重试!");
            });
        };
        //返回
        $scope.goBack = function () {
            $state.go("role",{page: $stateParams.page});
        }
    }
})();
