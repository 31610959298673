(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PriceController', PriceController);

    PriceController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService','ProductCategory', 'ParseLinks', '$uibModal','$uibModalInstance'];

    function PriceController ($scope, Principal, LoginService, $state,User,MessageService, ProductCategory, ParseLinks, $uibModal, $uibModalInstance) {
        var vm = this;

        //渠道
        vm.channelList = [
            {name:'标准',id:'标准'}
        ]
        //定价策略
        vm.pricingList = [
            {name:'标准',id:'标准'}
        ]
        //基于
        vm.baseList = [
            {name:'标准',id:'标准'}
        ]

        vm.data = {
            channel: '',
            price: [
                {
                    minPurchaseQuantity: '',
                    maxPurchaseQuantity: '',
                    pricing: '',
                    base: '',
                    price: '',
                }
            ]
        }
        
        
        //阶梯设置
        $scope.ladderSet = function (data) {
            
        }
        //删除
        $scope.delete = function (index) {

        }
        //新建
        $scope.priceAdd = function (index) {
            var price = {
                    minPurchaseQuantity: '',
                    maxPurchaseQuantity: '',
                    pricing: '',
                    base: '',
                    price: '',
                };
            vm.data.price.push(price)
        }
        //确认
        $scope.save = function () {
            console.log(vm.data)
        }
        //取消
        $scope.modalHide = function () {
            $uibModalInstance.close();
        }
    }
})();
