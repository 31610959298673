(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('AppLoginController', AppLoginController);

    AppLoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth','MessageService'];

    function AppLoginController ($rootScope, $state, $timeout, Auth,MessageService) {
        var vm = this;

        vm.authenticationError = false;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.rememberMe = true;
        vm.username = null;
        vm.remember = remember;

        $timeout(function (){angular.element('#username').focus();});
        sessionStorage.removeItem('subId');
        sessionStorage.removeItem('outerIndex');
        sessionStorage.removeItem('tableData');

        function remember(){
            vm.rememberMe = !vm.rememberMe;
        };

        function login (event) {
            event.preventDefault();
            if(vm.username==null){
                MessageService.error("请输入用户名");
                return;
            }
            if(vm.password==null){
                MessageService.error("请输入密码");
                return;
            }
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    location.reload();


                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                } else {
                    location.reload();
                    $timeout(function(){
                        $state.go('home');
                    },1000);
                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

    }
})();
