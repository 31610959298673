(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SizeProofEditController', SizeProofEditController);

    SizeProofEditController.$inject = ['$scope','$state','MessageService', 'entity', '$uibModalInstance'];

    function SizeProofEditController ($scope, $state,MessageService,  entity, $uibModalInstance) {
        var vm = this;
 
        vm.sizeProof = entity;
        vm.save = save;
        vm.clear = clear;
        vm.applyAllPages = [{value:true, name:"所有页"},{value: false, name:"部分页"}]
        

        $scope.save = function () {
            if(!vm.proofRule.name){
                MessageService.error("名称不能为空");
                return;
            }
            if(vm.proofRule.name.length >20){
                MessageService.error("名称不能大于20位");
                return;
            }
        };

        function clear(){
            $uibModalInstance.dismiss();
        }
        
        function save() {
            $uibModalInstance.close(vm.sizeProof);
        }
    }
})();
