(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('DepositoryEditController', DepositoryEditController);

    DepositoryEditController.$inject = ['$scope', '$state','MessageService', 'Depository', 'entity', '$stateParams','LocationProvice', 'LocationCity', 'LocationDistrict','Supplier'];

    function DepositoryEditController ($scope,$state,MessageService,Depository, entity, $stateParams,LocationProvice, LocationCity, LocationDistrict,Supplier) {
        var vm = this;

        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;

        vm.data = entity;
        vm.typeList=[
            {name:'成品库',value:'product'},
            {name:'原辅材料库',value:'accessories'}
        ];

        Supplier.getAll(function(res){
            vm.suppliers =res.filter(function(item){return item.enable;});
            angular.forEach(vm.suppliers,function(item){
                if(vm.data.supplier.id == item.id){
                    vm.data.supplier = item;
                }
            })
        });

        $scope.save = function () {
            if(!vm.data.name){
                MessageService.error("仓库名称不能为空");
                return;
            }
            if(vm.data.name.length > 20){
                MessageService.error("仓库名称不能大于20位");
                return;
            }
            if(!vm.data.type){
                MessageService.error("仓库类型不能为空");
                return;
            }
            if(!vm.data.supplier){
                MessageService.error("所属供应商不能为空");
                return;
            }
            if(!vm.data.number){
                MessageService.error("仓库编号不能为空");
                return;
            }
            if (vm.data.number.match(/[\u4E00-\u9FA5]/g,'')) {
                MessageService.error("仓库编号不能输入中文");
                return false;
            }
            if (vm.province == null || vm.province == "") {
                MessageService.error("请选择省份");
                return false;
            };

            if (vm.city == null || vm.city == '') {
                MessageService.error('请选择城市');
                return false;
            }

            if (vm.district == null || vm.district == "") {
                MessageService.error("请选择区县");
                return false;
            };

            if (vm.data.address == null || vm.address == "") {
                MessageService.error("请输入详细地址");
                return false;
            };
            vm.isSaving = true;
            vm.data.province = vm.province.name;
            vm.data.city = vm.city.name;
            vm.data.district = vm.district.name;

            Depository.update(vm.data, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("修改成功");
                $state.go("depository",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("修改失败，请稍后重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("depository",{page: $stateParams.page});
        };


        init();
        function init() {
            LocationProvice.query({},function (data) {
                vm.provinces =  data;
                for (var i = vm.provinces.length - 1; i >= 0; i--) {
                    var p = vm.provinces[i];
                    if (p.name == vm.data.province) {
                        vm.province = p;
                        break;
                    }
                }
                if (vm.data.city != '') {
                    vm.districts = [];
                    LocationCity.findByProvince({provinceId: vm.province.id}, function (data) {
                        vm.citys = data;
                        for (var i = vm.citys.length - 1; i >= 0; i--) {
                            var c = vm.citys[i];
                            if (c.name == vm.data.city) {
                                vm.city = c;
                                break;
                            }
                        }
                        if (vm.city != null) {
                            LocationDistrict.findByCities({cityId: vm.city.id}, function (data) {
                                vm.districts = data;
                                for (var i = vm.districts.length - 1; i >= 0; i--) {
                                    var di = vm.districts[i];
                                    if (di.name == vm.data.district) {
                                        vm.district = di;
                                    }
                                }
                            });
                        }
                    });
                }
            });

        }

        function changeProvince() {
            LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                vm.citys = data;
                vm.districts = [];
            });
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
            });
        }

    }
})();
