(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('AddressNewController', AddressNewController);

    AddressNewController.$inject = ['$scope', 'Principal', 'LoginService','$state','User','msgdata','$uibModal','$uibModalInstance','MessageService','LocationProvice', 'LocationCity', 'LocationDistrict'];

    function AddressNewController ($scope, Principal, LoginService,$state,User,msgdata,$uibModal,$uibModalInstance,MessageService,LocationProvice, LocationCity, LocationDistrict) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.supply= msgdata;
        if(msgdata == '' || msgdata == null){
            $scope.addressTitle ='新建地址';
            vm.flag = false;
        }else{
            $scope.addressTitle ='编辑地址';
            vm.flag = true;
        }

        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;

        function clear(){
            $uibModalInstance.dismiss();
        }

        $scope.validate = function() {
            if (vm.province == null || vm.province == "") {
                MessageService.error("请选择省份");
                return false;
            };

            if (vm.city == null || vm.city == '') {
                MessageService.error('请选择城市');
                return false;
            }

            if (vm.district == null || vm.district == "") {
                MessageService.error("请选择区县");
                return false;
            };

            if (vm.supply.address == null || vm.supply.address == "") {
                MessageService.error("请输入详细地址");
                return false;
            };

            if (vm.supply.address.length >50) {
                MessageService.error("详细地址不能大于50位");
                return false;
            };

            if (vm.supply.contact == null || vm.supply.contact == "") {
                MessageService.error("请输入联系人姓名");
                return false;
            };
            if (vm.supply.contact.length >20) {
                MessageService.error("联系人姓名不能超过20位");
                return false;
            };

            if (vm.supply.phone == null || vm.supply.phone == "") {
                MessageService.error("请输入联系方式");
                return false;
            };
            // var myreg=/^[1][3,4,5,6,7,8][0-9]{9}$/;
            // var myreg=/^[1][0-9][0-9]{9}$/;
            // if (!myreg.test(vm.supply.phone)) {
            //     MessageService.error("请输入11位符合要求的电话号码");
            //     return false;
            // };
            return true;
        };


        function save() {
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            var obj={
                province:vm.province.name,
                city:vm.city.name,
                district:vm.district.name,
                address:vm.supply.address,
                contact:vm.supply.contact,
                phone:vm.supply.phone,
                postCode:''
            };
            $uibModalInstance.close(obj);
        }

        loadData();
        function loadData() {
            LocationProvice.query({},function (data) {
                vm.provinces =  data;
                if(vm.flag) {
                    for (var i = vm.provinces.length - 1; i >= 0; i--) {
                        var p = vm.provinces[i];
                        if (p.name == vm.supply.province) {
                            vm.province = p;
                            break;
                        }
                    }
                    if (vm.supply.city != '') {
                        vm.districts = [];
                        LocationCity.findByProvince({provinceId: vm.province.id}, function (data) {
                            vm.citys = data;
                            for (var i = vm.citys.length - 1; i >= 0; i--) {
                                var c = vm.citys[i];
                                if (c.name == vm.supply.city) {
                                    vm.city = c;
                                    break;
                                }
                            }
                            if (vm.city != null) {
                                LocationDistrict.findByCities({cityId: vm.city.id}, function (data) {
                                    vm.districts = data;
                                    for (var i = vm.districts.length - 1; i >= 0; i--) {
                                        var di = vm.districts[i];
                                        if (di.name == vm.supply.district) {
                                            vm.district = di;
                                        }
                                    }
                                });
                            }
                        });
                    }
                }
            });

        }

        function changeProvince() {
            LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                vm.citys = data;
                vm.districts = [];
            });
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
            });
        }

    }
})();
