(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CreateUserController', CreateUserController);

    CreateUserController.$inject = ['$scope', 'Principal', 'LoginService','$state','User','msgdata','$uibModal','$uibModalInstance','MessageService','entity'];

    function CreateUserController ($scope, Principal, LoginService,$state,User,msgdata,$uibModal,$uibModalInstance,MessageService,entity) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.user={
            name:'',
            password:'',
            confirmPassword:'',
            admin:true
        };
        $scope.userTitle='新建用户名';
        console.log(entity)
        console.log(vm.user)

        if(entity=='customerHub'){
            vm.isCustomerHub = true;
            vm.user={
                name:'',
                password:'',
                confirmPassword:'',
                fullname:'',
                phone:''
            };
        }else{
            vm.user={
                name:'',
                password:'',
                confirmPassword:'',
                admin:true
            };
        }

        function clear(){
            $uibModalInstance.dismiss();
        }

        $scope.validate = function () {
             if (vm.user.name == null || vm.user.name == "") {
                 MessageService.error("用户名不能为空");
                 return false;
             }
             if (vm.user.name.match(/[\u4E00-\u9FA5]/g,'')) {
                 MessageService.error("用户名不能输入中文");
                 return false;
             }
             if (vm.user.name.length >20) {
                 MessageService.error("用户名不能大于20位");
                 return false;
             }
             if (vm.user.password == null || vm.user.password == "") {
                 MessageService.error("密码不能为空");
                 return false;
             }
             if (vm.user.password.length<4 || vm.user.password.length >20) {
                 MessageService.error("密码长度在4-20位之间");
                 return false;
             }
             if (vm.user.confirmPassword == null || vm.user.confirmPassword == "") {
                 MessageService.error("确认密码不能为空");
                 return false;
             }
             if (vm.user.confirmPassword != vm.user.password) {
                 MessageService.error("确认密码与密码不一致");
                 return false;
             }
            if (vm.user.confirmPassword.length<4 || vm.user.confirmPassword.length >20) {
                 MessageService.error("确认密码长度在4-20位之间");
                 return false;
             }
            if(vm.isCustomerHub){
                if (vm.user.fullname == null || vm.user.fullname == "") {
                    MessageService.error("姓名不能为空");
                    return false;
                }
                if (vm.user.phone == null || vm.user.phone == "") {
                    MessageService.error("联系方式不能为空");
                    return false;
                }
                // var myreg=/^[1][3,4,5,6,7,8][0-9]{9}$/;
                // if (!myreg.test(vm.user.phone)) {
                //     MessageService.error("请输入11位符合要求的电话号码");
                //     return;
                // };
            }
            return true;
        };

        function save () {
            if (!$scope.validate()) {
                return;
            }

            //判断用户名是否存在
            User.validate({name: vm.user.name}, function (res) {
                vm.isSaving = false;
                if (!res.message) {
                    MessageService.error("系统存在重复的用户名");
                } else {
                    $uibModalInstance.close(vm.user);
                }
            }, function (error) {
                vm.isSaving = false;
                MessageService.error("系统存在重复的用户名");
            });
        }

    }
})();
