(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SkuMakeupDetailController', SkuMakeupDetailController);

    SkuMakeupDetailController.$inject = ['$scope', 'msgdata', '$uibModalInstance', 'Product', '$q', 'Machine'];

    function SkuMakeupDetailController ($scope, msgdata, $uibModalInstance, Product, $q, Machine) {
        var vm = this;
        vm.clear = clear;

        vm.skuManages = [];
        if (!!msgdata.skuManageList) {
            for (var i = msgdata.skuManageList.length - 1; i >= 0; i--) {
                vm.skuManages.push(msgdata.skuManageList[i]);
            }
        }

        function clear(){
            $uibModalInstance.dismiss();
        }

    }
})();
