(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MyTaskController', MyTaskController);

    MyTaskController.$inject = ['$scope', 'Principal', '$state','User','MessageService','$sessionStorage','$localStorage','$location','ParseLinks', 'SalesOrder', '$timeout', '$stateParams'];

    function MyTaskController ($scope, Principal, $state,User,MessageService, $sessionStorage,$localStorage,$location, ParseLinks, SalesOrder, $timeout, $stateParams) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_PRODUCT').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有商品访问的权限！");
                $state.go("home");
            }
        });
        vm.isTableState = false;
        vm.token = $localStorage.authenticationToken;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.selection = [
            {name:'全部', value:'all'},
            {name:'已分配', value:'Assigned'},
            {name:'已回传', value:'Uploaded'}
        ];
        vm.user = $sessionStorage.user;
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: ['createdDate,DESC'],
            keyword: '',
            username:vm.user.username,
            status:'all'
        };
        $scope.checked =[];
        $scope.selectOne = function(data){
            var index = $scope.checked.indexOf(data);
            if(index == -1 && data.checked){
                $scope.checked.push(data);
            }else if (!data.checked && index !== -1){
                $scope.checked.splice(index, 1);
            }
            if(vm.tableList.length === $scope.checked.length) {
                $scope.select_all = true;
            }else{
                $scope.select_all = false;
            }
        };

        $scope.selectAll = function(){
            if($scope.select_all) {
                angular.forEach(vm.tableList, function (item) {
                    item.checked = true;
                });
                $scope.checked = vm.tableList.map(function(value){
                    return value;
                })
            }else {
                angular.forEach(vm.tableList, function (item) {
                    item.checked = false;
                });
                $scope.checked = [];
            }
        };

        //回传  传的是zip格式的文件 只能选一个
        $scope.reloadUp = function(){
            if($scope.checked.length==0){
                MessageService.error('请选择列表项！');
            }else if($scope.checked.length>1){
                MessageService.error('请选择一项进行回传！');
            }else{
                angular.element('#uploads').modal('show');
                console.log($scope.checked);
                if($scope.checked[0].postbackFile !=null){
                    vm.fileName = $scope.checked[0].postbackFile.split("\\")[2];
                }
            }
        };

        //文件上传  二次上传覆盖之前上传的文件
        window.upladFile = function($event) {
            var ext,idx;
            var fileObj = $event.target.files[0]; // js 获取文件对象
            idx = fileObj.name.lastIndexOf(".");
            ext = fileObj.name.substr(idx+1).toUpperCase();
            ext = ext.toLowerCase();
            if (ext != 'zip'){
                angular.element("#file").val("");
                MessageService.error("只能上传.zip类型的文件!");
                return;
            }
            var url =  $location.protocol() + '://' + $location.host() + ':' + $location.port() +  "/api/sales_order/postback";

            var formData = new FormData();
            formData.append('file', fileObj);
            formData.append('orderId', $scope.checked[0].id);

            $.ajax({
                cache: true,
                type: "POST",
                url: url,
                data: formData,
                async: false,
                contentType: false,
                processData: false,
                headers: {
                    Authorization: "Bearer " + vm.token
                },
                accept: 'file',
                success: function (data) {
                    angular.element("#file").val("");
                    MessageService.success("文件上传成功");
                    if(data.message == "回传成功"){
                        vm.fileName = fileObj.name;
                        $scope.$apply();
                        init();
                    }
                },
                error: function (error) {
                    angular.element("#file").val("");
                    MessageService.error("文件上传失败，请稍后重试！");
                }
            });
        };


        //下载  可以选多个
        $scope.downloadFile = function(){
            if($scope.checked.length==0){
                MessageService.error('请选择列表项');
            }else{



            }
        };

        $scope.uploadHide = function(){
            angular.element('#uploads').modal('hide');
            angular.forEach(vm.tableList, function (item) {
                item.checked = false;
            });
        };

        var _h = angular.element(".table-top").find(".table-state").height();
        if(_h > 40){
            vm.isTableState = true;
        }else {
            vm.isTableState = false;
        }

        init();
        function init() {
            vm.tableList = [];
            $scope.select_all = false;
            $scope.checked =[];
            SalesOrder.getOrderTask(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        }

        $scope.conditionSelect = function(data){
            vm.tableData.status = data.value;
            vm.tableData.page = 0;
            init();
        };

        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };

        $scope.tableStateBtn = function () {
            var _h = angular.element(".table-top").height();
            if(_h > 40){
                angular.element(".table-top").height("40");
                angular.element(".table-state-btn").removeClass("table-state-btnOn");
            }else {
                angular.element(".table-top").height("auto");
                angular.element(".table-state-btn").addClass("table-state-btnOn");
            }
        };

        window.onresize = function(){
            var _h = angular.element(".table-top").find(".table-state").height();
            $timeout(function () {
                if(_h > 40){
                    vm.isTableState = true;
                }else {
                    vm.isTableState = false;
                }
            })
        }
    }

})();
