(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('InvoiceDetailController', InvoiceDetailController);

    InvoiceDetailController.$inject = ['$scope', '$state','MessageService', 'DeliveryRequest', 'entity', '$stateParams','$q','$filter','OrderTraces'];

    function InvoiceDetailController ($scope,$state,MessageService,DeliveryRequest, entity, $stateParams,$q,$filter,OrderTraces) {
        var vm = this;
        vm.tracesList =[];
        vm.invoice = entity;

        $q.all([vm.invoice.$promise]).then(function(){
            vm.deliveryDate =  $filter('date')(vm.invoice.deliveryDate, "yyyy-MM-dd HH:mm:ss");

            // 获取实时物流信息
            OrderTraces.getByDeliveryRequestId({deliveryRequestId: vm.invoice.id}, function(data){
                vm.tracesList = data.traces;
            })
        });

        //返回
        $scope.goBack = function () {
            $state.go("invoice",{page: $stateParams.page});
        }
    }
})();
