(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('Channels', Channels);

    Channels.$inject = ['$resource'];

    function Channels ($resource) {
        var resourceUrl =  'api/channels/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true,
                params: {
                    id: 'page'
                },
            },
            'getAll': { method: 'GET', isArray: true,
                params: {
                    id: 'getAll'
                },
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'delete':{
                method:'DELETE',
                params: {
                    id: 'id'
                }
            },
            'add': { method:'POST' } ,
            'update': { method:'PUT' },
            'detail': {method:'GET',
                params:{
                    id:'exists'
                }
            },
            'changeBalance': {
                method: 'GET',
                params: {
                    id: 'changeBalance'
                }
            },
            'getTransactionRecord': {
                method: 'GET',
                params: {
                    id: 'getTransactionRecord'
                },
                isArray: true
            },
            'getHomePageData': {
                method: 'GET',
                params: {
                    id: 'getHomePageData'
                },
            },
            
            'getChannelAndMerchant': {
                method: 'GET',
                params: {
                    id: 'getChannelAndMerchant'
                },
                isArray: true
            }
        });
    }
})();
