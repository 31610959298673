(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CommodityAddController', CommodityAddController);

    CommodityAddController.$inject = ['$scope','$state','MessageService','ProductCategory', '$uibModal', 'UnitMeasurement', 'Supplier', 'Product','$stateParams', 'MaterialTemplates','Qinius','$q','$timeout', 'UidService'];

    function CommodityAddController ($scope,$state,MessageService, ProductCategory, $uibModal, UnitMeasurement, Supplier, Product, $stateParams, MaterialTemplates, Qinius, $q, $timeout, UidService) {
        var vm = this;

        //商品类型
        vm.commodityTypes =[
            {name:'定制商品',id:'OrderingProduct'},
            {name:'标准印品',id:'StandardProduct'},
            {name:'标准通品',id:'GeneralProduct'}
        ];

        //商品品类
        vm.productCategoryList = ProductCategory.getAll({type:'product'});
        //商品分类一级
        vm.superiorName = null;
        //计量单位
        vm.unitList = UnitMeasurement.getAll();
        //生产商
        vm.supplierList = Supplier.getAll();
        //物料模板
        MaterialTemplates.getAll({type:'product'},function(res){
            vm.materialTemplatesList =  res.filter(function(item){return item.enable;})
        });
        //是否定制
        vm.isCustomization = true;
        vm.data = {
            template: {},
            name:'',
            category: 'OrderingProduct',
            productCategoryId:'',
            productCategoryName:'',
            measurementId:'',
            measurementName:'',
            enable: false,
            propertyConfigs: [],
            producible: false,              //可生产
            warningTime: "",                //预警时间
            weight: "",                //预警时间
            imgDpi: "",                     //DPI
            toning: "",                     //调色
            autoOutsourcing: false,         //自动流转
            productCirculations: []        //流转配置
        }

        //物料模板选择
        $scope.materialTemplateSelect = function(){
            var _number1 = "";
            vm.commodityProperty =[];
            vm.superiorName = null;
            //商品属性
            if(vm.data.template ==null){
                vm.commodityPropertyList =[];
                vm.data.productCategoryName ='';
                vm.data.productCategoryId ='';
            }else{
                vm.commodityPropertyList = vm.data.template.items;
                vm.data.productCategoryName = vm.data.template.productCategoryName;
                vm.data.productCategoryId = vm.data.template.productCategoryId;

                for(var i=0; i<vm.productCategoryList.length; i++){
                    if(vm.productCategoryList[i].id == vm.data.template.productCategoryId){
                        vm.data.template.productCategoryName = vm.productCategoryList[i].name;
                        var _number = "";
                        if(vm.productCategoryList[i].superiorId){
                            vm.superiorName = vm.productCategoryList[i].superiorName;
                            for(var j=0; j<vm.productCategoryList.length; j++){
                                if(vm.productCategoryList[i].superiorId == vm.productCategoryList[j].id){
                                    _number = vm.productCategoryList[j].categoryCode;
                                }
                            }
                        }
                        _number1 = _number + '-' + vm.productCategoryList[i].categoryCode;
                        break
                    }
                }
                //获取订单编号
                Product.getNumber2({categoryCode:_number1}, function (res) {
                    vm.data.number = res.number;
                });
            }
        };
        //商品类型选择
        $scope.commodityTypeSelect = function(){
            if(vm.data.category == 'GeneralProduct'){
                vm.isCustomization = false;
                vm.data.producible = false;
                vm.data.autoOutsourcing = false;
                warningTime();
                autoOutsourcing();
                for(var i=0; i<vm.data.propertyConfigs.length; i++){
                    vm.data.propertyConfigs[i].warehouseConfigs = null;
                }
            }
            if(vm.data.category == 'OrderingProduct' || vm.data.category == 'StandardProduct'){
                vm.isCustomization = true;
            }
        }

        //计量单位选择
        $scope.measurementSelect = function(){
            for(var i=0; i<vm.unitList.length; i++){
                if(vm.unitList[i].id == vm.data.measurementId){
                    vm.data.measurementName = vm.unitList[i].name;
                }
            }
        };

        //流转配置
        $scope.supplierSelect = function(data){
            for(var i=0; i<vm.supplierList.length; i++){
                if(vm.supplierList[i].id == data.supplierId){
                    data.supplierName = vm.supplierList[i].name;
                }
            }
        };
        $scope.defaultFn = function(index){
            for(var i=0; i<vm.data.productCirculations.length; i++){
                if( i == index){
                    vm.data.productCirculations[i].defaulted = true;
                }else {
                    vm.data.productCirculations[i].defaulted = false;
                }
            }
        };
        $scope.defaultDelete = function(index){
            vm.data.productCirculations.splice(index, 1);
        }
        $scope.productCirculationAdd = function(index){
            var _obj = {
                supplierId: '',
                supplierName: '',
                defaulted: false
            };
            vm.data.productCirculations.push(_obj)
        }


        vm.commodityProperty = [];
        $scope.commodityPropertyFn = function($event, data){
            vm.commodityProperty =vm.commodityPropertyList.filter(function(item){
                return item.checked;
            });
        }
        $scope.rapidGenerating = function () {
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/property.html',
                controller: 'PropertyController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return vm.commodityProperty;
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    var day = new Date();
                    var _backData = [];
                    for( var x=0; x<backData.length; x++){
                        if(backData[x].length > 0){
                            _backData.push(backData[x]);
                        }
                    }
                    var _arr = getCombination(_backData);
                    if(typeof _arr == "object") {
                        for (var i = 0; i < _arr.length; i++) {
                            var _sku = vm.data.name + _arr[i];
                            var _isSku = false;
                            for(var j=0; j<vm.data.propertyConfigs.length; j++){
                                var _sku1 = vm.data.name + vm.data.propertyConfigs[j].sku;
                                if(_sku == _sku1){
                                    _isSku = true;
                                }
                            }
                            if(!_isSku) {
                                var _obj = {
                                    sku: _arr[i],
                                    number: '',
                                    normalPages: '',
                                    createdDate: day,
                                    alias: '',
                                    minStorage: '',
                                    warehouseConfigs: null,
                                    materialConfigs: [],
                                    uuid : UidService.get()
                                };
                                vm.data.propertyConfigs.push(_obj);
                            }
                        }
                    }
                }
            });
        }
        function getCombination (array) {
            if(array.length > 0){
                var _arr = [];
                if (array.length < 2) {
                    for(var j =0; j<array[0].length; j++){
                        _arr.push([array[0][j]])
                    }
                }else {
                    _arr = [].reduce.call(array, function (col, set) {
                        var res = [];
                        col.forEach(function (c) {
                            set.forEach(function (s) {
                                var t = [].concat(Array.isArray(c) ? c : [c]);
                                t.push(s);
                                res.push(t);
                            })
                        });
                        return res;
                    });
                }
                for(var i=0; i<_arr.length; i++){
                    var _h = '';
                    var _arrI = _arr[i].sort();
                    for(var j=0; j<_arrI.length; j++){
                        _h += '_' + _arrI[j];
                    }
                    _arr[i] = _h
                }
                return _arr
            }
        }
        //商品属性删除
        $scope.propertyDelete = function (index) {
            vm.data.propertyConfigs.splice(index, 1)
        };

        // 附件PDF文件的校验设定
        $scope.pdfFileEdit = function(data,index){
            $uibModal.open({
                templateUrl: 'app/product/file-check.html',
                controller: 'FileCheckController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {type: false, data: data};
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    vm.data.propertyConfigs[index].filePageCount = backData.filePageCount;
                    vm.data.propertyConfigs[index].fileHeight = backData.fileHeight;
                    vm.data.propertyConfigs[index].fileWidth = backData.fileWidth;
                }
            });
        }

        $scope.warningSet = function(index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/minWarning.html',
                controller: 'MinWarningController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {type: true}
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    vm.data.propertyConfigs[index].minStorage = backData.minStorage;
                    vm.data.propertyConfigs[index].warehouseConfigs = backData.warehouseConfigs;
                }
            });
        }

        //设置最小库存预警
        $scope.warningEdit = function(data,index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/minWarning.html',
                controller: 'MinWarningController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {type: false, data: data};
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    vm.data.propertyConfigs[index].minStorage = backData.minStorage;
                    vm.data.propertyConfigs[index].warehouseConfigs = backData.warehouseConfigs;
                }
            });
        }
        //辅料设置
        $scope.ingredientsSet = function(index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/ingredients.html',
                controller: 'IngredientsController',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function() {
                        return
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    console.log(backData)
                    vm.data.propertyConfigs[index].materialConfigs = backData;
                }
            });
        };
        $scope.ingredientsEdit = function(data, index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/ingredients.html',
                controller: 'IngredientsController',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function() {
                        return data.materialConfigs
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    console.log(backData);
                    vm.data.propertyConfigs[index].materialConfigs = backData;
                }
            });
        };

        //保存
        $scope.save = function () {
            if(!vm.data.name){
                MessageService.error("商品名称不能为空");
                return;
            }
            if(!vm.data.category){
                MessageService.error("商品类型不能为空");
                return;
            }
            if(!vm.data.productCategoryId){
                MessageService.error("商品品类不能为空");
                return;
            }
            if(!vm.data.measurementId){
                MessageService.error("计量单位不能为空");
                return;
            }
            if(!vm.data.weight){
                MessageService.error("重量不能为空");
                return;
            }

            if(vm.data.propertyConfigs.length == 0){
                MessageService.error("属性不能为空");
                return;
            }
            var _data = angular.copy(vm.data);

            for(var i=0; i<_data.propertyConfigs.length; i++){
                _data.propertyConfigs[i].sku = _data.name + _data.propertyConfigs[i].sku;
                _data.propertyConfigs[i].number = _data.number + '-' + (Number(i)+Number(1));
                _data.propertyConfigs[i].sort = Number(i) + Number(1);
            }

            vm.isSaving = true;
            Product.save(_data, function (res) {
                MessageService.success("保存成功");
                $state.go("commodity");
            }, function (error) {
                vm.isSaving = false;
                MessageService.error(error.data.message);
            });
        }
        //返回
        $scope.goBack = function () {
            $state.go("commodity",{page: $stateParams.page});
        }


        //清除方法
        $scope.productionCb = function () {
            if(!vm.data.producible){
                warningTime()
            }
        };
        $scope.autoOutsourcingCb = function () {
            if(!vm.data.autoOutsourcing){
                autoOutsourcing()
            }
        };

        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();

        $q.all([vm.tokens.$promise, vm.domains.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        })

        $scope.removeFile = function () {
            vm.data.previewImg = null;
        };
        window.updateImg = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.data.previewImg = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };
        $scope.removeSkuFile = function (data) {
            data.previewImg = null;
        };

        window.updateSkuImg = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('token', vm.token);
                $.ajax({


                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        for (var i = vm.data.propertyConfigs.length - 1; i >= 0; i--) {
                            var propertyConfig = vm.data.propertyConfigs[i];
                            if (propertyConfig.uuid == uuid) {
                                propertyConfig.previewImg = data.key;
                                break;
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        //预警时间
        function warningTime() {
            vm.data.warningTime = "";
        }
        //流转配置
        function autoOutsourcing() {
            vm.data.productCirculations = [];
        }
    }
})();
