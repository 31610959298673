(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderVipDetailsController', OrderVipDetailsController);

    OrderVipDetailsController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'ParseLinks'];

    function OrderVipDetailsController ($scope, Principal, LoginService, $state,User,MessageService, ParseLinks) {
        var vm = this;

        //打印
        $scope.printpage = function () {
            var iframe = document.getElementById('print-frame');
            if (iframe === null || iframe === undefined) {
                iframe = document.createElement('iframe');
                iframe.lang = 'en';
                iframe.style.display = 'none';
                iframe.id = 'print-frame';
                document.body.insertBefore(iframe,document.body.children[0]);
                iframe.contentDocument.head.innerHTML = "<meta charset='UTF-8'><title>订单</title><link rel='stylesheet' href='content/css/main.css'><link rel='stylesheet' href='bower_components/bootstrap/dist/css/bootstrap.css'>";
            }

            setTimeout(function () {
                iframe.contentDocument.body.innerHTML = document.getElementById("printPage").innerHTML;
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
            },300);
        };
    }
})();
