(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ExpressEditController', ExpressEditController);

    ExpressEditController.$inject = ['$scope', '$state','MessageService', 'Express', 'entity', '$stateParams', 'Supplier', '$uibModal', '$q'];

    function ExpressEditController ($scope,$state,MessageService,Express, entity, $stateParams, Supplier, $uibModal, $q) {
        var vm = this;
        vm.express = entity;

        $q.all([vm.express.$promise]).then(function() {
            Supplier.getAll(function (res) {
                vm.suppliers = res.filter(function (item) {
                    return item.enable;
                });
                for (var i = vm.suppliers.length - 1; i >= 0; i--) {
                    if (vm.suppliers[i].id == vm.express.supplierId) {
                        vm.supplier = vm.suppliers[i];
                    }
                }
            });
        })

        $scope.selectSup = function (sup) {
            vm.express.supplierId = sup.id;
        }

        $scope.save = function () {
            if(!vm.express.name){
                MessageService.error("名称不能为空");
                return;
            }
            if(!vm.express.alias){
                MessageService.error("别名不能为空");
                return;
            }
            if(!vm.express.code){
                MessageService.error("编码不能为空");
                return;
            }
            if (vm.express.code.match(/[\u4E00-\u9FA5]/g,'')) {
                MessageService.error("编码不能输入中文");
                return false;
            }
            if(!vm.express.customerId){
                MessageService.error("客户号不能为空");
                return;
            }
            if (vm.express.customerId.match(/[\u4E00-\u9FA5]/g,'')) {
                MessageService.error("客户号不能输入中文");
                return false;
            }
            if(!vm.express.customerPassword){
                MessageService.error("客户密码不能为空");
                return;
            }
            if (vm.express.customerPassword.match(/[\u4E00-\u9FA5]/g,'')) {
                MessageService.error("客户密码不能输入中文");
                return false;
            }
            if(!vm.express.overweight){
                MessageService.error("超重阈值不能为空");
                return;
            }

            // if(!vm.express.site){
            //     MessageService.error("网点名称不能为空");
            //     return;
            // }
            // if(!vm.express.siteNumber){
            //     MessageService.error("网点编码不能为空");
            //     return;
            // }
            // if (vm.express.siteNumber.match(/[\u4E00-\u9FA5]/g,'')) {
            //     MessageService.error("网点编码不能输入中文");
            //     return false;
            // }
            vm.isSaving = true;
            Express.update(vm.express, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("修改成功");
                $state.go("express",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("修改失败，请稍后重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("express",{page: $stateParams.page});
        }
        //偏远地区
        $scope.productionSet = function(){
            $uibModal.open({
                templateUrl: 'app/setting/express/province/province.html',
                controller: 'ProvinceController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return vm.express.remoteProvinces;
                    }
                }
            }).result.then(function(result){
                vm.express.remoteProvinces = result;
            });
        };
        //可发货地区
        $scope.shipments = function(){
            $uibModal.open({
                templateUrl: 'app/setting/express/province/province1.html',
                controller: 'Province1Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return vm.express.deliveryArea;
                    }
                }
            }).result.then(function(result){
                if(result){
                    vm.express.deliveryArea={
                        id:vm.express.deliveryArea ? vm.express.deliveryArea.id : "",
                    };
                    vm.express.deliveryArea.cities = result;
                    console.log(vm.express)
                }
            });
        };
    }
})();
