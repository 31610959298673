(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('tag', {
                parent: 'module',
                url: '/tag',
                params:{page: null},
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/tag/tag.html',
                        controller: 'TagController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('tagAdd', {
                parent: 'module',
                url: '/tagAdd',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/tag/tag-detail.html',
                        controller: 'TagDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ReplenishmentReason', function($stateParams, ReplenishmentReason) {
                        return null;
                    }],
                }
            })
            .state('tagEdit', {
                parent: 'module',
                url: '/tagEdit/{id}/{page}',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/setting/tag/tag-detail.html',
                        controller: 'TagDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'MetaTags', function($stateParams, MetaTags) {
                        return MetaTags.get({id : $stateParams.id});
                    }],
                }
            });
    }
})();
