(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User($resource) {
        var service = $resource('api/users/:login/:id', {}, {
            'batchDelete': {
                method: 'GET',
                params: {
                    login: 'batchDelete'
                }
            },
            'batchSave': {
                method:'POST' ,
                params: {
                    login: 'batchSave'
                },
                headers: {
                    scope:'client'
                }
            },
            'getAll': {
                method:'GET',
                params: {
                    login: 'getAll'
                },
                isArray: true
            } ,
            'query': {
                method: 'GET',
                params: {
                    login: 'page'
                },
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'saveSupply': {
                method:'POST' ,
                headers: {
                    scope:'order-end'
                }
            },
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {
                method: 'DELETE',
                params: {
                    login: 'id'
                }
            },
            'reset': {
                method: 'POST',
                params: {
                    login: 'reset'
                }
            },
            'reset2': {
                method: 'POST',
                params: {
                    login: 'reset2'
                }
            },
            'validate': {
                method: 'GET',
                params: {
                    login: 'exists'
                }
            },
            'id': {
                method: 'GET',
                params: {
                    login: 'id'
                }
            },
            'getEmployee': {
                method: 'GET',
                isArray: true,
                params: {
                    login: 'getEmployee'
                }
            }
        });

        return service;
    }
})();
